import request from '@/utils/request'

const url = '/auth-service/osses'

export function ststoken(params) {
  return request({
    url: `${url}/get_sts_token`,
    method: 'get',
    params: params
  })
}
