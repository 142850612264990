export default {
  install: Vue => {
    Vue.directive('allow', {
      inserted(el, binding, vnode) {
        const permissions = vnode.context.permissions
        console.log(vnode.context)
        if (!permissions.hasPermission(binding.value)) {
          el.parentNode.removeChild(el)
        }
      }
    })
  }
}
