<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">

          <el-row>
            <el-col :span="8">
              <el-form-item prop="storeId" label="店铺" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.storeId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in stores"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="title" label="菜单名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="picUrl" label="菜单主图" :label-width="formLabelWidth">
                <ly-pic-uploader ref="lyuploader" :url="form.picUrl"></ly-pic-uploader>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="screenPrice" label="原价" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.screenPrice" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="price" label="现价" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.price" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="content" label="菜单详情" :label-width="formLabelWidth">
                <ly-rich-editor ref="lyricheditor" :content="form.content"></ly-rich-editor>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="publishFlag" label="是否推荐" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.publishFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import LyRichEditor from '@/components/RichEditor'
import LyPicUploader from '@/components/PicUploader'
import * as EnumApi from '@/api/enums'
import * as DishApi from '@/api/dish'

export default {
  name: 'DishForm',
  components: {
    LyRichEditor,
    LyPicUploader
  },
  props: {
    enums: '',
    stores: '',
    parents: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
      form: {storeId: ''},
      rules: {
        title: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {

  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      that.form = Object.assign({}, that.control.form);
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)

      that.form.picUrl = that.$refs.lyuploader.fileList[0].url
      that.form.content = that.$refs.lyricheditor.content1
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              DishApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              DishApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>
