<template>
  <div class="writer-cashier">
    <div class="writer-cashier-flag" style="margin-top: 3rem; width: 100%;text-align: center;">
      <img src="@/assets/pay-success.png" style="width: 10rem;" alt="" srcset="" />
      <div class="" style="font-weight: 400; font-size: 1.8rem; padding: 1rem;">
        充值成功
      </div>
    </div>

    <div class="writer-cashier-goods">
      <div class="writer-cashier-info-item">
        <span>商品：</span>
        <span>{{getParams('productName')}}</span>
      </div>

      <div class="writer-cashier-info-item">
        <span>金额：</span>
        <span>￥{{getParams('price')}}</span>
      </div>
    </div>

    <div class="writer-cashier-info">
      <div class="writer-cashier-info-item">
        <span>手机号：</span>
        <span>{{getParams('phone')}}</span>
      </div>

      <div class="writer-cashier-info-item">
        <span>用户ID：</span>
        <span>{{getParams('accountId')}}</span>
      </div>

      <div class="writer-cashier-info-item">
        <span>能量：</span>
        <span>{{getParams('energy')}}</span>
      </div>
    </div>

  </div>
</template>
<script>
import * as EnumApi from '@/api/enums'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Cashier',
  components: {

  },
  data() {
    return {

    }
  },
  computed: {
  	...mapState(['user'])
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    ...mapMutations(['setStory']),
    getParams(name){
      const queryString = window.location.search;

      // 使用正则表达式匹配查询字符串中的参数
      const params = {};
      const regex = /([^?=&]+)=([^&]*)/g;
      let match;
      while (match = regex.exec(queryString)) {
          params[match[1]] = match[2];
      }
      return params[name]
    }
  }
}
</script>
<style scoped lang="scss">
.writer-cashier {
  color: #000000;
  &-goods {
    background: #FAFAFD;
    border-radius: 20px;
    font-weight: 400;
    font-size: 1rem;
    margin: 4rem 2rem 2rem 2rem;
    padding: 1rem 2rem;
    &-item {
      padding: .8rem 0;
    }
  }
  &-info {
    font-weight: 400;
    font-size: 1rem;
    padding: 0rem 4rem;
    &-item {
      padding: .8rem 0;
    }
  }
}
</style>
