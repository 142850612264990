<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">

          <el-row>
            <el-col :span="8">
              <el-form-item prop="storeId" label="店铺" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.storeId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in stores"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="title" label="套餐名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="picUrl" label="套餐主图" :label-width="formLabelWidth">
                  <ly-pic-uploader ref="lyuploader" :url="form.picUrl" picSize="160x160"></ly-pic-uploader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="album" label="套餐轮播图" :label-width="formLabelWidth">
                  <ly-pic-uploader :limit="9" ref="lyuploader1" :url="form.album" picSize="750x540"></ly-pic-uploader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="screenPrice" label="原价" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.screenPrice" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="price" label="现价" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.price" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="border: 1px solid #ccc;margin: 0 10px;">
            <el-form-item prop="priceJustify" label="价格纠正" :label-width="formLabelWidth">
            </el-form-item>
            <el-col :span="4">
              <el-form-item prop="douyinPrice" label="抖音售价" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.douyinPrice" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
                <el-button type="danger" @click="justifyPrice(1)">纠正</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="dianpingPrice" label="点评售价" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.dianpingPrice" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
                <el-button type="danger" @click="justifyPrice(2)">纠正</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="border: 1px solid #ccc;margin: 10px 10px;">
            <el-form-item prop="ratioSeting" label="分成设置" :label-width="formLabelWidth">
            </el-form-item>
            <el-col :span="4">
              <el-form-item prop="ratioFlag" label="是否分成" :label-width="formLabelWidth">
                <el-switch
                  v-model="form.ratioFlag"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="form.ratioFlag">
              <el-form-item prop="ratioType" label="分成类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.ratioType"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.RatioTypeEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="form.ratioFlag">
              <el-form-item prop="ratio" :label="form.ratioType=='FIXED_AMOUNT'?'金额':'比例'" :label-width="formLabelWidth">
                <el-input type="number" min="0" :disabled="control.disabled" v-model="form.ratio" autocomplete="off"></el-input>
                {{form.ratioType=='FIXED_AMOUNT'?'元':'%'}}
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="border: 1px solid #ccc;margin: 0 10px 10px 10px;">
            <el-form-item prop="cutSetting" label="砍价设置" :label-width="formLabelWidth">
            </el-form-item>
            <el-col :span="8">
              <el-form-item prop="cutPriceRank" label="减扣金额区间" :label-width="formLabelWidth">
                两位小数（元），格式：0.00
              </el-form-item>
              <el-form-item prop="cutPriceRangeMin" label="最小值" :label-width="formLabelWidth">
                <el-input type="number" :min="0" :disabled="control.disabled" v-model="form.cutPriceRangeMin"></el-input>
              </el-form-item>
              <el-form-item prop="cutPriceRangeMax" label="最大值" :label-width="formLabelWidth">
                <el-input type="number" :min="0" :disabled="control.disabled" v-model="form.cutPriceRangeMax" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="cutLimitMax" label="砍价上限" :label-width="formLabelWidth">
                <el-input type="number" :min="0" :disabled="control.disabled" v-model="form.cutLimitMax" autocomplete="off"></el-input>次数
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="cutLimitDuration" label="倒计时" :label-width="formLabelWidth">
                <el-input type="number" :min="0" :disabled="control.disabled" v-model="form.cutLimitDuration" autocomplete="off"></el-input>小时
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="desc1" label="套餐描述" :label-width="formLabelWidth">
            <el-input type="textarea" :row="4" :disabled="control.disabled" v-model="form.desc1" autocomplete="off"></el-input>
          </el-form-item>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="content" label="套餐详情" :label-width="formLabelWidth">
                <ly-rich-editor ref="lyricheditor" :content="form.content"></ly-rich-editor>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :row="4" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="publishFlag" label="是否发布" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.publishFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import LyRichEditor from '@/components/RichEditor'
import LyPicUploader from '@/components/PicUploader'
import * as EnumApi from '@/api/enums'
import * as SetmealApi from '@/api/setmeal'
import moment from "moment"
export default {
  name: 'SetmealForm',
  components: {
    LyRichEditor,
    LyPicUploader
  },
  props: {
    enums: '',
    stores: '',
    parents: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {storeId: '', content: '', picUrl: '', desc1: '', album: '', cutPriceRangeMin: 0, cutPriceRangeMax: 0},
      rules: {
        title: [
          { required: true, message: '请输入套餐名称', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {

  },
  mounted() {
    this.init();
  },
  methods: {
    init(){

      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      that.control.form.cutPriceRangeMin = that.control.form.cutPriceRange.split(',')[0]
      that.control.form.cutPriceRangeMax = that.control.form.cutPriceRange.split(',')[1]
      that.form = Object.assign({}, that.control.form);
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)

      that.form.picUrl = that.$refs.lyuploader.fileList[0].url
      that.form.album = that.$refs.lyuploader1.fileList.map((item) => {
        return item.url
      }).join(',')
      that.form.content = that.$refs.lyricheditor.content1
      // cut range
      if(that.form.cutPriceRangeMin > that.form.cutPriceRangeMax){
        // this.$message({
        //   message: '砍价价格区间错误：最小值不能大于最大值',
        //   type: 'error'
        // });
        this.$alert('砍价价格区间错误：最小值不能大于最大值', '温馨提示', {
          confirmButtonText: '我知道了',
          callback: action => {

          }
        });
        return false
      }
      that.form.cutPriceRange = that.form.cutPriceRangeMin + ',' + that.form.cutPriceRangeMax
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...", that.form)
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              SetmealApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              SetmealApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    justifyPrice(flag){
      let param = {id: this.form.id}
      if(flag == 1){
        //douyin
        param.douyinPrice = this.form.douyinPrice
        param.douyinPriceTip = ''
      }else if(flag == 2){
        //dianping
        param.dianpingPrice = this.form.dianpingPrice
        param.dianpingPriceTip = ''
      }
      SetmealApi.update(param).then(res => {
        this.$message({
          message: '已纠正',
          type: 'success'
        });
        this.$parent.handlePage();
      })
    },
  }
}
</script>

<style lang="scss">
.el-popover{
  height: 100vh !important;
  overflow: auto !important;
}
</style>
