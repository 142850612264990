<template>
    <!-- import -->
    <el-dialog
    ref="dialog"
      :title="'导入数据'"
      :visible.sync="visibleFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
      :destroy-on-close="true"
      @close="close"
      >
      <div>
        <el-form status-icon :model="importForm" :rules="rules" ref="form" label-width="100px">
          <el-form-item prop="fileUrl" label="文件" :label-width="formLabelWidth">
              <ly-file-uploader ref="lyuploader" :accept="accept" :url="importForm.fileUrl" :name="importForm.fileUrl"></ly-file-uploader>
              <div style="color: chocolate;">文件类型：{{accept}}</div>
          </el-form-item>
          <el-form-item>
            <el-button @click="doHandleImport" type="success">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
</template>
<script>
import LyFileUploader from '@/components/FileUploader'
import * as FileApi from '@/api/file'
export default {
  name: 'File',
  props: {
    showDialogFlag: {
      type: Boolean,
      default: false
    },
    fromType: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '.xlsx,.xls'
    },
  },
  components: {
    LyFileUploader
  },
  data() {
    return {
      formLabelWidth: '90px',
      importForm: {
        fileUrl: '',
        fromType: ''
      },
      rules: {
        picUrl: [
          { required: true, message: '请选择文件', trigger: 'blur' }
        ]
      },
    }
  },
  mounted() {
    // this.visibleFlag = this.showDialogFlag;
  },
  computed: {
    // 计算属性的 getter
    visibleFlag: {
      get: function () {
        // `this` 指向 vm 实例
        return this.showDialogFlag
      },
      set: function () {
        // `this` 指向 vm 实例
        this.$parent.showDialogFlag = false;
      }
    }
  },
  methods: {
    close(){
      console.log('close');
      // this.$refs.dialog.close();
    },
    doHandleImport() {
      console.log('import form:', this.importForm, this.fromType, this.showDialogFlag);

      if(this.$refs.lyuploader.fileList && this.$refs.lyuploader.fileList[0]){
        this.importForm.fileUrl = this.$refs.lyuploader.fileList[0].url
      }

      if(!this.fromType || this.fromType == '' || this.importForm.fileUrl == ''){
        this.$message({
          message: '请检查参数！！',
          type: 'warning'
        });
        return
      }

      this.importForm.fromType = this.fromType
      console.log('import form 2:', this.importForm, this.fromType, this.showDialogFlag);
      FileApi.importData(this.importForm).then(res => {
        if(res.data.code == 0){
          this.$message({
            message: '导入成功',
            type: 'success'
          });
          setTimeout(()=>{
            this.$parent.showDialogFlag = false;
            this.$parent.handlePage();
          }, 1000)
        }

      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
