<template>
  <el-container class="layout">
    <el-header><HeadNav /></el-header>
    <el-container class="main">
      <el-aside>
        <el-scrollbar>
          <LeftMenu />
        </el-scrollbar>
      </el-aside>
      <el-main class="scroll-container">
        <el-card class="box-card" :body-style="{ padding: '0px', height: '100%' }">
          <router-view />
        </el-card>
        <!-- <router-view /> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import HeadNav from '@/components/HeadNav'
import LeftMenu from '@/components/LeftMenu'

export default {
  name: 'Index',
  components: {
    HeadNav, LeftMenu
  },
  async mounted() { }
}
</script>
<style scoped lang="scss">
.layout {
  height: 100%;
}
.main {
  min-height: 1px;
  flex: 1;
}
.el-aside {
  padding: 0px;
  margin-bottom: 0 !important;
  height: 100%;
  width: 12.5rem !important;
}
.el-scrollbar {
  height: 100%;
  background-color: #ffffff;
}
.el-header {
  padding: 0px !important;
  background-color: #001A40;
}
.el-main {
  padding: .5rem;
  // height: 100vh; /* 设置父容器高度为视口高度 */
  height: 100%;
  overflow: hidden; /* 禁止父容器滚动 */
}
.box-card {
  // height: 100vh; /* 设置父容器高度为视口高度 */
  overflow: hidden; /* 禁止父容器滚动 */
  height: 100%;
  // overflow: scroll;
  // overflow-x: scroll;
  // overflow-y: hidden;
}
</style>

