import request from '@/utils/request'

const url = '/auth-service/systems'

export function create(params) {
  return request({

  })
}

export function page(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

export function viewConfigInfo() {
  return request({
    url: `${url}/view_config_info`,
    method: 'get',
    params: {}
  })
}

export function updateConfigInfo(params) {
  return request({
    url: `${url}/update_config_info`,
    method: 'post',
    params: params
  })
}

export function update(params) {
  return request({
    url: `${url}/${params.id}`,
    method: 'put',
    params: params
  })
}

export function remove(id) {
  return request({
    url: `${url}/${id}`,
    method: 'delete'
  })
}

export function enabled(params) {
  return request({
    url: `${url}/${params.id}/enabled/${params.enabled}`,
    method: 'put'
  })
}
