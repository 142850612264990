<template>

    <!-- 从创意开始 弹窗 -->
    <el-drawer
      class="custom-dialog"
      ref="dialog"
      title=""
      :visible.sync="showDialogDpFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="false"
      :withHeader="showHeader"
      :destroy-on-close="true"
      :show-close="showHeader"
      :wrapperClosable="false"
      :size="drawerWidth"
      @close="close"
      >
      <div style="padding: 1.5rem">
        <el-row :gutter="10">
          <el-col>
            <div class="d-flex flex-between text-center slider-box" style="padding: .1rem;">
              <div class="slider-box-item" :class="{'slider-box-active': sliderBoxActive == 0}" @click="sliderBoxActive = 0">快捷模式</div>
              <div class="slider-box-item" :class="{'slider-box-active': sliderBoxActive == 1}" @click="sliderBoxActive = 1">进阶模式</div>
              <div class="slider-box-item" :class="{'slider-box-active': sliderBoxActive == 2}" @click="sliderBoxActive = 2">模板模式</div>
            </div>
          </el-col>
          <!-- 模板 -->
          <el-col :span="24" v-if="sliderBoxActive == 2" style="margin-top: 1rem;">
            <div class="d-flex flex-y-center">
              <!-- <span style="margin-right: .5rem;">模板类型</span> -->
              <el-select v-if="false" class="custom-select"
                  v-model="queryForm.templateType"
                  filterable
                  style="width: 5rem;margin-right: 1rem;"
                  size="mini"
                  placeholder="模板类型">
                <el-option
                  v-for="(item,index) in enums.TemplateTypeEnum"
                  :label="item.desc"
                  :key="index"
                  :value="item.name"
                />
              </el-select>

              <span style="margin-right: .5rem;">模板标签</span>
              <el-select @change="handleTemplatePage" v-model="tags" multiple placeholder="请选择" class="custom-select">
                  <el-option-group
                    v-for="group in storyTemplateTags"
                    :key="group.label"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label">
                    </el-option>
                  </el-option-group>
                </el-select>
            </div>
          </el-col>
          <!-- 模板区域 -->
          <el-col :span="24" v-if="sliderBoxActive == 2" style="overflow: hidden;">
            <div class="model-box">
                <div v-if="storyTemplates.length > 0" @click.stop="handleTemplateClick(item)" class="model-box-item" :class="{'model-box-item-active': item.id == activeItem.id}" :key="index" v-for="(item,index) in storyTemplates">
                  <div class="model-box-item-title">
                    {{item.title}}
                  </div>
                  <div class="model-box-item-content ellipsis4">
                    {{item.content}}
                  </div>
                  <div class="model-box-item-tag">
                    <span class="model-box-item-tag-item" :key="indexTag" v-for="(itemTag,indexTag) in item.tag&&item.tag.split(',')">{{itemTag}}</span>
                  </div>
                </div>
                <el-empty  v-if="storyTemplates.length==0" image="https://www.creatimix.com/static/empty.jpg" description="没有相关模板" style="margin: 0 auto;background-color: #FFFFFF;">
                  <!-- <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">敬请期待!</p> -->
                </el-empty>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="my-1">
          <el-col :span="24" class="my-1" v-if="sliderBoxActive != 2">
            <span class="field-title">故事类型</span><span class="field-required">*</span>
          </el-col>
          <el-col :span="24" v-if="enums.ArticleTypeEnum && sliderBoxActive != 2">
            <el-select
                v-model="story.articleType"
                filterable
                size="mini"
                placeholder="类型">
              <el-option
                v-for="(item,index) in enums.ArticleTypeEnum.filter((item) => {
                if(story.articleGenType == 'GEN_BY_ZHIHU' && item.code >= 1 && item.code <= 10){
                  return true
                }else{
                  return false
                }
              })"
                :label="item.desc"
                :key="index"
                :value="item.name"
              />
            </el-select>
          </el-col>
          <!-- wenfeng -->
          <el-col :span="24" class="my-1">
            <span class="field-title">故事文风</span><span class="field-required">*</span>
          </el-col>
          <el-col :span="24" v-if="enums.ArticleStyleEnum">
            <el-select
                v-model="story.articleStyle"
                filterable
                size="mini"
                placeholder="文风">
              <el-option
                v-for="(item,index) in enums.ArticleStyleEnum.filter((item) => {
                  return item.code != 3
                })"
                :label="item.desc"
                :key="index"
                :value="item.name"
              />
            </el-select>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="my-1">
          <el-col :span="24" class="my-1">
            <span class="field-title">写作视角</span><span class="field-required">*</span>
          </el-col>
          <el-col :span="24" v-if="enums.WritingPerspectiveEnum">
            <el-radio-group v-model="story.writingPerspective">
              <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.WritingPerspectiveEnum">{{item.desc}}</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="my-1" v-show="sliderBoxActive != 1">
          <el-col :span="24" class="my-1">
            <span class="field-title">你想要创作什么样的故事？</span><span class="field-required"></span>
          </el-col>
          <el-col :span="24">
            <el-input type="textarea" placeholder="请描述你想创作的故事…可以是简单的一句话或者是一大段设定"
              v-model="story.storyIdea" :rows="4" maxlength="1000" show-word-limit clearable class="story-idea no-border-input">
            </el-input>
          </el-col>
        </el-row>

        <!-- 创意引导 -->
        <el-row :gutter="10" class="my-1"  v-show="sliderBoxActive == 1">

          <el-col :span="24" class="my-1">
            <span class="field-title">故事元素</span><span class="field-required"></span>
          </el-col>
          <el-col :span="24">
            <el-input type="text" placeholder="非必填，多个用逗号分隔"
              v-model="story.storyIdeaElement" :rows="1" clearable class="story-idea no-border-input">
            </el-input>
            <div class="d-flex flex-between">
             <div class="story-idea-tag-box">
               <span @click="handleIdeaClick('element', itemHot.name)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in synesthesiaElements">{{itemHot.name}}</span>
             </div>
             <div class="pointer get-more" @click="handleIdeaRef('element')">
               <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
             </div>
            </div>
          </el-col>

          <el-col :span="24" class="my-1" v-if="story.articleGenType != 'GEN_BY_ZHIHU'">
            <span class="field-title">人设CP</span>
          </el-col>
          <el-col :span="24" v-if="story.articleGenType != 'GEN_BY_ZHIHU'">
            <el-input type="text" placeholder="非必填"
              v-model="story.storyCp" clearable :rows="1" class="story-idea no-border-input">
            </el-input>
            <div class="d-flex flex-between">
              <div class="story-idea-tag-box">
                <span @click="handleIdeaClick('cp', itemHot.leadingRole1)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in cps">{{itemHot.leadingRole1}}</span>
              </div>
              <div class="pointer get-more" @click="handleIdeaRef('cp')">
                <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
              </div>
            </div>
          </el-col>

          <el-col :span="24" class="my-1">
            <span class="field-title">时下热点</span><span class="field-required"></span>
          </el-col>
          <el-col :span="24">
            <el-input type="text" placeholder="可选，多个用逗号分隔"
              v-model="story.storyHot" clearable :rows="1" class="story-idea no-border-input">
            </el-input>

            <div class="d-flex flex-between">
              <div class="story-idea-tag-box">
                <span @click="handleIdeaClick('hot', itemHot.content)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in hots">{{itemHot.content}}</span>
              </div>
              <div class="pointer get-more" @click="handleIdeaRef('hot')">
                <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
              </div>
            </div>
          </el-col>

          <el-col :span="24" class="my-1">
            <span class="field-title">其它想法</span>
          </el-col>
          <el-col :span="24">
            <el-input type="textarea" placeholder="请描述你想创作的故事…可以是简单的一句话或者是一大段设定"
              v-model="story.storyIdeaOther" clearable :rows="4" maxlength="1000" show-word-limit class="story-idea no-border-input">
            </el-input>
          </el-col>
        </el-row>

        <el-row :gutter="10" type="flex" justify="center" style="margin-top: 2rem; margin-bottom: 1rem;">
          <el-button class="" style="padding: 10px 55px" @click="close()">取 消</el-button>
          <el-button class="" style="padding: 10px 55px;margin-left: 30px" type="primary" @click="newStoryByIdea">开 始</el-button>
        </el-row>
      </div>
    </el-drawer>
</template>
<script>
import * as StoryCpApi from '@/api/storycp'
import * as StoryTemplateApi from '@/api/storytemplate'
import * as EnumApi from '@/api/enums'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import * as ArticleApi from '@/api/article'
export default {
  name: 'StoryByIdea',
  props: {
    enums: {
      type: Object,
      default: {}
    },
    channel: {
      type: String,
      default: 'workspace_index'
    },
  },
  components: {

  },
  data() {
    return {
      sliderBoxActive: 0,
      activeItem: {},
      synesthesiaElements:[],
      cps:[],
      hots:[],
      storyTemplates:[],
      storyTemplateTags:[],
      tags: [],
      importForm: {
        fileUrl: '',
        fromType: ''
      },
      queryForm: {
        name: '',
        templateType: 'GUANFANG',
        tag: '',
        current: '',
        size: 100
      },
      rules: {
        picUrl: [
          { required: true, message: '请选择文件', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    console.log('mounted');
    this.activeItem = {}
    this.handleIdeaRef('element')
    this.handleIdeaRef('hot')
    this.handleIdeaRef('cp')
    this.handleTemplatePage()
    this.handleTemplateTag()
    window.addEventListener('resize', this.forceUpdate);

    this.$nextTick(() => {
      if ('workspace_index' == this.channel) {// 初始化表单
        this.sliderBoxActive = 0
        console.log('StoryIdeaDialogDp init')
        this.setStory({id: '', articleType: 'FEEL_GOOD', articleGenType: 'GEN_BY_ZHIHU', articleStyle: 'ZHENGWEN', writingPerspective: 'FIRST_PERSON', storyCp: '', storyHot: '', storyIdeaElement: ''})
      }
    })
  },
  computed: {
    ...mapState(['user', 'story', 'showDialogDpFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : '40%';
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpFlag']),
    close(){
      this.setShowDialogDpFlag(false);
    },
    newStoryByIdea() {
      if ((!this.story.articleType || !this.story.articleType.trim()) && this.sliderBoxActive != 2) {
          this.$message.error('请选择故事类型');
          return false;
      }
      if (!this.story.articleStyle || !this.story.articleStyle.trim()) {
          this.$message.error('请选择故事文风');
          return false;
      }
      if (!this.story.writingPerspective || !this.story.writingPerspective.trim()) {
          this.$message.error('请选择写作视角');
          return false;
      }
      if (this.sliderBoxActive == 0 && (!this.story.storyIdea || !this.story.storyIdea.trim())) {
          this.$message.error('请输入故事创意');
          return false;
      }
      if (this.sliderBoxActive == 2 && (!this.story.storyTemplateId || this.story.storyTemplateId == '0')) {
          this.$message.error('请选择模板');
          return false;
      }
      if (this.sliderBoxActive == 0) {
          this.story.storyCp = ''
          this.story.storyHot = ''
          this.story.storyIdeaElement = ''
          this.story.storyIdeaOther = ''
          this.story.storyTemplateId = '0'
      }else if (this.sliderBoxActive == 1) {
          this.story.storyIdea = ''
          this.story.storyTemplateId = '0'
      }else if (this.sliderBoxActive == 2) {
        this.story.storyCp = ''
        this.story.storyHot = ''
        this.story.storyIdeaElement = ''
        this.story.storyIdeaOther = ''
        this.story.articleType = ''
      }
      console.log('channel', this.channel);
      if ('genbyoutline_regenelement' == this.channel
       || this.channel == 'genbyzhihu_regenelement'
       ) {// 重新生成 要素
        // console.log('channel', this.$parent);
        this.$parent.genStoryElement()
        console.log('StoryIdeaDialog genbyoutline_regenelement')
        this.setStory(this.story)
        this.close()
        return false;
      }else if (this.channel == 'genbyzhihu_regenoutlinesimple') {// 重新生成 粗钢
        // console.log('channel', this.$parent);
        this.$parent.genStoryOutlineSimple()
        console.log('StoryIdeaDialog genbyzhihu_regenoutlinesimple')
        this.setStory(this.story)
        this.close()
        return false;
      }
// return false;
      ArticleApi.create(this.story).then(res => {
          if(res.data.code == 0){
            let data = res.data.data
            console.log('StoryIdeaDialog ArticleApi create')
            this.setStory(data)
            // console.log('story', this.story);
            this.close()
            this.$router.push('/workspace/gen');
          }else{
            this.$message.error(res.data.msg);
          }
      })
    },
    handleIdeaRef(flag) {
      if(flag == 'element') {
        StorySynesthesiaElementApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.synesthesiaElements = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'hot') {
        StoryHotApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.hots = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'cp') {
        StoryCpApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.cps = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }
    },
    handleTemplateClick(item) {
      this.activeItem = item
      this.story.storyTemplateId = item.id
      this.setStory(this.story)
    },
    handleIdeaClick(flag, text) {
      console.log('flag, text', flag, text);
      if(flag == 'element') {
        if(!this.story.storyIdeaElement){
          this.story.storyIdeaElement = text
        }else if(this.story.storyIdeaElement.indexOf(text) == -1){
          this.story.storyIdeaElement = this.story.storyIdeaElement +  ',' + text
        }
      }else if(flag == 'hot') {
        if(!this.story.storyHot){
          console.log('flag, text', flag, text);
          this.story.storyHot = text
          console.log('storyHot', this.story.storyHot);
          this.setStory(this.story)
        }else if(this.story.storyHot.indexOf(text) == -1){
          console.log('flag, text', flag, text);
          this.story.storyHot = this.story.storyHot +  ',' + text
        }
      }else if(flag == 'cp') {
        if(!this.story.storyCp){
          this.story.storyCp = text
        }else if(this.story.storyCp.indexOf(text) == -1){
          this.story.storyCp = this.story.storyCp +  ',' + text
        }
      }
    },
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            // 生成从0到i的随机索引
            const j = Math.floor(Math.random() * (i + 1));
            // 交换元素
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    },
    handleTemplateTag() {
      EnumApi.templateTags().then(res => {
        let result = res.data.data;
        let tag = result.tag[0]

        //type tag
        let typeTagOptions = []
        tag.typeTag.split(',').forEach((item, index) => {
          typeTagOptions.push({
            value: 'type' + index,
            label: item
          })
        })
        let typeTag = {
          label: '类型',
          options: typeTagOptions,
          }
        this.storyTemplateTags.push(typeTag)

        // role tag
        let roleTagOptions = []
        tag.roleTag.split(',').forEach((item, index) => {
          roleTagOptions.push({
            value: 'role' + index,
            label: item
          })
        })
        let roleTag = {
          label: '角色',
          options: roleTagOptions,
          }
        this.storyTemplateTags.push(roleTag)

        // age tag
        let ageTagOptions = []
        tag.ageTag.split(',').forEach((item, index) => {
          ageTagOptions.push({
            value: 'age' + index,
            label: item
          })
        })
        let ageTag = {
          label: '时代',
          options: ageTagOptions,
          }
        this.storyTemplateTags.push(ageTag)

        // plotTag tag
        let plotTagOptions = []
        tag.plotTag.split(',').forEach((item, index) => {
          plotTagOptions.push({
            value: 'plot' + index,
            label: item
          })
        })
        let plotTag = {
          label: '情节',
          options: plotTagOptions,
          }
        this.storyTemplateTags.push(plotTag)

        // emotionTag tag
        let emotionTagOptions = []
        tag.emotionTag.split(',').forEach((item, index) => {
          emotionTagOptions.push({
            value: 'emotion' + index,
            label: item
          })
        })
        let emotionTag = {
          label: '情绪',
          options: emotionTagOptions,
          }
        this.storyTemplateTags.push(emotionTag)
      })
    },
    handleTemplatePage(){
      console.log('this.queryForm', this.queryForm);
      this.queryForm.tag = this.tags.join(',')
      // this.queryForm.tags = []
      StoryTemplateApi.page(this.queryForm).then(res => {
        let result = res.data.data;
        this.storyTemplates = result.records;
        if(this.storyTemplates.length > 1){
          document.documentElement.style.setProperty('--template-rows', '2');
        }
        //default
        this.storyTemplates.forEach((item,index) => {
          if(this.story.storyTemplateId == item.id){
            this.activeItem = item
            return
          }
        })

      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
:root {
  --template-rows: 1; /* 默认红色 */
}
$tagMT: .5rem;
/deep/ .story-idea .el-textarea__inner{
  background: #F4F4F4;
  padding: 1rem;
}
.story-idea-tag-box {
  margin-top: $tagMT;
  &-tag {
    display: inline-block;
    background: #F2F3FF;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    padding: .25rem 1rem;
    margin: 0 .5rem .5rem 0;
    white-space: nowrap;
  }
}
.get-more {
  font-size: 12px;
  color: #0052D9;
  white-space: nowrap;
  margin-top: $tagMT;
}
/* 全局滚动条轨道的padding */
.model-box::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}
/* 滚动条的样式 */
.model-box::-webkit-scrollbar {
  height: 10px;
  margin: 1rem 0;
  background-color: transparent;
}
.model-box::-webkit-scrollbar-thumb {
  background-color: rgba(54,110,244,0.1);
  // border-radius: 6px;
  // background-color: transparent;
}
.model-box {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  // grid-auto-rows: minmax(30px, auto); /* 行的高度至少为100px，但可以更高以适应内容 */
  grid-auto-flow: column;
  // grid-template-columns: 20rem 20rem;
  /*  声明了两行，行高分别为 50px 50px  */
  grid-template-rows:  repeat(var(--template-rows), 1fr);
  gap: .5rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  overflow-x: auto;
  // overflow-y: hidden;
  white-space: nowrap; /* 确保内容不会换行 */
  box-sizing: border-box; /* 边框计算在宽度内 */

  &-item:hover{
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.02);
    cursor: pointer;
    // border: 1px solid #366EF4;
  }
  &-item {
    width: 20rem;
    padding: 1rem;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    border-radius: 14px;
    box-sizing: border-box; /* 边框计算在宽度内 */
    border: 1px solid #EBEEF5;
    background-color: #FFFFFF;
    overflow: hidden;
    color: #303133;
    &-title {
      font-weight: 600;
      font-size: 1rem;
      color: rgba(0,0,0,0.9);
    }
    &-active {
      border: 1px solid #366EF4;
    }
    &-content {
      font-size: .9rem;
      color: rgba(0,0,0,0.6);
      line-height: 1.25rem;
      text-align: justify;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
    &-tag {
      &-item{
        margin-top: .5rem;
        display: inline-block;
        background: #F2F3FF;
        border-radius: 12px;
        font-weight: 400;
        font-size: 12px;
        color: #242424;
        padding: .25rem 1rem;
        margin-right: .5rem;
        white-space: nowrap;
      }
    }
  }
}
.multiline-ellipsis {
  position: relative; /* 为伪元素定位做准备 */
  max-height: 6em; /* 根据内容和行高设置最大高度，这里假设每行2em */
  overflow: hidden; /* 隐藏溢出的内容 */
  line-height: 1.25em; /* 设置行高，与最大高度保持一致 */
  text-align: justify; /* 文本两端对齐 */
  // padding-right: 20px; /* 为省略号留出空间 */
}

.multiline-ellipsis::after {
  content: '...';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFFFFF;
  padding-left: 40px; /* 渐变背景的长度 */
  background: linear-gradient(to right, transparent, white 50%);
  /* 渐变背景从透明到白色，覆盖超出的文本 */
}
.ellipsis4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.custom-select {
    /deep/ .el-input__inner {
        border-radius: 20px;
    }
    /deep/ .el-input__inner {
      height: 1.75rem;
      line-height: 1.75rem;
      font-size: 12px;
    }
    /deep/ .el-input__suffix {
      height: 1.75rem;
      line-height: 1.75rem;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
}
</style>
