<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm" style="padding-left: 10px;">
        <el-row>
          <el-col :span="6">
            <el-form-item label="性格" :label-width="formLabelWidth">
              <el-input v-model="queryForm.content" maxlength="200" size="small" placeholder="请输入关键字" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="攻受属性" :label-width="formLabelWidth">
              <el-select
                  v-model="queryForm.attackType"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in [{name: '攻', desc: '攻'}, {name: '受', desc: '受'}]"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              <!-- <el-input v-model="queryForm.attackType" maxlength="200" size="small" placeholder="请输入关键字" /> -->
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="10px">
              <el-button type="primary" size="small" @click="handlePage()">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>

    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <el-table-column
        fixed
        prop="id"
        label="ID"
        min-width="80px" />

      <el-table-column
        prop="content"
        label="性格"
        min-width="180px"/>

      <el-table-column
        prop="attackType"
        label="攻受属性"
        min-width="180px"/>

      <el-table-column fixed="right" min-width="200px">
        <template slot="header">
          <span style="float: left">操作</span>
          <el-button
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)"
              @click="showDialogFlag = true">
            导入
          </el-button>
          <el-button
              style="float: right; margin-right: 10px;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)"
              @click="handleAction($permits.CREATE)">
            + 创建
          </el-button>
        </template>
        <template slot-scope="scope">
          <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button>
          <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>
    <Novel v-if="control.show" :control="control" :enums="enums" />
    <ImportDialog :fromType="fromType" :showDialogFlag="showDialogFlag"  />
  </div>
</template>
<script>
// import JSZip from 'jszip';
import * as EnumApi from '@/api/enums'
import * as StoryCharacterApi from '@/api/storycharacter'
import Novel from './form'
import ImportDialog from '../../components/ImportDialog'
export default {
  name: 'File',
  components: {
    Novel,ImportDialog
  },
  data() {
    return {
      formLabelWidth: '90px',
      enums: [],
      fromType: 'character',
      showDialogFlag: false,
    	targetPermit: 'storycharacter',
      tableHeight: 0,
      queryForm: {
        content: '',
        attackType: '',
        current: '',
        size: '',
      },
      control: {
        show: false,
        showFlow: false,
        showConfig: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        sizes: [20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
    this.handleEnum()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)

      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          // download
          // StoryCharacterApi.down(item);
          this.downloadFile(item.name, item.content)
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.show = true;
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.show = true;
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        StoryCharacterApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // 获取表格数据
      StoryCharacterApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    },
    downloadFile(storycpname, text) {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', storycpname);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    batchDownload(storycpnames, contents) {
      // 确保CP名数组和内容数组长度相同
      if (storycpnames.length !== contents.length) {
        console.error('Filenames and contents arrays must be the same length');
        return;
      }

      // 遍历CP名和内容数组
      storycpnames.forEach((storycpname, index) => {
        const text = contents[index];
        const element = document.createElement('a'); // 创建一个新的<a>元素
        const blob = new Blob([text], { type: 'text/plain' }); // 创建一个新的Blob对象，其内容为文本内容
        const url = URL.createObjectURL(blob); // 创建一个Object URL
        element.setAttribute('href', url); // 设置<a>的href属性
        element.setAttribute('download', storycpname); // 设置下载的CP名
        document.body.appendChild(element); // 将<a>元素添加到body中
        element.click(); // 触发<a>元素的点击事件，开始下载
        document.body.removeChild(element); // 下载完成后移除<a>元素
        URL.revokeObjectURL(url); // 释放Object URL
      });
    },
    listall() {
      // 获取表格数据
      StoryCharacterApi.page({current:1, size: 1000, name: this.queryForm.name }).then(res => {
        let result = res.data.data.records;
        let storycpnames = result.map((e) => { return e.name})
        let contents = result.map((e) => {return e.content})
        console.log(storycpnames,contents);
        this.batchDownload(storycpnames, contents)
      })
    },
    async handleDownload(storycpnames, contents) {
      // try {
      //   // 使用 JSZip 创建一个新的 zip 压缩包
      //   const zip = new JSZip();

      //   // 将文本内容添加到压缩包中
      //   this.storycpnames.forEach((storycpname, index) => {
      //     zip.storycp(storycpname, this.contents[index]);
      //   });

      //   // 生成 zip CP并触发下载
      //   const content = await zip.generateAsync({ type: 'blob' });
      //   const link = document.createElement('a');
      //   link.href = URL.createObjectURL(content);
      //   link.download = 'archive.zip';
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      //   URL.revokeObjectURL(link.href);
      // } catch (error) {
      //   console.error('批量下载失败:', error);
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
