<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="organizationName" label="公司名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.organizationName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="brandName" label="品牌名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.brandName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
<!--
          <el-row>
            <el-col :span="8">
              <el-form-item prop="businessNo" label="商户编号" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.businessNo" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-row>
            <el-form-item prop="licenseUrl" label="营业执照" :label-width="formLabelWidth">
              <el-input :disabled="true" v-model="form.licenseUrl" autocomplete="off"></el-input>
              <el-image
                    style ="width:100px; height: auto;" v-if="form.licenseUrl" :src="form.licenseUrl"
                    :preview-src-list="[form.licenseUrl]"
                  />
            </el-form-item>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="storeName" label="餐厅名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.storeName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-form-item prop="storePicUrl" label="餐厅主图" :label-width="formLabelWidth">
                  <ly-pic-uploader ref="lyuploader" :url="form.storePicUrl"></ly-pic-uploader>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item prop="storeAlbum" label="餐厅轮播图" :label-width="formLabelWidth">
                  <ly-pic-uploader :limit="9" ref="lyuploader1" :url="form.storeAlbum" picSize="750x540"></ly-pic-uploader>
            </el-form-item>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="餐饮品类" :label-width="formLabelWidth">
                  <el-checkbox-group v-model="checkList" size="mini" @change="handleCheckedCategoriesChange">
                    <el-checkbox v-for="(item,index) in categories" :key="item.id" :checked="item.checked" :label="item.id" border>{{item.title}}</el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="storeDishes" :label-width="formLabelWidth">
                <el-input v-show="false" :disabled="control.disabled" v-model="form.storeDishes" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="storeOpeningTime" label="营业时间" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.storeOpeningTime" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="storeContactus" label="联系方式" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.storeContactus" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="storePerCapita" label="餐厅人均" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.storePerCapita" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4">
              <el-form-item prop="cooperativeState" label="合作状态" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.cooperativeState"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.CooperativeStateEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="checkinTime" label="入驻时间" :label-width="formLabelWidth">
                <el-date-picker
                  v-model="form.checkinTime"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="storeAddress" label="餐厅地址" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.storeAddress" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="1">
              <el-form-item prop="location" label="位置信息" :label-width="formLabelWidth">
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="longitude" label="经度" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.longitude" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="latitude" label="纬度" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.latitude" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="" label="" :label-width="formLabelWidth">
                <a href="https://www.qvdv.net/tools/qvdv-coordinate.html" target="_blank">在线获取</a>
              </el-form-item>

            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="storeBrief" label="餐厅介绍" :label-width="formLabelWidth">
                <ly-rich-editor ref="lyricheditor" :content="form.storeBrief"></ly-rich-editor>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="storePurchaseNotes" label="购买须知" :label-width="formLabelWidth">
                <ly-rich-editor ref="lyricheditor1" :content="form.storePurchaseNotes"></ly-rich-editor>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="enableFlag" label="是否启用" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.enableFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import * as EnumApi from '@/api/enums'
import * as OrganizationApi from '@/api/organization'
import LyRichEditor from '@/components/RichEditor'
import LyPicUploader from '@/components/PicUploader'

export default {
  name: 'OrganizationForm',
  components: {
    LyRichEditor,
    LyPicUploader
  },
  props: {
    enums: '',
    categories: [],
    parents: '',
    control: Object
  },
  data() {
    return {
      checkList: [],
      formLabelWidth: '100px',
      form: {orderNumber: 0},
      rules: {
        organizationName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    categoriesClone() {
        return Object.assign({}, this.categories);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action, that.control)
      this.categories.forEach((item, index) => {
        item.checked = false
      })
      if(that.$permits.CREATE === that.control.action){
        that.form = Object.assign({}, {});
        return
      }

      that.form = Object.assign({}, that.control.form);
      this.categories.forEach((item, index) => {
        if(this.form.storeDishes){
          item.checked = this.form.storeDishes.split(',').includes(item.id)
        }
      })
    },
    handleCheckedCategoriesChange(value) {
      console.log("handleCheckedCategoriesChange >> ", value, this.checkList, this.form.storeDishes)
      this.form.storeDishes = this.checkList.join(',')
    },
    async handleSubmit(action) {
      let that = this;
      // console.log(2,that.$refs.lyuploader.filelist);
      that.form.storePicUrl = that.$refs.lyuploader.fileList[0].url
      that.form.storeAlbum = that.$refs.lyuploader1.fileList.map((item) => {
        return item.url
      }).join(',')
      that.form.storeBrief = that.$refs.lyricheditor.content1
      that.form.storePurchaseNotes = that.$refs.lyricheditor1.content1
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              OrganizationApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              OrganizationApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>
