<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="orderId" label="订单ID" :label-width="formLabelWidth">
                {{form.orderId}}
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="accountId" label="种草用户" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.accountId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in users"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="articleType" label="任务类型" :label-width="formLabelWidth">
                <el-select
                    :disabled="control.disabled"
                    v-model="form.articleType"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择">
                  <el-option
                    v-for="item in enums.ArticleTypeEnum"
                    :key="item.name"
                    :label="item.desc"
                    :value="item.name"
                  />
                </el-select>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.articleType == 'OUT_ARTICLE_TASK'">
              <el-form-item prop="taskId" label="探店任务" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.taskId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in stores"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="title" label="标题" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model.trim="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="picUrl" label="主图" :label-width="formLabelWidth">
                  <ly-pic-uploader ref="lyuploader" :url="form.picUrl" picSize="300x300"></ly-pic-uploader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="album" label="轮播图" :label-width="formLabelWidth">
                  <ly-pic-uploader :limit="9" ref="lyuploader1" :url="form.album" picSize="750x540"></ly-pic-uploader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="content" label="内容" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="10" :disabled="control.disabled" v-model="form.content" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import LyRichEditor from '@/components/RichEditor'
import LyPicUploader from '@/components/PicUploader'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as AccountApi from '@/api/account'
const OSS = require('ali-oss');
export default {
  components: {
      LyRichEditor,
      LyPicUploader
  },
  name: 'ArticleForm',
  props: {
    enums: '',
    stores: [],
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (this.form.articleType == 'OUT_ARTICLE_TASK' && !value) {
          return callback(new Error('请选择探店任务'));
        }
        callback();
      };
    return {
      formLabelWidth: '100px',
      form: {},
      picUrl: '',
      flag: '',
      users: [],
      rules: {
        title: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        articleType: [
          { required: true, message: '请选择任务类型', trigger: 'blur' }
        ],
        accountId: [
          { required: true, message: '请选择种草用户', trigger: 'blur' }
        ],
        taskId: [
          { required: true, validator: checkNumber, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init control >> ", that.control)
      this.doGetUsers()
      that.form.orderId = that.control.form.id
      if(that.$permits.CREATE === that.control.action){
        return
      }
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      if(that.$refs.lyuploader.fileList && that.$refs.lyuploader.fileList[0]){
        that.form.picUrl = that.$refs.lyuploader.fileList[0].url
      }

      that.form.album = that.$refs.lyuploader1.fileList.map((item) => {
        return item.url
      }).join(',')

      if(that.$refs.lyricheditor && that.$refs.lyricheditor.content1){
        that.form.content = that.$refs.lyricheditor.content1
      }

      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              ArticleApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.orderId = that.control.form.id
              that.form.fromType = 'task_editor'
              that.form.articleState = 'COMPLETED'
              console.log("that.form >>> ", that.form)
              return;
              ArticleApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    doGetUsers() {
      AccountApi.page({enableFlag: true, size: 1000, editorFlag: true}).then(res => {
        let result = res.data.data;
        this.users = result.records
      })
    },
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
