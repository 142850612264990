<template>
<el-drawer
    :title="controlCopy.title"
    :enums="enums"
    :with-header="true"
    :visible.sync="controlCopy.show"
    direction="rtl"
    :append-to-body="true"
    :modal-append-to-body="false"
    :modal="false"
    size="50%">
  <div>
    <!-- 查询条件 -->
   <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="套餐名称" :label-width="formLabelWidth">
              <el-input v-model="queryForm.title" maxlength="9" size="small" placeholder="请输入名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage()">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <div style="display: flex;">
      <div style="flex: 2;margin-right: 20px;">
        <!-- 数据表格 -->
        <el-table
          stripe
          :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
          :data="tableData"
          @selection-change="handleSelectionChange"
          height="90vh"
          ref="tableContainer"
          class="table-container">
          <el-table-column
            fixed
            prop="title"
            label="套餐名称"
            levelKey="depth"
            treeKey="id"
            parentKey="parentId"
            childKey="children"
            min-width="120px">
            </el-table-column>

          <el-table-column
            prop="picUrl"
            label="套餐主图">
            <template slot-scope="scope">
              <el-image
                style ="width:60px; height: 60px"
                :src="scope.row.picUrl"
                :preview-src-list="[scope.row.picUrl]"
              />
            </template>
          </el-table-column>

          <el-table-column
            prop="desc1"
            label="菜单描述"/>

      <el-table-column
        prop="storeName"
        label="店铺名称"
        min-width="80px">
        <template slot-scope="scope">
          {{scope.row.store?scope.row.store.storeName:''}}
        </template>
      </el-table-column>

          <el-table-column fixed="right" min-width="80px">
            <template slot="header">
              <span style="float: left">操作</span>
            </template>
            <template slot-scope="scope">
              <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleCopyToSetmeal(scope.row)" size="mini" type="danger" round>复制到该套餐</el-button>
            </template>
          </el-table-column>
        </el-table>


        <!-- 分页 -->
        <el-row>
          <el-col>
            <div class="pagination">
              <el-pagination background
                v-if="paginations.total>0"
                :page-sizes="paginations.pages"
                :page-size="paginations.size"
                :layout="paginations.layout"
                :total="paginations.total"
                :current-page.sync="paginations.current"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"/>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</el-drawer>
</template>
<script>
import * as SetmealApi from '@/api/setmeal'
import * as EnumApi from '@/api/enums'
import * as SetmealStockApi from '@/api/setmealstock'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Copy',
  components: {

  },
  props: {
    controlCopy: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
      stores: [],
      enums: [],
    	// 权限key
      targetPermit: 'task',
      tableHeight: 0,
      queryForm: {
        title: '',
        setmealId: '',
        storeId: '',
        current: 1,
        size: 10
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parents: [],
      multipeSelection: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    // this.handleEnum()
    this.handlePage()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          that.control.form = {setmealId: that.controlCopy.form.id, storeId: that.controlCopy.form.storeId};
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleCopyToSetmeal(item) {
      let that = this;
      that.$confirm('', '确定复制到该套餐吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        SetmealStockApi.copy({setmealStockIds: that.controlCopy.form.setmealStockIds.map(i=>{return i.id}), setmealId: item.id}).then(res => {
          if(res.code == 0){
            this.$message({message: '复制成功', type: 'success'})
          }
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        SetmealStockApi.enabled({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      console.log('current', current)
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // 获取表格数据
      SetmealApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleSelectionChange(val){
      console.log('handleSelectionChange', val)
      this.multipeSelection = val
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
