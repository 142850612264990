<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="ltr"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="parentId" label="父级名称" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.parentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                  v-for="item in parents"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="name" label="工作/指标名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-form-item label="提交内容" :label-width="formLabelWidth">
            <el-radio-group v-model="radio">
              <el-radio v-for="(element, index) in fileTargetTypeEnum" :label="element.name" :key="index">
                {{element.desc}}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传材料" :label-width="formLabelWidth">
            <el-upload
              :action="uploadAction"
              :headers="headers"
              :data="uploadData"
              multiple
              :limit="fileLimit"
              :file-list="fileList"
              list-type="text"
              :on-preview="handlePreview"
              :on-success="handlePage"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :on-change="imgSaveToUrl" 
              :before-upload="handleBeforeUpload"
              :before-remove="handleBeforeRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传 {{fileType}} 文件，且不超过 {{fileSize}} MB</div>
            </el-upload> 
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as TuteworkApi from '@/api/tutework'
import * as FileApi from '@/api/file'

export default {
  name: 'TuteworkForm',
  props: {
    enums: '',
    parents: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '120px',
      form: {orderNumber: 0, submitConfig: ''},
      uploadData: {},
      uploadAction: '',
      fileTargetTypeEnum: [],
      headers: {
        "Authorization": localStorage.getItem('lyToken'),
        // "Content-Type": 'multipart/form-data;charset=UTF-8'
        },
      radio: -1,
      fileList: [],
      fileLimit: 5, // 最多 5 个文件
      fileSize: 2, // 最大 2 MB
      fileType: 'jpeg/jpg/png/gif/mp3/mp4/pdf/docx/xlsx/pptx', // 允许的文件类型
      rules: {
        name: [
          { required: true, message: '请输入工作/指标名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      that.uploadAction = FileApi.getUploadAction()
      console.log("init action >> " + that.control.action)
      console.log("init uploadAction >> " + that.uploadAction)
      // init file list
      that.handlePage()
      // init fileType enum
      that.fileTargetTypeEnum = that.enums.FileTargetTypeEnum
        .filter(i => i.brief == "tute_job")
        .filter(i => that.control.form.submitConfig.indexOf(i.code) >= 0)
      if(that.fileTargetTypeEnum.length > 0){
        that.radio = that.fileTargetTypeEnum[0].name
      }

      if(that.$permits.CREATE === that.control.action){
        return
      }
      TuteworkApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    // 选取图片后自动回调，里面可以获取到文件
    imgSaveToUrl(file){

      // 转换操作可以不放到这个函数里面，
      // 因为这个函数会被多次触发，上传时触发，上传成功也触发
      let reader = new FileReader()
      reader.readAsDataURL(file.raw);
        
      // 转换成功后的操作，reader.result即为转换后的DataURL base64
      reader.onload=()=>{
          reader.result
      }
      /* 另外一种本地预览方法 */
      let URL = window.URL || window.webkitURL;
      let previewIcon = URL.createObjectURL(file.raw);
      // 转换后的地址为 blob:http://xxx/7bf54338-74bb-47b9-9a7f-7a7093c716b5

      let md5 = require('md5')
      this.uploadData.md5 = md5(file.raw)
      console.log(md5(file.raw))
    },
    handleBeforeUpload(file) {
      let extension = file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase()
      console.log("file.type: " + extension);
      this.uploadData.fileType = 'UNKNOWN'
      if('jpeg/jpg/png/gif'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'IMAGE'
      }
      if('gif'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'GIF'
      }
      if('docx'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'WORD'
      }
      if('xlsx'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'EXCEL'
      }
      if('pptx'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'PPT'
      }
      if('mp3'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'AUDIO'
      }
      if('mp4'.indexOf(extension) >= 0){
        this.uploadData.fileType = 'VIDEO'
      }
      this.uploadData.targetId = this.form.id
      this.uploadData.targetType = this.radio
      this.uploadData.fileSize = file.size

      const size = this.fileSize;
      const isSize = file.size / 1024 / 1024 < size;
      let isType = this.fileType.indexOf(extension) >= 0
      if (!isType) {
        this.$message.error('上传文件只能是 ' + this.fileType + ' 格式!');
      }
      if (!isSize) {
        this.$message.error('上传文件大小不能超过 ' + size + 'MB!');
      }
      return isType && isSize;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 ' + this.fileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleBeforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleRemove(file, fileList) {
      let that = this;
      FileApi.remove(file.id).then(res => {
          that.handlePage();
        });
    },
    handlePage() {
      let that = this;
      // 获取表格数据
      FileApi.page({current: 1, size: 500}).then(res => {
        let result = res.data.data;
        that.fileList = result.records
      })
    },
  }
}
</script>

<style>
</style>