<template>
  <el-image :src="url">
    <div slot="error" class="image-slot">
      <i class="el-icon-picture-outline" />
    </div>
  </el-image>
</template>
<script>
export default {
  name: 'CusImage',
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    url() {
      if (this.src.includes('http')) {
        return this.src
      }
      return `${this.imgUrlBase}${this.src}`
    }
  }
}
</script>
