<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.showFlow"
        direction="rtl"
        size="90%">
        <div class="" style="margin-left: 16px;padding: 4px; display: flex;align-items: center;">
          <!-- <div v-html="replaceLineBreaks('\nhello,world.\n')"></div> -->
          <el-button type="primary" @click="testRun">执行</el-button>
          <el-button type="warning" disabled>执行次数</el-button>
          <el-input type="number" placeholder="执行次数" min="1" max="50" style="padding: 0 1rem;" v-model="form.runTimes" autocomplete="off"></el-input>
          <el-button type="primary" @click="control.showTrace = true">追踪</el-button>
        </div>
        <!-- <el-button @click="openBpmn">openBpmn</el-button> -->
        <!-- flow-container -->
        <div class="bpmn" style="height: 100%;">
          <iframe id="mybpmn" :src="bpmnUrl" width="100%" height="100%" referrerpolicy="no-referrer"></iframe>
        </div>

        <ConfigForm v-if="control.showConfig" :control="control" :enums="enums" />
        <TracePage v-if="control.showTrace" :control="control" :enums="enums" />
    </el-drawer>
</template>

<script>
import * as EnumApi from '@/api/enums'
import * as WorkflowApi from '@/api/workflow'
import ConfigForm from './configform'
import TracePage from './trace/page'
export default {
  name: 'FlowForm',
  components: {
    ConfigForm,
    TracePage
  },
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {runTimes: 1},
      //bpmn
      bpmnUrl: process.env.VUE_APP_BPMN_URL,
      xml: "", // Query the xml
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  },
  computed: {

  },
  methods: {
    receiveMessage(event) {
      if (event.origin !== this.bpmnUrl) {
        // 确保消息来自预期的源
        return;
      }
      if (event.data && event.data.action === 'saveProcessAsXml') {
        console.log('Received xml from iframe:', event.data);
        this.save(event.data)
        // 处理消息...
      }
    },
    replaceLineBreaks(str) {
      return str.replace(/\\n/g, '<br>');
    },
    openBpmn() {
      // 向 iframe 发送消息
      const iframe = document.getElementById('mybpmn');
      console.log('form', this.form);
      iframe.contentWindow.postMessage({ action: "openBpmn", data: this.form.bpmn }, this.bpmnUrl);
    },
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }

      WorkflowApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
        this.picUrl = that.form.picUrl

        this.$nextTick(() => {
              const iframe = document.getElementById('mybpmn');
              iframe.onload = () => {
                // 你的逻辑处理，例如调用iframe中的方法或与iframe交互
                console.log('iFrame has loaded');
                this.openBpmn()
              };
            });
      })

    },
    save(data) {
      console.log(data);  // { process: {...}, xml: '...', svg: '...' }
      WorkflowApi.update({id: this.form.id, bpmn: data.data, action: "save_bpmn"}).then(res => {

      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              WorkflowApi.update(that.form).then(res => {
                that.control.showFlow = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              WorkflowApi.create(that.form).then(res => {
                that.control.showFlow = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    showConfig(node) {
      console.log("node >> " + node)
      this.control.formNode = node;
      this.control.showConfig = true;
    },
    testRun(){
      console.log('flowform',this.form);
      // this.form.runTimes = 1
      WorkflowApi.testRun(this.form).then(res => {

      })
    },
    download(){
      console.log('flowform',this.form);
      this.form.runTimes = 1
      WorkflowApi.testRun(this.form).then(res => {

      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
.g6-x {
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-left: 20px;
}
/* 提示框的样式 */
.g6-tooltip {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #545454;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 8px;
  box-shadow: rgb(174, 174, 174) 0px 0px 10px;
}
</style>
