<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="服务标题" :label-width="formLabelWidth">
              <el-input v-model="queryForm.title" maxlength="20" size="small" placeholder="请输入服务标题" />
            </el-form-item>
          </el-col>
<!--
          <el-col :span="6">
            <el-form-item prop="serviceType" label="类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="queryForm.serviceType"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ServiceTypeEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
           </el-form-item>
          </el-col> -->

          <el-col :span="6">
            <el-form-item prop="serviceState" label="状态" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="queryForm.serviceState"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ServiceStateEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
           </el-form-item>
          </el-col>

          <el-col :span="5" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage(1)">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <!-- <el-table-column prop="id" label="id" type="selection" fixed></el-table-column> -->
      <el-table-tree-column
        fixed
        :show-overflow-tooltip="true"
        prop="title"
        label="服务标题"
        levelKey="depth"
        treeKey="id"
        parentKey="parentId"
        childKey="children"
        min-width="120px">
        </el-table-tree-column>

      <el-table-column
        prop="departmentName"
        label="服务部门">
        <template slot-scope="scope">
          <span>{{scope.row.department?scope.row.department.name:''}}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="store"
        label="商户信息" min-width="120px">
        <template slot-scope="scope">
          <div>{{scope.row.store?scope.row.store.organizationName:''}}</div>
          <div>{{scope.row.store?scope.row.store.storeName:''}}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="creator"
        label="提交人">
        <template slot-scope="scope">
          <div>{{scope.row.creator?scope.row.creator.name:''}}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="coin"
        label="消耗服务币"
        min-width="80px"/>

     <el-table-column
        prop="coinFlag"
        label="扣除服务币"
        min-width="80px">
        <template slot-scope="scope">
          <el-button :type="scope.row.coinFlag?'success':'danger'" size="mini" round>
            {{scope.row.coinFlag?'已扣除':'未扣除'}}
          </el-button>
        </template>
      </el-table-column>

<!--      <el-table-column
        prop="serviceType"
        label="服务类型"
        min-width="80px">
        <template slot-scope="scope">
          {{scope.row.serviceTypeLabel}}
        </template>
      </el-table-column> -->

      <el-table-column
        prop="serviceState"
        label="服务状态"
        min-width="80px">
        <template slot-scope="scope">
          {{scope.row.serviceStateLabel}}
        </template>
      </el-table-column>

      <el-table-column
        prop="createTime"
        label="提交时间"
        min-width="165px"/>

      <el-table-column fixed="right" min-width="200px">
        <template slot="header">
          <span style="float: left">操作</span>
          <el-button
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)"
              @click="handleAction($permits.CREATE)">
            + 新建
          </el-button>
        </template>
        <template slot-scope="scope">
          <el-button v-show="$permits.has(targetPermit,$permits.VIEW)" @click="handleAction($permits.VIEW,scope.row)" size="mini" type="success" round>详情</el-button>
          <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button>
          <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"/>
        </div>
      </el-col>
    </el-row>
    <service-form v-if="control.show" :control="control" :departments="departments" :accounts="accounts" :enums="enums"  :parents="parents" />
  </div>
</template>
<script>
import * as DepartmentApi from '@/api/department'
import * as AccountApi from '@/api/account'
import * as EnumApi from '@/api/enums'
import * as ServiceApi from '@/api/service'
import ServiceForm from './form'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Service',
  components: {
    ServiceForm
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
      departments: [],
      accounts: [],
    	// 权限key
      targetPermit: 'service',
      tableHeight: 0,
      queryForm: {
        title: '',
        serviceType: '',
        serviceState: '',
        current: '',
        size: 10
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parents: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handleEnum()
    this.handlePage()
    this.handleDepartment()
    this.handleAccount()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ServiceApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ServiceApi.enabled({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handleDepartment() {
      let that = this;
      // 获取表格数据
      DepartmentApi.page({enableFlag: true}).then(res => {
        let result = res.data.data;
        that.departments = result.records||[]
      })
    },
    handleAccount() {
      let that = this;
      // 获取表格数据
      AccountApi.page({enableFlag: true,adminFlag: true}).then(res => {
        let result = res.data.data;
        that.accounts = result.records||[]
      })
    },
    handlePage(current) {
      console.log('current', current)
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // 获取表格数据
      ServiceApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
