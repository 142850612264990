<template>
  <div class="app-table-container">

    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">

      <el-table-column
        fixed
        prop="configInfo"
        label="配置信息"
        min-width="100px">
        <template slot-scope="scope">
          <div><span>兼职奖励：</span><span>{{scope.row.partTimeJobAmount}}元</span></div>
          <div><span>团队一级奖励：</span><span>{{scope.row.teamProfitLevel1}}%</span></div>
          <div><span>团队二级奖励：</span><span>{{scope.row.teamProfitLevel2}}%</span></div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="200px">
        <template slot="header">
          <span style="float: left">操作</span>
          <!-- <el-button
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)"
              @click="handleAction($permits.CREATE)">
            + 新建
          </el-button> -->
        </template>
        <template slot-scope="scope">
          <!-- <el-button v-show="$permits.has(targetPermit,$permits.VIEW)" @click="handleAction($permits.VIEW,scope.row)" size="mini" type="success" round>详情</el-button> -->
          <el-button @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button>
          <!-- <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button> -->
          <!-- <el-button v-show="!$permits.has(targetPermit,$permits.LOG)" @click="handleLog($permits.LOG, scope.row)" size="mini" type="info" round>日志</el-button> -->
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>
    <JobDetailForm v-if="control.show" :control="control" :enums="enums" />
  </div>
</template>
<script>

import * as EnumApi from '@/api/enums'
import * as SystemApi from '@/api/system'
import JobDetailForm from './form'
export default {
  name: 'JobDetail',
  components: {
    JobDetailForm
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
    	// 权限key
      targetPermit: 'system',
      tableHeight: 0,
      queryForm: {
        name: '',
        current: '',
        size: ''
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      controlLog: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
    this.handleEnum()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleLog(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.controlLog.show = true;
      that.controlLog.disabled = false;
      that.controlLog.action = action;
      that.controlLog.title = "任务名称: "+item.name;
      that.controlLog.disabled = true;
      that.controlLog.form = item;
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        SystemApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleStop(item) {
      let that = this;
      that.$confirm('', '确定停用吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        SystemApi.enabled({id: item.id, enabled: false}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleStart(item) {
      let that = this;
      that.$confirm('', '确定启用吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        SystemApi.enabled({id: item.id, enabled: true}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      // 获取表格数据
      SystemApi.viewConfigInfo(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = []
        that.tableData.push(result)
        that.paginations.total = 1
        that.paginations.current = 1
        that.paginations.size = 1
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style>
.el-table th, .el-table td {
  padding: .25rem !important;
  height: 1rem !important;
  line-height: 1rem !important;
}
</style>
