<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="title" label="标题" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="picUrl" label="达人申请" :label-width="formLabelWidth">
                <ly-pic-uploader ref="lyuploader" :url="form.picUrl" picSize="750x高不限"></ly-pic-uploader>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="content" label="达人承诺" :label-width="formLabelWidth">
                <ly-rich-editor ref="lyricheditor" :content="form.content"></ly-rich-editor>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-form-item label="考试题" :label-width="formLabelWidth">
            <div class=""
              v-for="(item,index) in (form.remark?JSON.parse(form.remark):[])">
              <el-input
                :disabled="control.disabled" v-model="item.question" autocomplete="off"></el-input>
              <el-input
                :disabled="control.disabled" v-model="item.options" autocomplete="off"></el-input>
              <el-input
                :disabled="control.disabled" v-model="item.answer" autocomplete="off"></el-input>
            </div>
          </el-form-item> -->

          <el-form-item prop="remark" label="考试题" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="50" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import LyRichEditor from '@/components/RichEditor'
import LyPicUploader from '@/components/PicUploader'
import * as EnumApi from '@/api/enums'
import * as BannerApi from '@/api/banner'
export default {
  name: 'BannerForm',
  components: {
    LyPicUploader,
    LyRichEditor
  },
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {},
      picUrl: '',
      flag: '',
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {

  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){

        return
      }
      that.form = Object.assign({}, that.control.form);
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)

      that.form.picUrl = that.$refs.lyuploader.fileList[0].url
      that.form.content = that.$refs.lyricheditor.content1
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              BannerApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.bannerApp = 'ITANDIAN_CUSTOMER_KOL'
              BannerApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
