<template>
  <div class="user-info-box accountInfo">
    <el-tabs v-model="currentTap" type="border-card" @tab-click="handleReset">
      
      <!-- update account info -->

      <el-tab-pane label="账号信息" name="first">

        <el-form ref="form" :rules="form_rules" label-width="4rem" style="margin:10px;width:auto;">

          <el-form-item label="账号">
            <el-col :span="8">
              <span>{{ lyAccount.username }}</span>
            </el-col>
          </el-form-item>

          <el-form-item label="手机号">
            <el-col :span="8">
              <span>{{ lyAccount.phone }}</span>
            </el-col>
          </el-form-item>

          <el-form-item label="邮箱">
            <el-col :span="8">
              <span>{{ lyAccount.email }}</span>
            </el-col>
          </el-form-item>

          <el-form-item label="头像">
            <div class="head_img">
              <img class="avatarId" :src="lyAccount.avatarId" />
            </div>
            <el-button
              size="small"
              class="setting_right"
              type="primary"
              @click.stop="updateAvatar">更换头像</el-button>
            <input type="file" accept="image/*" class="fileInput" @change="handleFile">
          </el-form-item>
          
        </el-form>

      </el-tab-pane>

      <!-- update password -->

      <el-tab-pane label="修改密码" name="second">
        <el-form
          ref="updatePasswordForm"
          :model="updatePasswordForm"
          :rules="rules"
          label-width="130px"
          label-position="right"
        >
          <el-form-item prop="oldPassword" label="旧密码">
            <el-col :span="20">
              <el-input v-model="updatePasswordForm.oldPassword" type="password" />
            </el-col>
          </el-form-item>
          <el-form-item prop="newPassword" label="新密码">
            <el-col :span="20">
              <el-input v-model="updatePasswordForm.newPassword" type="password" />
            </el-col>
          </el-form-item>
          <el-form-item prop="repassword" label="再次输入新密码">
            <el-col :span="20">
              <el-input v-model="updatePasswordForm.repassword" type="password" />
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updatePassword()">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script>
const OSS = require('ali-oss');
import  * as AliossApi from '@/api/alioss'
import  * as AccountApi from '@/api/account'
import * as Validator from '@/utils/validate'
export default {
  name: 'MyInfo',
  components: {
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value !== this.updatePasswordForm.newPassword) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      currentTap: 'first',
      updatePasswordForm: {
        oldPassword: '',
        newPassword: '',
        repassword: ''
      },
      form_rules: {
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { validator: Validator.testPassword, trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: Validator.testPassword, trigger: 'blur' }
        ],
        repassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      },
      lyAccount: {}
    }
  },
  mounted() {
    this.lyAccount = JSON.parse(localStorage.getItem('lyAccount'));
  },
  methods: {
    handleReset() {
      this.$refs['updatePasswordForm'].clearValidate()
      this.updatePasswordForm = {}
    },
    updateAvatar() {
      this.$el.querySelector('.fileInput').click()
    },
    async handleSave(avatarId) {
      const params = {
        id: this.lyAccount.id,
        avatarId: avatarId
      }
      const res = await AccountApi.update(params)
      if(res.data.code === 0){
        this.lyAccount.avatarId = avatarId
        localStorage.setItem('lyAccount', JSON.stringify(this.lyAccount))
        this.$eventHub.$emit('onUpdateUser', this.lyAccount);
        this.$message({
          message: '头像更新成功',
          type: 'success'
        })
      }
    },
    async updatePassword() {
      this.$refs['updatePasswordForm'].validate(valid => {
        if (!valid) {
          return false
        }
      })
      const params = {}
      let md5 = require('md5')
      params.id = this.lyAccount.id
      params.oldPassword = md5(this.updatePasswordForm.oldPassword)
      params.newPassword = md5(this.updatePasswordForm.newPassword)
      params.repassword = md5(this.updatePasswordForm.repassword)
      let res = await AccountApi.updatePassword(params)
      if (res.data.code == 0) {
        this.$message({
          message: '密码修改成功,请重新登录',
          type: 'success'
        })
        this.handleReset()
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.$router.push('/login')
      }
    },
    async handleFile(e) {
      const $target = e.target || e.srcElement
      var file = $target.files[0]

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }
      
      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      // OSS.urlib是sdk内部封装的发送请求的逻辑，开发者完全可以使用任何发请求的库向`sts-server`发送请求
      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
          this.handleSave(result.res.requestUrls[0].split('?')[0])
        }).catch(function(err) {})
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  }
}
</script>
<style scoped>
.user-info-box {
  padding: 20px;
}
.head_img {
  float: left;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  overflow: hidden;
  margin-bottom: 15px;
}
.setting_right {
  margin: 34px 0 0 20px;
}
.head_img img {
  width: 100% !important;
  height: auto;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.accountInfo .avatar {
  display: block;
}
.accountInfo .fileInput {
  display: none;
}
</style>
<style>
.accountInfo .el-form-item__content {
  color: #999;
}
</style>