<template>
<el-row>
  <el-col :span="24">
    <quill-editor id="quill-container"
        v-model="content1"
        ref="myQuillEditor"
        :options="toolbarFlag?editorOption:editorOption2"
        @ready="onEditorReady($event)"
        @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
        @change="onEditorChange($event)">
    </quill-editor>
    <!-- upload picture -->
    <div style="display: none !important;">
      <input class="handleFileBtn" type="file" accept="image/*" @change="handleFile" style="display: none;">
    </div>
  </el-col>
</el-row>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
const OSS = require('ali-oss');
import Quill from 'quill'

export default {
  name: 'RichEditor',
  props: {
    content: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    toolbarFlag: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.content1 = this.content
    // init height
    window.addEventListener('resize', this.resizeQuill);
    this.resizeQuill();
  },
  computed: {
    editor() {
        return this.$refs.myQuillEditor.quill;
    },
  },
  data() {
    return {
        editorOption: {
          placeholder: this.placeholder,
          modules:{
  　　　　　　toolbar: [
                // [{ 'size': ['small' ,'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                ['blockquote'],
                [{ 'color': [] }, { 'background': [] }],  // dropdown with defaults from theme
                // [{ 'font': [] }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link', 'image']
              ],
          },
          theme:'snow'
      },
      editorOption2: {
          placeholder: this.placeholder,
          modules:{
  　　　　　　toolbar: false
          },
          // theme:'snow'
      },
      content1: '',
      qlScrollHeight: 0
    }
  },
  methods: {
    resizeQuill() {
      // console.log(11);
      // const quill = this.$refs.myQuillEditor.quill
      // console.log('quill', this.$refs.myQuillEditor.quill.container.scrollHeight);
      // this.qlScrollHeight = quill.container.scrollHeight;
      // quill.container.style.height = quill.container.scrollHeight + 'px';
    },
    addImageHandler(){
      this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.updatePicture)
    },
    updatePicture() {
      this.$el.querySelector('.handleFileBtn').click()
    },
    async handleFile(e) {
      var that = this;
      console.log('e', e)
      const $target = e.target || e.srcElement
      var file = $target.files[0]

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }

      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
        let picUrl = result.res.requestUrls[0];
        console.log('picUrl', picUrl);
        let url =  picUrl.split("?")[0];
        if (url != null && url.length > 0) {
          that.addImgRange = that.$refs.myQuillEditor.quill.getSelection()
          let imageIndex = that.addImgRange == null?0:that.addImgRange.index;
          console.log('that.addImgRange', that.addImgRange, url);
          that.$refs.myQuillEditor.quill.insertEmbed(imageIndex, 'image', url, Quill.sources.USER)
          // console.log('content', this.content);
        } else {
          that.$message.warning("图片增加失败")
        }
      }).catch(function(err) {})
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
       (c) => {
          var r = (Math.random() * 16) | 0
          var v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        })
    },
    onEditorReady(editor) { // 准备编辑器
      if(this.toolbarFlag){
        editor.getModule("toolbar").addHandler("image", this.updatePicture)
      }
    },
    onEditorBlur(){}, // 失去焦点事件
    onEditorFocus(){}, // 获得焦点事件
    onEditorChange(){
      // console.log(this.content);
    }, // 内容改变事件
  }
}
</script>

<style scoped lang="scss">
/deep/ .ql-editor {
  height: calc( 100vh - 20rem );
  overflow-y: scroll;
}
</style>
