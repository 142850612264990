<template>
  <div class="nofind">
    <el-empty class="data-empty" image="https://www.creatimix.com/static/empty.jpg" description="当前网页不存在" style="margin: 0 auto;background-color: #FFFFFF;">
      <router-link to="/" class="back" @click.native="onBack">
        <el-button size="medium" type="primary" >回到首页</el-button>
      </router-link>
    </el-empty>

  </div>
</template>
<script>
export default {
  methods: {
    onBack() {
      localStorage.removeItem('activeId')
    }
  }
}
</script>

<style scoped>
.back {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.nofind {
  height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}
</style>

