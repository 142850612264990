<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="资源名称" :label-width="formLabelWidth">
              <el-input v-model="queryForm.name" maxlength="9" size="small" placeholder="请输入资源名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="category" label="类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="queryForm.category"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ResourceCategoryEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
           </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <!-- <el-table-column prop="id" label="id" type="selection" fixed></el-table-column> -->
      <el-table-tree-column 
        fixed
        prop="name" 
        label="资源名称" 
        levelKey="depth"
        treeKey="id"
        parentKey="parentId"
        childKey="children"
        min-width="165px">
        </el-table-tree-column>

      <el-table-column 
        prop="categoryLabel" 
        label="类型" 
        min-width="80px"/>

      <el-table-column 
        prop="subcategoryLabel" 
        label="子类型" 
        min-width="80px" />

      <!-- <el-table-column 
        prop="link" 
        label="页面路径" 
        min-width="165px"/> -->

      <el-table-column 
        prop="orderNumber" 
        label="排序值" 
        min-width="80px"/>

      <el-table-column 
        prop="publicFlag" 
        label="是否公开" 
        min-width="80px">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="scope.row.publicFlag" round>是</el-button>
          <el-button type="danger" size="mini" v-else round>否</el-button>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="200px">
        <template slot="header">
          <span style="float: left">操作</span>
          <el-button
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)" 
              @click="handleAction($permits.CREATE)">
            + 新建
          </el-button>
        </template>
        <template slot-scope="scope">
          <el-button v-show="$permits.has(targetPermit,$permits.VIEW)" @click="handleAction($permits.VIEW,scope.row)" size="mini" type="success" round>详情</el-button>
          <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button>
          <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button>
        </template> 
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"/>
        </div>
      </el-col>
    </el-row>
    <resource-form v-if="control.show" :control="control" :enums="enums"  :parentResources="parentResources" />
  </div>
</template>
<script>

import * as EnumApi from '@/api/enums'
import * as ResourceApi from '@/api/resource'
import ResourceForm from './form'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Resource',
  components: {
    ResourceForm
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
    	targetPermit: 'resource',
      tableHeight: 0,
      queryForm: {
        name: '',
        category: '',
        current: '',
        size: 10000
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parentResources: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handleEnum()
    this.handlePage()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ResourceApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage() {
      let that = this;
      // 获取表格数据
      ResourceApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = buildTree(result.records, 0, 0)
        that.parentResources = treeToList(that.tableData)
        that.parentResources.unshift({id: 0, name: "无", depth: 0})
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
