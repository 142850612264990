<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="'当前步骤：'+nodeTitle"
        :enums="enums"
        :with-header="true"
        :visible.sync="control.showConfig"
        direction="rtl"
        :append-to-body="true"
        :modal-append-to-body="false"
        :modal="false"
        size="80%">
        <!-- <el-divider>创建</el-divider> -->
        <div>
          <el-button type="success" plain>{{formNode.workflowNodeTypeLabel}}</el-button>
          <el-button type="primary" @click="handleSubmit('update')" size="mini">保存</el-button>
        </div>
        <!-- 左右两列 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form status-icon :model="formNode" :rules="rules" ref="formNode" label-width="100px">
              <el-row>
                <el-col :span="12">
                  <el-form-item prop="runTimes" label="执行次数" :label-width="formLabelWidth">
                    <el-input type="number" min="1" max="5"  v-model="formNode.runTimes" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item prop="modelType" label="模型" :label-width="formLabelWidth">
                      <el-select
                        :disabled="control.disabled"
                        v-model="formNode.modelType"
                        clearable
                        filterable
                        size="small"
                        placeholder="请选择">
                      <el-option
                        v-for="item in enums.ModelTypeEnum"
                        :key="item.name"
                        :label="item.desc"
                        :value="item.name"
                      />
                    </el-select>
                 </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item prop="temperature" label="温度" :label-width="formLabelWidth">
                    <el-input type="number" min="0" max="1"  v-model="formNode.temperature" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- <div>输入</div> -->
              <el-divider>输入</el-divider>
              <el-row>
                <el-col :span="12">
                  <el-form-item prop="input" label="名称" :label-width="formLabelWidth">
                    <el-input placeholder="input" v-model="formNode.input" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="inputValue" label="值" :label-width="formLabelWidth">
                    <el-input placeholder="值" v-model="formNode.value" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item prop="content" label="提示词" :label-width="formLabelWidth">
                <el-input :autosize="{minRows: 10, maxRows:10}" placeholder="提示词" type="textarea" :disabled="control.disabled" v-model="formNode.content" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item prop="remark" label="主题库" :label-width="formLabelWidth">
                <el-input :autosize="{minRows: 10, maxRows:10}" placeholder="主题库" type="textarea" :disabled="control.disabled" v-model="formNode.remark" autocomplete="off"></el-input>
              </el-form-item>

              <!-- <div>输出</div> -->
              <el-divider>输出</el-divider>
              <el-row>
                <el-col :span="12">
                  <el-form-item prop="output" label="名称" :label-width="formLabelWidth">
                    <el-input placeholder="output" v-model="formNode.output" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="outputValue" label="值" :label-width="formLabelWidth">
                    <el-input placeholder="值" v-model="formNode.value" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>
          </el-col>
          <el-col :span="12">
            <div style="margin-top: -40px; padding: 8px 0;">
              <el-button type="primary" @click="handleSubmit('debug')" size="mini">调试</el-button>
            </div>
            <div class="border: 1px solid #DCDFE6;min-height: 500px;width: 100%;" v-html="result">
              结果
            </div>
          </el-col>
        </el-row>


    </el-drawer>
</template>

<script>
import * as EnumApi from '@/api/enums'
import * as WorkflowNodeApi from '@/api/workflownode'
import * as ImmessageApi from '@/api/immessage'
import marked from 'marked';
export default {
  name: 'ConfigForm',
  props: {
    control: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
      formNode: {
        modelType: '',
        workflowNodeType: 0,
        runTimes: 1,
        content: '',
        temperature: 0.7
      },
      nodeTitle: '',
      result: '',
      enums: [],
      rules: {
        modelType: [
          { required: true, message: '请选择一个模型', trigger: 'blur' }
        ],
        content: [
          { required: false, message: '请输入提示词', trigger: 'blur' }
        ]
      },
    }
  },
  mounted() {
    this.init();
  },
  computed: {

  },
  methods: {
    init(){
      let that = this;

      let formNode = JSON.parse(localStorage.getItem("formNode"))
      console.log('formNodeId', formNode.id);

      WorkflowNodeApi.view(formNode.id).then(res => {
        let result = res.data;
        that.formNode = result.data
        that.nodeTitle = that.formNode.title
      })
      this.handleEnum()
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['formNode'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case 'debug':
              ImmessageApi.create({content: that.formNode.content, modelType: that.formNode.modelType}).then(res => {
                let completion =  res.data.data
                this.result = marked.marked(completion.result.output.content.replace(/\n/g, "\n\n"))
              })
              console.log("switch >>> " + action)
              break;
            case that.$permits.UPDATE:
              WorkflowNodeApi.update(that.formNode).then(res => {
                // that.control.showConfig = false;
                if(res.data.code == 0){
                  this.$message.success('保存成功');
                }
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              WorkflowNodeApi.create(that.formNode).then(res => {
                // that.control.showConfig = false;
                // this.$message.success('保存成功');
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
.g6-x {
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-left: 20px;
}
/* 提示框的样式 */
.g6-tooltip {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #545454;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 8px;
  box-shadow: rgb(174, 174, 174) 0px 0px 10px;
}
</style>
