/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {Number} phone
 * @returns {Boolean}
 */
export function isPhone(phone) {
  return /^((12[0-9])|(13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/.test(phone)
}

export function testPassword(rule, value, callback) {
  var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
  if (!reg.test(value) || value.length < 6 || value.length > 12) {
    callback(new Error('密码必须为6-12位的数字和字母组合'))
  } else {
    callback()
  }
}