<template>  <!-- 新建/编辑 -->
<el-drawer
    :title="control.title"
    :enums="enums"
    :with-header="false"
    :visible.sync="control.voucher.show"
    direction="rtl"
    size="70%">
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="充值记录" :label-width="formLabelWidth">
              <!-- <el-input v-model="queryForm.title" maxlength="9" size="small" placeholder="请输入品类名称" /> -->
            </el-form-item>
          </el-col>
          </el-col>
          <!-- <el-col :span="6" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage()">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col> -->
      </el-row>
    </el-form>

    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">

      <el-table-column
        fixed
        prop="storeInfo"
        label="店铺名称"
        min-width="200px">
        <template slot-scope="scope">
          {{scope.row.store?scope.row.store.storeName:''}}
        </template>
      </el-table-column>

      <el-table-column
        fixed
        prop="profitTypeLabel"
        label="类型"
        min-width="100px" />

      <el-table-column
        prop="amount"
        label="金额"
        align="center"
        min-width="100px" >
        <template slot-scope="scope">
          <span v-if="scope.row.incomeFlag" style="color: red;">+{{scope.row.amount}}</span>
          <span v-if="!scope.row.incomeFlag" style="color: red;">-{{scope.row.amount}}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="creator"
        label="操作人"
        min-width="80px">
        <template slot-scope="scope">
          {{scope.row.creator?scope.row.creator.name:'--'}}
        </template>
      </el-table-column>

      <el-table-column
        prop="createTime"
        label="充值时间"
        min-width="165px"/>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>
    <!-- <CategoryForm v-if="control.show" :control="control" :enums="enums" /> -->
  </div>
</el-drawer>
</template>
<script>

import * as EnumApi from '@/api/enums'
import * as CategoryApi from '@/api/category'
import * as AccountProfitLogApi from '@/api/accountprofitlog'
import CategoryForm from './form'
export default {
  name: 'Category',
  components: {
    CategoryForm
  },
  props: {
    enums: '',
    categories: [],
    parents: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
    	targetPermit: 'category',
      tableHeight: 0,
      queryForm: {
        name: '',
        current: '',
        size: '',
        profitTypes: ["MERCHANT_COIN_VOUCHER","MERCHANT_COIN_COST"]
      },
      tableData: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
    this.handleEnum()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        CategoryApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        CategoryApi.enabled({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {

    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      that.queryForm.storeId = that.control.form.id
      // 获取表格数据
      AccountProfitLogApi.merchantPage(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
