<template>
  <div class="writer-cashier">
    <!-- <div class="writer-cashier-title">收银台</div> -->

    <div class="writer-cashier-energy">
      <span class="writer-cashier-energy-num">{{getParams('energy')}}</span>
      <span class="writer-cashier-energy-label">能量</span>
    </div>

    <div class="writer-cashier-money">
      <span class="writer-cashier-money-flag">¥</span>
      <span>{{getParams('price')}}</span>
      <span class="writer-cashier-money-flag">元</span>
    </div>

    <div class="writer-cashier-info">
      <div class="writer-cashier-info-item">
        <span>手机号：</span>
        <span>{{getParams('phone')}}</span>
      </div>

      <div class="writer-cashier-info-item">
        <span>用户ID：</span>
        <span>{{getParams('accountId')}}</span>
      </div>

      <div class="writer-cashier-info-item">
        <span>订单号：</span>
        <span>{{getParams('orderId')}}</span>
      </div>

      <div class="writer-cashier-info-payway">
        <div class="writer-cashier-info-item">
          支付方式:
        </div>
        <div class="writer-cashier-info-item">
          <el-radio-group v-model="form.payWay">
            <el-radio v-if="!getParams('payWay') || item.name == getParams('payWay')"  v-for="(item,index)  in [{name: 'WEIXIN', desc: '微信支付'},{name: 'ZHIFUBAO', desc: '支付宝支付'}]" :label="item.name" :key="index">
              {{item.desc}}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  <!-- <div style="word-break: break-all;">
      {{decodeURIComponent(getParams('url'))}}
    </div> -->

    <div class="writer-cashier-info-item" style="width: 100%;padding: 0 4rem;position: absolute;bottom: 4rem;">
      <el-button style="width: 100%;" @click="pay" type="primary">立即支付</el-button>
    </div>
  </div>
</template>
<script>
import * as PaymentApi from '@/api/payment'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Cashier',
  components: {

  },
  data() {
    return {
      form: {
        tradeType: 'mweb',
        payWay: '',
        fromType: 'hupijiao',
        orderId: ''
      },
      isLoading: true
    }
  },
  computed: {
  	...mapState(['user'])
  },
  mounted() {
    this.$nextTick(() => {

    })
    this.form.payWay = this.getParams('payWay')
    this.form.orderId = this.getParams('orderId')
    // if(this.getParams('autoFlag')){
    //   let redirectUrl = process.env.VUE_APP_BASE_API_URL
    //   + 'auth-service/payments/wx/redirect?payWay='
    //   +this.form.payWay
    //   +'&orderId='+this.getParams('orderId')
    //   +'&productId='+this.getParams('productId')
    //   +'&Authorization='+localStorage.getItem('lyToken');
    //   console.log('href', redirectUrl);

    // }
  },
  methods: {
    ...mapMutations(['setUser']),
    pay() {
      console.log('pay', this.getParams('url'))
      // this.$message.error(this.getParams('url'));
      if(this.getParams('url')){
        window.location.href = decodeURIComponent(this.getParams('url'))
      }else{
        console.log('pay', this.form.payWay);
        if(!this.form.payWay){
          this.$message.error('请选择支付方式');
          return false
        }

        let payWay = this.form.payWay?this.form.payWay:''
        let redirectUrl = process.env.VUE_APP_BASE_API_URL
        + 'auth-service/payments/wx/redirect?payWay='
        +this.form.payWay
        +'&orderId='+this.getParams('orderId')
        +'&productId='+this.getParams('productId')
        +'&Authorization='+this.getParams('Authorization');
        console.log('href', redirectUrl);
        window.location.href = redirectUrl
      }

      // window.open(decodeURIComponent(this.getParams('url')), '_blank');
    },
    getParams(name){
      const queryString = window.location.search;

      // 使用正则表达式匹配查询字符串中的参数
      const params = {};
      const regex = /([^?=&]+)=([^&]*)/g;
      let match;
      while (match = regex.exec(queryString)) {
          params[match[1]] = match[2];
      }
      return params[name]
    }
  }
}
</script>
<style scoped lang="scss">
.writer-cashier {
  color: #000000;
  &-title {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
  &-energy {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    padding-top: 6rem;
    &-num {
      padding: .2rem;
    }
  }
  &-money {
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
    padding-top: 2rem;
    &-flag {
      font-size: 1.3rem;
      padding: .2rem;
    }
  }
  &-info {
    font-weight: 400;
    font-size: 1rem;
    padding: 4rem 4rem;
    &-item {
      padding: .8rem 0;
    }
  }
}
</style>
