<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item prop="parentId" label="父级名称" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.parentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                  v-for="item in parentWeixinmenus"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="appid" label="公众号appid" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.appid"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in accounts"
                  :key="item.appid"
                  :label="item.name"
                  :value="item.appid"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="name" label="菜单名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.type"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in [{name: $permits.VIEW,desc: '跳转网页'},{name: 'click',desc: '点击'},{name: 'miniprogram',desc: '跳转小程序'}]"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="key" label="点击事件" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.key" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="url" label="网页跳转链接" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.url" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="maAppid" label="小程序appid" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.maAppid" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item prop="pagePath" label="小程序跳转链接" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.pagePath" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="enableFlag" label="是否启用" :label-width="formLabelWidth">
                <el-switch
                  :disabled="control.disabled" 
                  v-model="form.enableFlag"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="orderNumber" label="排序值" :label-width="formLabelWidth">
                <el-input type="number" :disabled="control.disabled" v-model.number="form.orderNumber" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as WeixinmenuApi from '@/api/weixinmenu'
import * as WxAccountApi from '@/api/weixinaccount'
export default {
  name: 'WeixinmenuForm',
  props: {
    enums: '',
    parentWeixinmenus: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      accounts: [],
      formLabelWidth: '120px',
      form: {orderNumber: 0},
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        orderNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
    this.handleWeixinAccountPage()
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      WeixinmenuApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              WeixinmenuApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              WeixinmenuApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    handleWeixinAccountPage() {
      let that = this;
      // 获取表格数据
      WxAccountApi.page({current:1,size:500}).then(res => {
        that.accounts = res.data.data.records;
      })
    },
  }
}
</script>

<style>
</style>