<template>
<div style="padding: 1rem 1rem;">
  <div style="font-weight: 400;font-size: 20px;color: rgba(0,0,0,0.8);margin-bottom: 1rem;">购买记录</div>
  <!-- 数据表格 -->
  <el-table
    :header-cell-style="{background:'#E5EEFE',color:'rgba(0,0,0,0.4)','font-weight': 400}"
    :data="tableData"
    :highlight-current-row="false"
    ref="tableContainer"
    class="table-container">
    <el-table-column
      prop="id"
      label="订单ID"
      min-width="190px" />
    <el-table-column
      prop="productName"
      label="商品"
      min-width="120px" >
      <template slot-scope="scope">
        <span class=""
         style="font-weight: 400;font-size: 12px;">
          {{''+scope.row.sku.energy/10000+'万能量'}}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      prop="payMoney"
      label="价格"
      min-width="70px" >
      <template slot-scope="scope">
        ￥{{scope.row.payMoney}}
      </template>
    </el-table-column>

    <el-table-column
      prop="payState"
      label="支付状态"
      align="center"
      min-width="100px">
      <template slot-scope="scope">
        <span v-if="scope.row.payState == 'YES'" class=""
         style="font-weight: 400;font-size: 12px;color: #2BA471;background: #E3F9E9;border-radius: 3px;border: 1px solid #2BA471;padding: .2rem;">
          支付成功
        </span>
        <span v-else class="" style="padding: .2rem;">
          待支付
        </span>
      </template>
    </el-table-column>

    <el-table-column
      prop="payTime"
      label="支付时间"
      min-width="180px"/>

    <el-table-column
      prop="payWayLabel"
      label="支付方式"
      min-width="100px"/>

  </el-table>
<!--  <el-empty v-if="tableData.length==0" image="https://www.creatimix.com/static/empty.jpg" description="还没有购买记录" style="margin: 0 auto;">
    <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">Start your journey!</p>
  </el-empty> -->

  <el-row>
    <el-col>
      <div class="pagination">
        <el-pagination background
          hide-on-single-page
          :page-sizes="paginations.pages"
          :page-size="paginations.size"
          :layout="paginations.layout"
          :total="paginations.total"
          :current-page.sync="paginations.current"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-col>
  </el-row>
</div>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as OrderApi from '@/api/order'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {

  },
  data() {
    return {
      sliderBoxActive: 0,
      fromType: 'writer_client',
      dialogShowGenbyoutline: false,
      dialogShowGenbyidea: false,
      enums: {"ArticleTypeEnum":[{"desc":"","name":""}]},
    	targetPermit: 'article',
      queryForm: {
        payState: 'YES',
        fromType: 'writer-web',
        current: '',
        size: 20
      },
      tableData: [],
      activeMenuItem: 1,
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
    })
    this.handlePage()
  },
  computed: {
  	...mapState(['user', 'story']),
    isMobile() {
      return window.innerWidth <= 768 ? true : false;
    }
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogFlag']),
    handleRemove(item) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        OrderApi.remove(item.id).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      console.log('workspace index user', this.user);
      if(!this.user || !this.user.id){
        return false;
      }
      that.queryForm.accountId = this.user.id
      // 获取表格数据
      OrderApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

/* 取消行悬停效果 */
/deep/ .el-table--enable-row-hover {
    background-color: transparent !important;
}

/* 取消行过渡效果 */
/deep/ .el-table--enable-row-transition {
    transition: none !important;
}
/deep/ .el-table th.el-table__cell.is-leaf, /deep/ .el-table td.el-table__cell {
  border-bottom: none;
}
/deep/ td.el-table__cell {
  background-color: transparent !important;
}
// .el-table--enable-row-transition
/deep/ .el-table__row:hover{
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #366EF4 !important;
}

</style>
