<template>
<el-row>
  <el-col :span="20">
    <el-upload
      class="ly-uploader"
      action="#"
      :on-preview="handlePreview"
      :on-success="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      list-type="picture"
      :limit="limit"
      :http-request="httpRequest">
      <el-button slot="trigger" size="small" type="primary">选取图片</el-button>
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpeg/jpg/png文件，且不超过500kb</div> -->
      <div slot="tip" class="el-upload__tip">图片尺寸：{{picSize}}</div>
    </el-upload>
  </el-col>
  <el-col :span="4" v-if="fileList.length > 0">
    预览
    <el-image
      style ="width:60px; height: 60px"
      :src="fileList.map(item => item.url)[0]"
      :preview-src-list="fileList.map(item => item.url)"
    />
  </el-col>
</el-row>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
const OSS = require('ali-oss');
export default {
  name: 'RichEditor',
  props: {
    picSize: {
      type: String,
      default: '160x160'
    },
    url: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    if(this.url){
      this.url.split(',').forEach((item,inex)=>{
        this.fileList.push({name: '', url: item})
      })
    }
  },
  computed: {

  },
  data() {
    return {
        fileList: []
    }
  },
  methods: {
    async httpRequest(item) {
      console.log('httpRequest', item);
      var that = this;
      var file = item.file

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }

      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + that.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
        let picUrl = result.res.requestUrls[0];
        that.fileList.push({name: file.name, url: picUrl})
        console.log('picUrl', picUrl, that.fileList);

        let url =  picUrl.split("?")[0];
        if (url != null && url.length > 0) {

        } else {
          that.$message.warning("图片增加失败")
        }
      }).catch(function(err) {})
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      let temp = []
      this.fileList = temp.concat(fileList)
    },
    handlePreview() {
      console.log('handlePreview', 1);
      // picUrl
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
       (c) => {
          var r = (Math.random() * 16) | 0
          var v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
