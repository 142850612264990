<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-form-item prop="lastName" label="姓" :label-width="formLabelWidth">
            <el-input placeholder="姓" :disabled="control.disabled" v-model="form.lastName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="firstName" label="名" :label-width="formLabelWidth">
            <el-input placeholder="名" :disabled="control.disabled" v-model="form.firstName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
import * as EnumApi from '@/api/enums'
import * as StoryCharacterNameApi from '@/api/storycharactername'
import Quill from 'quill'
const OSS = require('ali-oss');
export default {
  name: 'FileForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      editorOption: {
        placeholder: '请填写简介',
        modules:{
　　　　　　toolbar: [
              // [{ 'size': ['small' ,'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              ['blockquote'],
              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              // [{ 'font': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['link', 'image']
            ],
        },
        // theme:'snow'
      },
      formLabelWidth: '100px',
      form: {picUrl: ''},
      picUrl: '',
      flag: '',
      rules: {
        lastName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        firstName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {

  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      StoryCharacterNameApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
        this.picUrl = that.form.picUrl
      })
    },
    addImageHandler(){
      this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.updatePictureForEditor)
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              StoryCharacterNameApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              StoryCharacterNameApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    updatePicture() {
      this.flag = 'logo'
      this.$el.querySelector('.logo').click()
    },
    updatePictureForEditor() {
      this.flag = 'brief'
      this.$el.querySelector('.brief').click()
    },
    async handleFile(e) {
      var vm = this;
      console.log('e', e)
      const $target = e.target || e.srcElement
      var file = $target.files[0]

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }

      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      // OSS.urlib是sdk内部封装的发送请求的逻辑，开发者完全可以使用任何发请求的库向`sts-server`发送请求
      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
        let picUrl = result.res.requestUrls[0];
        console.log('picUrl', picUrl);
        picUrl = picUrl.split("?")[0];

        if(this.flag == 'logo'){
          this.form.picUrl = picUrl
          this.picUrl = picUrl
          console.log('this.form.picUrl', this.form.picUrl);
        }

        if(this.flag == 'brief'){
          if (picUrl != null && picUrl.length > 0) {
            var value = picUrl
            vm.addImgRange = vm.$refs.myQuillEditor.quill.getSelection()
            console.log('vm.addImgRange', vm.addImgRange);
            value = value.indexOf('http') != -1 ? value : 'http:' + value
            console.log('value', value);
            vm.$refs.myQuillEditor.quill.insertEmbed(vm.addImgRange != null?vm.addImgRange.index:0, 'image', value, Quill.sources.USER)
            console.log('content', this.form.content);
          } else {
            vm.$message.warning("图标增加失败")
          }
        }
      }).catch(function(err) {})
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    onEditorReady(editor) { // 准备编辑器
    },
    onEditorBlur(){}, // 失去焦点事件
    onEditorFocus(){}, // 获得焦点事件
    onEditorChange(){
      console.log(this.form);
    }, // 内容改变事件
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
