import { create } from '@/api/statlog'
function has(target, permit) {
	let actions = getActions(target, permit)
	const merge = target + ':' + permit

	let flag = actions.some(item => item.link == merge)
	// console.log('merge, flag', merge, flag)
	return flag;
}
function getActions(target, permit) {
	let actions = JSON.parse(localStorage.getItem('lyActions'))
	return (actions.find(item => item.link == target) || {}).children || []
}
function take(target, permit) {
	let actions = getActions(target, permit)
	const merge = target + ':' + permit

	return actions.find(item => item.link == merge) || {}
}
function log(params) {
  console.log('STAT:LOG::', params);
  if(params.target && params.permit){
    params = take(params.target, params.permit)
  }
	// create({predicate: 'ADMIN_OPERATION', objectId: params.id, belongId: params.parentId})
}
export default {
	has,
  log,
	ENABLE0: 'enable0',
	ENABLE1: 'enable1',
	LOG: 'log',
	UPLOAD: 'upload',
	CREATE: 'create',
	UPDATE: 'update',
	REMOVE: 'remove',
	PAGE: 'page',
	VIEW: 'view'
}
