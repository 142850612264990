import request from '@/utils/request'


const url = '/auth-service/files'

export function page(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

export function view(id) {
  return request({
    url: `${url}/${id}`,
    method: 'get',
    params: {}
  })
}

export function create(params) {
  return request({
    url: `${url}`,
    method: 'post',
    params: params
  })
}

export function importData(params) {
  return request({
    url: `${url}/import`,
    method: 'post',
    params: params
  })
}

export function update(params) {
  return request({
    url: `${url}/${params.id}`,
    method: 'put',
    params: params
  })
}

export function remove(id) {
  return request({
    url: `${url}/${id}`,
    method: 'delete'
  })
}

export function getUploadAction() {
  return process.env.VUE_APP_BASE_API_URL + `auth-service/files/upload`;
}

// 下载
export function down (params) {
  return request({
    url: `auth-service/files/down/${params.id}`,
    method: 'get',
    responseType: "blob",
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
  })
}
