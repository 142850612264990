<template>
  <div style="padding: 0.5rem; line-height: 3rem; vertical-align: middle; display: flex; justify-content: space-between;">

    <!-- org section -->
    <div>
      <img class="org-logo" v-if="user.organization" :src="user.organization.avatarId">
      <img class="org-logo" v-else src="../assets/logo.png">
      <span >{{ '智能体' }}</span>
    </div>

    <!-- user section -->
    <el-dropdown trigger="click" @command="handleCommand">
      <div style="cursor: pointer;">
        <img class="user-avatar" v-if="user.avatarId" :src="user.avatarId">
        <img class="user-avatar" v-else src="../assets/default_avatar.png">
        <span>{{ user.username }}</span>
        <i class="el-icon-caret-bottom el-icon--right" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="info">账号信息</el-dropdown-item>
        <el-dropdown-item divided command="logout">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: 'HeadNav',
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('lyAccount'))
    this.$eventHub.$on('onUpdateUser', async(params) => {
      console.log("onUpdateUser", params);
      this.user = JSON.parse(localStorage.getItem('lyAccount'))
    })
  },
  created() {
  },
  methods: {
    handleCommand(cmditem) {
      if (!cmditem) {
        this.$message('菜单选项缺少command属性')
        return
      }
      switch (cmditem) {
        case 'info':
          // 个人信息
          this.$router.push('/myinfo')
          break
        case 'logout':
          // 清除
          localStorage.removeItem('lyToken')
          localStorage.removeItem('lyAccount')
          localStorage.removeItem('lyActions')
          localStorage.removeItem('activeId')
          // 返回登录页面
          this.$router.push('/login')
          break
      }
    }
  }
}
</script>

<style scoped>
div {
  color: #ffffff;
}
.org-logo {
  width: 2rem;
  height: auto;
}
.user-avatar {
  width: 2rem;
  height: auto;
}
img {
  border-radius: 50%;
  vertical-align: middle;
}
span {
  vertical-align: middle;
  padding-left: 0.5rem;
}
</style>
