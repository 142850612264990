import request from '@/utils/request'

const url = '/auth-service/tenants'

export function page(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

export function view(id) {
  return request({
    url: `${url}/${id}`,
    method: 'get',
    params: {}
  })
}

export function create(params) {
  return request({
    url: `${url}`,
    method: 'post',
    params: params
  })
}

export function update(params) {
  return request({
    url: `${url}/${params.id}`,
    method: 'put',
    params: params
  })
}

export function remove(id) {
  return request({
    url: `${url}/${id}`,
    method: 'delete'
  })
}

export function enabled(params) {
  return request({
    url: `${url}/enabled/${params.id}/${params.enabled}`,
    method: 'post'
  })
}
