<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="兼职奖励" prop="partTimeJobAmount" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.partTimeJobAmount" autocomplete="off"></el-input>元
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="团队一级奖励" prop="teamProfitLevel1" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.teamProfitLevel1" autocomplete="off"></el-input>%
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="团队二级奖励" prop="teamProfitLevel2" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.teamProfitLevel2" autocomplete="off"></el-input>%
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
            <el-button v-if="!control.disabled" @click="resetForm('form')" size="mini">重置</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as EnumApi from '@/api/enums'
import * as SystemApi from '@/api/system'

export default {
  name: 'JobDetailForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '6rem',
      form: {},
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }

      that.form = that.control.form;
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      let passed = false
      that.$refs['form'].validate((valid) => {
        passed = valid;
      });
      console.log("passed >> " + passed)
      if(!passed){
        return;
      }
      switch(action){
        case that.$permits.UPDATE:
          SystemApi.updateConfigInfo(that.form).then(res => {
            that.control.show = false;
            that.$parent.handlePage();
          })
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          SystemApi.create(that.form).then(res => {
            that.control.show = false;
            that.$parent.handlePage();
          })
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
  }
}
</script>

<style>
</style>
