<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="title" label="书名" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item prop="articleType" label="状态" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.articleType"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ArticleTypeEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="source" label="来源" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.source" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="tag" label="标签" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.tag" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="fileUrl" label="文件" :label-width="formLabelWidth">
                  <ly-file-uploader ref="lyuploader" :url="form.fileUrl" :accept="accept" :name="form.fileUrl"></ly-file-uploader>
              </el-form-item>
            </el-col>
          </el-row>
<!--
          <el-row>
            <el-col :span="12">
              <el-form-item prop="album" label="轮播图" :label-width="formLabelWidth">
                  <ly-pic-uploader :limit="9" ref="lyuploader1" :url="form.album" picSize="750x540"></ly-pic-uploader>
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-form-item prop="content" label="内容" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="10" :disabled="control.disabled" v-model="form.content" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import LyFileUploader from '@/components/FileUploader'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
export default {
  components: {
      LyFileUploader
  },
  name: 'ArticleForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      accept: ".txt",
      formLabelWidth: '100px',
      stores: [],
      form: {},
      fileUrl: '',
      flag: '',
      rules: {
        name: [
          { required: true, message: '请输入书名', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }

      that.form = Object.assign({}, that.control.form);

    },

    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      if(that.$refs.lyuploader.fileList && that.$refs.lyuploader.fileList[0]){
        that.form.fileUrl = that.$refs.lyuploader.fileList[0].url
      }

      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              ArticleApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.content = ''
              that.form.contentEn = ''
              that.form.fromType = 'article'
              ArticleApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
