<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="title" label="品类名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="24">
              <el-form-item prop="title" label="排序" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.orderNumber" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="enableFlag" label="是否启用" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.enableFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
import * as EnumApi from '@/api/enums'
import * as CategoryApi from '@/api/category'
const OSS = require('ali-oss');
export default {
  name: 'CategoryForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '100px',
      form: {},
      picUrl: '',
      flag: '',
      rules: {
        name: [
          { required: true, message: '请输入品类名称', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      CategoryApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
        this.picUrl = that.form.picUrl
      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              CategoryApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.content = ''
              that.form.contentEn = ''
              CategoryApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
