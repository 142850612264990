<template>
  <div style="width: 100%;backdrop-filter: blur(120px);
    background: rgba(0, 0, 0, 0);">
    <div class="header">
        <img src="@/assets/logo1.png" class="header-logo"/>
        <ul class="main-nav main-nav">
          <li><a class="nav-item" @click="goPage('/')">首页</a></li>
          <li><a class="nav-item" @click="goPage('/news')">资讯</a></li>
          <!-- <li><a class="nav-item" @click="goPage('/contact-us')">联系我们</a></li> -->
          <li style="margin-right: 1rem;"><a class="nav-item" target="_blank" href="https://qcnx120ai68z.feishu.cn/share/base/form/shrcnPhSdUoXlXaVCGyZ9zJAD4d">合作</a></li>
          <!-- <li><a @click="goPage('/about-us')">关于我们</a></li> -->
          <li v-if="!isAuth"><el-button style="font-size: 1rem;border-radius: 6px !important;" type="primary" size="mini" @click="setShowLoginDialogFlag(true)">登录</el-button></li>
        </ul>
      <LoginDialog />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import LoginDialog from '@/components/LoginDialog'
export default {
  name: 'MainHeader',
  components: {
    LoginDialog,
  },
  data() {
    return {
      activeIndex: 1
    }
  },
  computed: {
  	...mapState(['isAutnenticated']),
    isAuth(){
      console.log('is auth', !!localStorage.lyToken);
      return !!localStorage.lyToken;
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapMutations(['setShowLoginDialogFlag']),
    goPage(path) {
      console.log(path)
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
  // PC
@media screen and (min-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 0;
    max-width: 1024px;
    min-width: 1024px;
    // width: 100%;
    margin: 0 auto;
    // backdrop-filter: blur(120px);
    // background: rgba(0, 0, 0, 0);
    &-logo {
      width: 15rem;
    }
  }
  .main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;

    overflow: hidden;
    list-style: none;
    max-width: 40rem;
    .nav-item {
      padding: 10px 20px;
      // backdrop-filter: blur(10px);
      // background: rgba(0, 0, 0, 0.1);
    }
    li > a {
      font-size: 1rem;
      color: #fff;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }
}
// mobile
@media screen and (max-width: 767px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    backdrop-filter: blur(120px);
    background: rgba(0, 0, 0, 0);
    ul {
      margin-left: 0;
      padding-left: 0;
    }
    width: 100%;
    margin: 0 auto;
    &-logo {
      width: 8rem;
    }
  }
  .main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    overflow: hidden;
    list-style: none;
     width: auto;
    .nav-item {
      padding: 10px 5px;
    }
    li > a {
      font-size: 1rem;
      color: #fff;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
