<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="title" label="服务标题" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
<!--
          <el-row>
            <el-col :span="6">
              <el-form-item prop="serviceType" label="类型" :label-width="formLabelWidth">
                  <el-select
                    :disabled="control.disabled"
                    v-model="form.serviceType"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择">
                  <el-option
                    v-for="item in enums.ServiceTypeEnum"
                    :key="item.name"
                    :label="item.desc"
                    :value="item.name"
                  />
                </el-select>
             </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="6">
              <el-form-item prop="serviceState" label="服务状态" :label-width="formLabelWidth">
                  <el-select
                    :disabled="control.disabled"
                    v-model="form.serviceState"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择">
                  <el-option
                    v-for="item in enums.ServiceStateEnum"
                    :key="item.name"
                    :label="item.desc"
                    :value="item.name"
                  />
                </el-select>
             </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item prop="departmentId" label="服务部门" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.departmentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item prop="accountMainId" label="服务人员" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.accountMainId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in accounts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

         <!-- <el-row>
            <el-col :span="6">
              <el-form-item label="选项1" prop="option1" v-if="selectedOptions.includes('选项1')">
                  <el-input
                    v-model="queryParams.operatorName"
                    placeholder="请输入选项1"
                    clearable
                    @keyup.enter.native="handleQuery"
                  />
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-row>
            <el-col :span="6">
              <el-form-item prop="accountAssistantId" label="协助人员" :label-width="formLabelWidth">
                <el-dropdown :hide-on-click="false" @command="handleCommand">
                  <el-button class="el-dropdown-link">
                    请选择<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item  v-for="option in accounts" :key="option.id" :disabled="option.disabled" :command="option.id">
                      <label>
                        <input type="checkbox" v-model="selectedOptions" :value="option.id">
                        {{ option.name }}
                      </label>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" :label-width="formLabelWidth">
                {{ this.accounts.filter( option => this.selectedOptions.includes(option.id) ).map(item=>item.name).join(',')}}
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>

         </el-row>


          <el-row>
            <el-col :span="6">
              <el-form-item prop="coin" label="消耗金币" :label-width="formLabelWidth">
                <el-input type="number" min="0" :disabled="control.disabled" v-model="form.coin" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label-width="5px">
                服务币
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button v-if="form.coinFlag" type="danger" round disabled>已扣除</el-button>
              <el-button v-else type="danger" @click="chargeOffService" round>扣除服务币</el-button>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="coin" label="服务时间" :label-width="formLabelWidth">
                <el-date-picker
                  :disabled="control.disabled"
                  v-model="serviceTime"
                  type="datetimerange"
                  range-separator=" - "
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="coin" label="提交时间" :label-width="formLabelWidth">
                <div>{{form.createTime}}</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="coin" label="商户信息" :label-width="formLabelWidth">
                <div>公司名称：{{form.store?form.store.organizationName:''}}</div>
                <div>店铺名称：{{form.store?form.store.storeName:''}}</div>
                <div>入驻时间：{{form.store?form.store.checkinTime:''}}</div>
                <div>剩余服务币：{{form.store?form.store.balance:0}}服务币</div>

                <div>服务提交次数：{{form.store?form.store.balance:0}}</div>
                <div>服务完成次数：{{form.store?form.store.balance:0}}</div>
                <div>待验收项目：{{form.store?form.store.balance:0}}</div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="coin" label="操作人" :label-width="formLabelWidth">
                <div>姓名：{{form.creator?form.creator.name:''}}</div>
                <div>职务：{{form.creator?form.creator.position:''}}</div>
                <div>手机号：{{form.creator?form.creator.phone:''}}</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input :rows="20" type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <!-- <el-form-item prop="enableFlag" label="是否启用" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.enableFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item> -->

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import  { formatDate } from '@/utils'
import  * as AliossApi from '@/api/alioss'
import * as EnumApi from '@/api/enums'
const OSS = require('ali-oss');
import Quill from 'quill'
import * as OrganizationApi from '@/api/organization'
import * as ServiceApi from '@/api/service'

export default {
  name: 'ServiceForm',
  props: {
    departments: [],
    accounts: [],
    enums: [],
    parents: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      selectedOptions:[],
      selectedAccount: '',
      editorOption: {
        placeholder: '请填写简介',
        modules:{
　　　　　　toolbar: [
              // [{ 'size': ['small' ,'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              ['blockquote'],
              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              // [{ 'font': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['link', 'image']
            ],
        },
        // theme:'snow'
      },
      formLabelWidth: '80px',
      form: {orderNumber: 0},
      serviceTime: [],
      rules: {
        name: [
          { required: true, message: '请输入服务标题', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // editor() {
    //     return this.$refs.myQuillEditor.quill;
    // },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      ServiceApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
        // this.addImageHandler();
          that.serviceTime.push(new Date(that.form.startTime))
          that.serviceTime.push(new Date(that.form.stopTime))
          // assistant
          if(that.form.accountAssistantId){
            that.selectedOptions = that.form.accountAssistantId.split(',')
          }
      })
    },
    //下拉菜单
    handleCommand(command) {
      // 根据选择的结果，更新选中的选项
      if (this.selectedOptions.includes(command)) {
        this.selectedOptions = this.selectedOptions.filter(
          option => option !== command
        );
      } else {
        this.selectedOptions.push(command);
      }
      // this.accounts.forEach((item, index) => {
      //   if(this.selectedOptions.includes(item.id)){
      //     item.disabled = true
      //   }else{
      //     item.disabled = false
      //   }
      // })
      // this.selectedOptions.map(item => { return item.name }).join(',')
      console.log("下拉菜单 >> ", this.accounts.filter(
          option => this.selectedOptions.includes(option.id)
        ).map(item=>item.name).join(','))
    },
    addImageHandler(){
      this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.updatePicture)
    },
    async chargeOffService() {

      let that = this;
      that.$confirm('', '确定扣除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        OrganizationApi.chargeOffService(this.form).then(res => {
          this.control.show = false;
          this.$parent.handlePage();
        })
      }).catch((e) => {console.log('error', e)});
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          if(that.serviceTime && that.serviceTime.length == 2){
            that.form.startTime = formatDate(that.serviceTime[0])
            that.form.stopTime = formatDate(that.serviceTime[1])
          }
          that.form.accountAssistantId = this.selectedOptions.join(',')
          switch(action){
            case that.$permits.UPDATE:
              ServiceApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:

              ServiceApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    updatePicture() {
      this.flag = 'logo';
      this.$el.querySelector('.'+this.flag).click()
    },
    async handleFile(e) {
      var vm = this;
      console.log('e', e)
      const $target = e.target || e.srcElement
      var file = $target.files[0]

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }

      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      // OSS.urlib是sdk内部封装的发送请求的逻辑，开发者完全可以使用任何发请求的库向`sts-server`发送请求
      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
        let picUrl = result.res.requestUrls[0];
        console.log('picUrl', picUrl);
        this.form.picUrl = picUrl.split("?")[0];
        console.log('this.form.picUrl', this.form.picUrl);
        let url = this.form.picUrl;
        if (url != null && url.length > 0) {
          var value = url
          vm.addImgRange = vm.$refs.myQuillEditor.quill.getSelection()
          console.log('vm.addImgRange', vm.addImgRange);
          value = value.indexOf('http') != -1 ? value : 'http:' + value
          console.log('value', value);
          vm.$refs.myQuillEditor.quill.insertEmbed(vm.addImgRange != null?vm.addImgRange.index:0, 'image', value, Quill.sources.USER)
          console.log('content', this.form.content);
        } else {
          vm.$message.warning("图片增加失败")
        }
      }).catch(function(err) {})
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    onEditorReady(editor) { // 准备编辑器
    },
    onEditorBlur(){}, // 失去焦点事件
    onEditorFocus(){}, // 获得焦点事件
    onEditorChange(){
      console.log(this.form);
    }, // 内容改变事件
  }
}
</script>

<style>
</style>
