var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{ref:"dialog",staticClass:"custom-dialog new-changpian-dialog",attrs:{"visible":_vm.showBreakdownDialogFlag,"append-to-body":true,"modal-append-to-body":false,"close-on-press-escape":true,"withHeader":false,"destroy-on-close":true,"show-close":true,"close-on-click-modal":true,"wrapperClosable":true,"width":_vm.drawerWidth,"top":_vm.dialogTop},on:{"update:visible":function($event){_vm.showBreakdownDialogFlag=$event},"close":_vm.close}},[_c('el-form',{attrs:{"label-position":"top"}},[_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('h3',{staticStyle:{"color":"rgba(0,0,0,0.9)"}},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('div',[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[(_vm.enums.ArticleGenTypeEnum && _vm.enums.ArticleGenTypeEnum.length>0)?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"请选择拆书类型","required":""}},[_c('el-radio-group',{model:{value:(_vm.formData.articleGenType),callback:function ($$v) {_vm.$set(_vm.formData, "articleGenType", $$v)},expression:"formData.articleGenType"}},_vm._l((_vm.enums.ArticleGenTypeEnum.filter((item) => {
                  if(item.code == 3){
                    item.desc = '长篇（暂不支持）'
                    item.disabled = true
                  }else{
                    item.disabled = false
                  }
                  return item.code > 1
                })),function(item,index){return _c('el-radio',{key:index,attrs:{"disabled":item.disabled,"border":false,"label":item.name}},[_vm._v(_vm._s(item.desc))])}),1)],1)],1):_vm._e()],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"picUrl","label":"上传作品内容","required":""}},[_c('ly-pic-uploader',{ref:"lyuploader",attrs:{"url":_vm.formData.picUrl}})],1)],1)],1),_c('el-row',{staticClass:"mgt-10",attrs:{"type":"flex","justify":"space-between"}},[(_vm.enums.BookBreakdowTypeEnum && _vm.enums.BookBreakdowTypeEnum.length>0)?_c('el-col',{attrs:{"span":_vm.showHeader?24:24}},[_c('el-form-item',{attrs:{"label":"请选择拆书输出结果（可多选）","required":""}},[_c('el-checkbox-group',{model:{value:(_vm.formData.breakdownType),callback:function ($$v) {_vm.$set(_vm.formData, "breakdownType", $$v)},expression:"formData.breakdownType"}},_vm._l((_vm.enums.BookBreakdowTypeEnum.filter((item) => {
                return true
              })),function(item,index){return _c('el-checkbox-button',{key:index,staticClass:"custom-checkbox",attrs:{"disabled":item.disabled,"border":false,"label":item.name}},[_vm._v(_vm._s(item.desc))])}),1)],1),_c('div',{staticStyle:{"line-height":"1rem !important","margin-top":"-.8rem","font-size":"12px","color":"rgba(0, 0, 0, .6)"}},[_vm._v("预估消耗能量:"),_c('span',{staticStyle:{"color":"#125FDC","padding":"0 .25rem"}},[_vm._v(_vm._s(314123))]),_vm._v("(仅预估，会有误差)")])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"type":"flex","justify":"center"}},[_c('el-button',{staticStyle:{"padding":"10px 55px"},attrs:{"type":"primary"},on:{"click":_vm.newBookBreakdown}},[_vm._v("开始拆书")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }