<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="公司名称" :label-width="formLabelWidth">
              <el-input v-model="queryForm.organizationName" maxlength="9" size="small" placeholder="请输入公司名称" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item prop="category" label="类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="queryForm.category"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ResourceCategoryEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
           </el-form-item>
          </el-col> -->
          <el-col :span="6" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <!-- <el-table-column prop="id" label="id" type="selection" fixed></el-table-column> -->

      <el-table-column
      fixed
        prop="merchant"
        label="商户信息"
        min-width="200px">
        <template slot-scope="scope">
          <div>公司名称：{{scope.row.organizationName}}</div>
          <div>店铺名称：{{scope.row.storeName}}</div>
          <div>入驻时间：{{scope.row.checkinTime}}</div>
          <div>剩余服务币：<span style="color: red;">{{scope.row.score + ' '}}</span>服务币</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="licenseUrl"
        label="营业执照">
        <template slot-scope="scope">
          <el-image
            style ="width:100px; height: 100px"
            :src="scope.row.licenseUrl"
            :preview-src-list="[scope.row.licenseUrl]"
          />
        </template>
      </el-table-column>
<!--
      <el-table-column
        prop="storeName"
        label="店铺名称"
        min-width="80px"/>

      <el-table-column
        prop="storeContactus"
        label="联系方式"
        min-width="80px"/>

      <el-table-column
        prop="storeAddress"
        label="店铺地址"
        min-width="80px"/>

      <el-table-column
        prop="createTime"
        label="创建时间"
        min-width="165px"/>

      <el-table-column
        prop="enableFlag"
        label="是否启用"
        min-width="80px">
        <template slot-scope="scope">
          <el-button @click="$permits.has(targetPermit,$permits.CREATE)?handleEnable(scope.row):''"  :type="scope.row.enableFlag?'success':'danger'" size="mini" round>
            {{scope.row.enableFlag?'启用':'停用'}}
          </el-button>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right" min-width="200px">
        <template slot="header">
          <span style="float: left">操作</span>
         <!-- <el-button
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)"
              @click="handleAction($permits.CREATE)">
            + 新建
          </el-button> -->
        </template>
        <template slot-scope="scope">
          <el-button v-show="$permits.has(targetPermit,$permits.VIEW)" @click="handleAction($permits.VIEW,scope.row)" size="mini" type="success" round>明细</el-button>
          <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>充值</el-button>
          <!-- <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button> -->
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"/>
        </div>
      </el-col>
    </el-row>
    <organization-form v-if="control.show" :control="control" :categories="categories"  :enums="enums"  :parents="parents" />
    <voucher-log-page v-if="control.voucher.show" :control="control" :statlogs="tableData"  :enums="enums"  :parents="parents" />
  </div>
</template>
<script>

import * as EnumApi from '@/api/enums'
import * as OrganizationApi from '@/api/organization'
import * as CategoryApi from '@/api/category'
import OrganizationForm from './form'
import VoucherLogPage from './log/page'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Organization',
  components: {
    OrganizationForm,
    VoucherLogPage
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
      categories: [],
    	// 权限key
      targetPermit: 'organization',
      tableHeight: 0,
      queryForm: {
        organizationName: '',
        category: '',
        current: '',
        size: 10000
      },
      control: {
        show: false,
        voucher: {
          show: false,
        },
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parents: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handleEnum()
    this.handlePage()
    this.doGetCategories()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "充值记录";
          that.control.disabled = true;
          that.control.voucher.show = true
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.show = true;
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        OrganizationApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        OrganizationApi.enabled({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage() {
      let that = this;
      // 获取表格数据
      OrganizationApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = buildTree(result.records, 0, 0)
        that.parents = treeToList(that.tableData)
        that.parents.unshift({id: 0, name: "无", depth: 0})
      })
    },
    doGetCategories() {
      let that = this;
      // 获取表格数据
      CategoryApi.page({enableFlag: true}).then(res => {
        let result = res.data.data;
        this.categories = result.records
        console.log('>>', this.categories);
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
