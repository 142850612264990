<template>
  <div class="layout" style="background: #242424;min-height:100vh;display: flex;flex-direction:column;height:100%;overflow-y:scroll;">
    <MainHeader />
    <div class="page-body" style="width:1000px;margin: 0 auto; flex: 1;">
      <h1 style="color: #ffffff;">关于我们</h1>
      <div class="about-us">
        <h3>公司愿景</h3>
        <p>北京智博灵犀科技有限公司致力于通过人工智能技术，为小说创作者提供先进的辅助工具，旨在激发创意，提升写作效率和质量，为文学创作领域注入新的活力。</p>
        <h3>业务范围</h3>
        <p>1.智能写作助手：基于AI的自动文本生成工具，帮助作者构思剧情、发展角色和生成文本。</p>
        <p>2.故事发展建议系统：通过分析已有文本提供剧情转折、角色发展的建议。</p>
        <p>3.文本修订与优化：利用自然语言处理技术，帮助作者优化语言表达和文风，提高文本的可读性和吸引力。</p>
        <p>4.版权保护与分析：使用区块链技术为作品提供版权注册服务，并基于大数据分析提供市场趋势预测。</p>
        <h3>核心技术</h3>
        <p>我们的技术团队由人工智能、计算机科学和文学领域的专家组成。技术核心包括深度学习、自然语言处理和机器学习，能够实现高度个性化和创新性的文本生成。</p>
        <h3>我们的客户</h3>
        <p>我们服务于全职和兼职小说作者，文学工作室，以及各大出版社，帮助他们在快节奏的市场中保持竞争力，创作出更多深受读者喜爱的作品。</p>
        <h3>未来展望</h3>
        <p>北京智博灵犀科技有限公司将继续领先科技创新，扩大服务范围，不仅限于小说创作，未来将覆盖剧本、游戏剧情和其他形式的创意写作。我们期待通过不断的技术革新，为更广泛的创作者提供支持，共同推动文化创新的进程。 </p>
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
export default {
  name: 'Contact',
  components: {
    MainHeader,
    MainFooter
  },
  data() {
    return {
      sliderBoxActive: 0,
      activeMenuItem: 1,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.about-us {
  color: #fff;
  line-height: 1.5;
}
</style>
