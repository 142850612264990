<template>
  <div class="" ref="llm">
    <div v-html="msg"></div>
    <el-button type="primary" @click="llmstream">sse</el-button>
    <el-button type="primary" @click="log">log</el-button>
    <!-- display: grid; -->
    <div v-if="false" class="table-container1">
      <!-- 动态添加单元格 -->
      <div class="table-cell1">
        <div class="content">内容1</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容2</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容3</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容4</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容11</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容12</div>
      </div>
     <div class="table-cell1">
        <div class="content">内容13</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容14</div>
      </div>
      <!-- 更多单元格 -->
    </div>

    <div class="" style="position: relative;">
      码哩写作1
      <div class="filter-blur">

      </div>
      码哩写作2
    </div>

    <!-- <VueDragResize :isActive="true" :w="200" :h="200" v-on:resizing="resize" v-on:dragging="resize"> -->
    <VueDragResize :isActive="true"  :isResizable="false">
        <h3>Hello World!</h3>
        <p>{{ top }} х {{ left }} </p>
        <p>{{ width }} х {{ height }}</p>
    </VueDragResize>

    <!-- video -->
    <!-- <video-player ref="videoPlayer" style=""
           :options="playerOptions"
           title="码哩写作"
           @play="onPlayerPlay($event)"
           @pause="onPlayerPause($event)"
           @ended="onPlayerEnded($event)"
           @loadeddata="onPlayerLoadeddata($event)"
           @waiting="onPlayerWaiting($event)"
           @playing="onPlayerPlaying($event)"
           @timeupdate="onPlayerTimeupdate($event)"
           @canplay="onPlayerCanplay($event)"
           @canplaythrough="onPlayerCanplaythrough($event)"
           @statechanged="playerStateChanged($event)"
           @ready="playerReadied">
    </video-player> -->
    <!-- video-end -->

    <div class="animation-container">
      <img src="" alt="Animation Frame" class="animation-frame">
    </div>

<div style="position: relative; width: 200px; height: 200px;">
  <frame-player ref="theFramePlayer"/>
</div>

  </div>
</template>
<script>
import FramePlayer from 'vue-frame-player'
import sse from '@/utils/llmstream'
import { create } from '@/api/statlog'
import VueDragResize from 'vue-drag-resize';
import { videoPlayer } from 'vue-video-player'

export default {
  components: {
    VueDragResize,FramePlayer
  },
  data() {
    return {
      msg: '',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      playerOptions: {
        // component options
        start: 0,
        playsinline: false,
        // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          // src: "https://image.uc.cn/s/uae/g/3o/broccoli/resource/202408/45060e5781c02920.mp4"
          // src: "https://maliwriter.com/static/homepage.mp4"
          src: "https://maliwriter.com/static/homepage.webm"
        }],
        // poster: "/static/images/author.jpg",
      },
    }
  },
  mounted() {
    // document.addEventListener("DOMContentLoaded", function() {
    //   var frames = [];
    //   for(let i=6000;i<6194;i++){
    //     frames.push(`https://maliwriter.com/static/png/${i}.png`)
    //   }
    //   console.log('frames', frames)
    //   var frameCount = frames.length;
    //   var currentFrame = 0;

    //   var imgElement = document.querySelector('.animation-frame');

    //   function updateFrame() {
    //     imgElement.src = frames[currentFrame];
    //     currentFrame = (currentFrame + 1) % frameCount;
    //   }

    //   // 每100毫秒更新一次帧（根据需要调整时间）
    //   setInterval(updateFrame, 100);
    // });
    const frames = [];
    for(let i=6000;i<6194;i++){
      frames.push(`https://maliwriter.com/static/png/${i}.png`)
    }
    const player = this.$refs.theFramePlayer;
      player
      .init( {
        imageMode: 'visible',
        playMode: 'loop',
        length: 194,
        // initialImages: ( i, length ) => require( `../assets/bg/${i + 1}.jpg` ),
        initialImages: frames,
        playStep: 1,
        playSpeed: 24,
        autoplay: true,
        preload: true,
      } );

      // player
      // .on('play', () => console.log('frame player played!'))
      // .on('pause', () => console.log('frame player paused!'))
      // .on('ended', () => console.log('frame player ended!'))
      // .on('update', (current) => console.log('frame player update frame ', current))
      // .play()
  },
  methods: {
    llmstream() {
      sse("1815703365829668865", {}, this.handleMsg).then((result)=>{
        console.log('result', result);
      })
    },
    handleMsg(msg){
      // console.log('msg', msg);
      this.msg = msg
    },
    log(){
      create({predicate: 'BAIDU_TUIGUANG', objectText: 'querystring'});
    },
    resize(newRect) {
        this.width = newRect.width;
        this.height = newRect.height;
        this.top = newRect.top;
        this.left = newRect.left;
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded(player) {
      // console.log('player pause!', player)
      player.play()
    },
    onPlayerLoadeddata(player) {
      // console.log('player pause!', player)
    },
    onPlayerWaiting(player) {
      // console.log('player pause!', player)
    },
    onPlayerPlaying(player) {
      // console.log('player pause!', player)
    },
    onPlayerTimeupdate(player) {
      // console.log('player pause!', player)
    },
    onPlayerCanplay(player) {
      // console.log('player pause!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player pause!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied(player) {
      console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
      player.play()
    }
  }
};
</script>
<style scoped>
/deep/ video {
  mix-blend-mode: screen;
  /* background: #000000; */
  background: transparent;
}
.frame-player {
    position: absolute;
    width: 768px;
    height: auto;
}
.filter-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  filter: blur(10px);
  color: #FFFFFF;
  z-index: -1;
}
/* 容器样式 */
.table-container1 {
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* 2行，每行高度相等 */
  grid-auto-flow: column; /* 允许项目填充更小的空格 */
  /* grid-auto-columns: repeat(1, 1fr); */
  gap: 8px; /* 单元格间隙 */
  /* width: auto; */
  overflow-y: hidden;
  overflow-x: auto;
  background: pink;
}

/* 单元格样式 */
.table-cell1 {
  display: flex;
  border: 1px solid #000; /* 边框样式 */
  padding: 8px; /* 单元格内边距 */
  box-sizing: border-box; /* 边框和内边距包含在宽度内 */
  width: 10rem;
}

/* 内容样式，用于调整内容以适应单元格宽度 */
.content {
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}


/* png iframe */
.animation-container {
  position: relative;
  width: 100%; /* 根据你的帧尺寸调整 */
  height: 100%; /* 根据你的帧尺寸调整 */
}

.animation-frame {
  width: 100%;
  height: auto;
  display: block;
}

/* 定义关键帧动画 */
@keyframes play-sequence {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 应用动画到图像 */
.animation-frame {
  animation: play-sequence 30ms steps(1) infinite;
}
/* png iframe end */
</style>
