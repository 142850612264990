<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="title" label="标题" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item prop="content" label="内容" :label-width="formLabelWidth">
                <quill-editor 
                    v-model="form.content" 
                    ref="myQuillEditor" 
                    :options="editorOption" 
                    @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)">
                </quill-editor>
                <!-- upload picture -->
                <div style="display: none !important;">
                  <input class="logo" :disabled="control.disabled" type="file" accept="image/*" @change="handleFile" style="display: none;">
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
import * as EnumApi from '@/api/enums'
import * as CultureApi from '@/api/culture'
const OSS = require('ali-oss');
import Quill from 'quill'
export default {
  name: 'CultureForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '100px',
      editorOption: {
        placeholder: '请填写简介',
        modules:{
　　　　　　toolbar: [
              // [{ 'size': ['small' ,'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              ['blockquote'],
              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              // [{ 'font': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['link', 'image']
            ],
        },
        // theme:'snow'
      },
      form: {},
      flag: '',
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    editor() {
        return this.$refs.myQuillEditor.quill;
    },
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      CultureApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
        this.addImageHandler();
      })
    },
    addImageHandler(){
      this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.updatePicture)
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              CultureApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.content = ''
              that.form.contentEn = ''
              CultureApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    updatePicture() {
      this.flag = 'logo';
      this.$el.querySelector('.'+this.flag).click()
    },
    async handleFile(e) {
      var vm = this;
      console.log('e', e)
      const $target = e.target || e.srcElement
      var file = $target.files[0]

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }
      
      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      // OSS.urlib是sdk内部封装的发送请求的逻辑，开发者完全可以使用任何发请求的库向`sts-server`发送请求
      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
        let picUrl = result.res.requestUrls[0];
        console.log('picUrl', picUrl);
        this.form.picUrl = picUrl.split("?")[0];
        console.log('this.form.picUrl', this.form.picUrl);
        let url = this.form.picUrl;
        if (url != null && url.length > 0) {
          var value = url
          vm.addImgRange = vm.$refs.myQuillEditor.quill.getSelection()
          console.log('vm.addImgRange', vm.addImgRange);
          value = value.indexOf('http') != -1 ? value : 'http:' + value
          console.log('value', value);
          vm.$refs.myQuillEditor.quill.insertEmbed(vm.addImgRange != null?vm.addImgRange.index:0, 'image', value, Quill.sources.USER)
          console.log('content', this.form.content);
        } else {
          vm.$message.warning("图片增加失败")
        }
      }).catch(function(err) {})
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    onEditorReady(editor) { // 准备编辑器
    },
    onEditorBlur(){}, // 失去焦点事件
    onEditorFocus(){}, // 获得焦点事件
    onEditorChange(){
      console.log(this.form);
    }, // 内容改变事件
  }
}
</script>

<style lang="scss" scoped>
.ql-container.ql-snow {
    border: 0px solid #ccc;
}
</style>

<style lang="scss">
.el-drawer.rtl{
    overflow: scroll;
}
.ql-toolbar{
    line-height: 30px;
}
</style>