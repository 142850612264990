<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form>
      <el-form-item label="标题" :label-width="formLabelWidth">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-input v-model="queryForm.title" maxlength="9" size="small" placeholder="请输入标题" />
          </el-col>
          <el-col :span="8">
            <el-button type="primary" size="small" @click="handlePage(1)">搜索</el-button>
            <el-button size="small" @click="reset()">重置</el-button>
          </el-col>
      </el-row>
      </el-form-item>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <el-table-column
        fixed
        prop="orderInfo"
        min-width="80px"
        label="用户信息">
        <template slot-scope="scope">
          <div>ID：{{scope.row.creator?scope.row.creator.username:''}}</div>
          <div>昵称：{{scope.row.creator?scope.row.creator.name:''}}</div>
          <img style="width: 40px;" :src="scope.row.creator?scope.row.creator.avatarId:''"/>
        </template>
      </el-table-column>

      <el-table-column
        fixed
        prop="orderInfo"
        label="订单信息"
        min-width="140px" >
        <template slot-scope="scope">
          <div>订单ID：{{scope.row.id}}</div>
          <div v-if="scope.row.orderState=='YES'">核销码：{{scope.row.chargeOffCode}}</div>
          <div>套餐：{{scope.row.setmeal?scope.row.setmeal.title:''}}</div>
          <div>店铺：{{scope.row.organization?scope.row.organization.storeName:''}}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="orderType"
        label="订单类型"
        min-width="80px">
        <template slot-scope="scope">
          {{scope.row.orderTypeLabel}}
        </template>
      </el-table-column>

      <el-table-column
        prop="payMoney"
        label="实付金额"
        min-width="80px"/>

      <el-table-column
        prop="payCoin"
        label="抵扣金币"
        min-width="80px"/>

      <el-table-column
        prop="orderType"
        label="支付状态"
        min-width="80px">
        <template slot-scope="scope">
          {{scope.row.payStateLabel}}
        </template>
      </el-table-column>

      <el-table-column
        prop="orderState"
        label="订单状态"
        min-width="80px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.orderState=='NO'" type="warning" size="mini" round> {{scope.row.orderStateLabel}} </el-button>
          <el-button v-else-if="scope.row.orderState=='REFUND'" type="danger" size="mini" round> {{scope.row.orderStateLabel}} </el-button>
          <el-button v-else-if="scope.row.orderState=='YES'" type="success" size="mini" round> {{scope.row.orderStateLabel}} </el-button>
          <el-button v-else type="info" size="mini" round> {{scope.row.orderStateLabel}} </el-button>
        </template>
      </el-table-column>
<!--
      <el-table-column
        prop="publishFlag"
        label="发布状态"
        min-width="80px">
        <template slot-scope="scope">
          <el-button @click="$permits.has(targetPermit,$permits.CREATE)?handlePublish(scope.row):''"  :type="scope.row.publishFlag?'success':'info'" size="mini" round>
            {{scope.row.publishFlag?'已发布':'未发布'}}
          </el-button>
        </template>
      </el-table-column> -->

      <el-table-column
        prop="chargeOffTime"
        label="核销时间"
        min-width="90px"/>

      <el-table-column
        prop="createTime"
        label="下单时间"
        min-width="90px"/>

      <el-table-column fixed="right" min-width="100px">
        <template slot="header">
          <span style="float: left">操作</span>
          <!-- <el-button
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)"
              @click="handleAction($permits.CREATE)">
            + 新建
          </el-button> -->
        </template>
        <template slot-scope="scope">
          <!-- <el-button v-if="scope.row.orderState=='YES'" @click="handleTask($permits.CREATE,scope.row)" size="mini" type="success" round>补发任务</el-button> -->
          <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>延期</el-button>
          <!-- <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button> -->
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>
    <OrderForm v-if="control.show" :control="control" :enums="enums" />
    <TaskForm v-if="controlTask.show" :control="controlTask" :stores="stores" :enums="enums" />
  </div>
</template>
<script>
import * as TaskApi from '@/api/task'
import * as EnumApi from '@/api/enums'
import * as OrderApi from '@/api/order'
import OrderForm from './form'
import TaskForm from './task'
export default {
  name: 'Order',
  components: {
    OrderForm,
    TaskForm
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
    	targetPermit: 'order',
      tableHeight: 0,
      queryForm: {
        name: '',
        current: '',
        size: ''
      },
      controlTask : {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
    this.handleEnum()
    this.doGetStores()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleTask(action,item) {
      let that = this;
      console.log("handleTask action >> " + action)
      that.controlTask.show = true;
      that.controlTask.disabled = false;
      that.controlTask.action = action;
      that.controlTask.form = item;
      that.control.title = "补发任务";
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        OrderApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    doGetStores() {
      TaskApi.page({enableFlag: true}).then(res => {
        let result = res.data.data;
        this.stores = result.records
      })
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // 获取表格数据
      OrderApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
