import request from '@/utils/request'

const url = '/auth-service/tasks'

export function findByPage4Setmeal(params) {
  return request({
    url: `${url}/query_page_setmeal`,
    method: 'get',
    params: params
  })
}

export function addToSetmeal(params) {
  return request({
    url: `${url}/add_to_setmeal`,
    method: 'post',
    params: params
  })
}

export function updateToSetmeal(params) {
  return request({
    url: `${url}/setmeal/${params.id}`,
    method: 'put',
    params: params
  })
}

export function removeToSetmeal(id) {
  return request({
    url: `${url}/setmeal/${id}`,
    method: 'delete'
  })
}

export function page(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

export function view(id) {
  return request({
    url: `${url}/${id}`,
    method: 'get',
    params: {}
  })
}

export function create(params) {
  return request({
    url: `${url}`,
    method: 'post',
    params: params
  })
}

export function update(params) {
  return request({
    url: `${url}/${params.id}`,
    method: 'put',
    params: params
  })
}

export function remove(id) {
  return request({
    url: `${url}/${id}`,
    method: 'delete'
  })
}

export function enabled(params) {
  return request({
    url: `${url}/enabled/${params.id}/${params.enabled}`,
    method: 'post'
  })
}

export function published(params) {
  return request({
    url: `${url}/published/${params.id}/${params.enabled}`,
    method: 'post'
  })
}
