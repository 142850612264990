<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">


          <el-row>
            <el-col :span="8">
              <el-form-item prop="" label="" :label-width="formLabelWidth">

              </el-form-item>
              <el-form-item prop="" label="订单信息" :label-width="formLabelWidth">
                <div>订单ID：{{form.id}}</div>
                <div>下单时间：{{form.createTime}}</div>
                <div>支付时间：{{form.payTime}}</div>
              </el-form-item>

              <el-form-item prop="coin" label="商户信息" :label-width="formLabelWidth">
                <div>公司名称：{{form.organization?form.organization.organizationName:''}}</div>
                <div>店铺名称：{{form.organization?form.organization.storeName:''}}</div>
                <div>入驻时间：{{form.organization?form.organization.checkinTime:''}}</div>
              </el-form-item>
            </el-col>

            <el-col :span="8">

              <el-form-item prop="" label="" :label-width="formLabelWidth">

              </el-form-item>
              <el-form-item prop="coin" label="用户信息" :label-width="formLabelWidth">
                <div>姓名：{{form.creator?form.creator.name:''}}</div>
                <div>用户ID：{{form.creator?form.creator.id:''}}</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item prop="expireStopTime" label="过期时间" :label-width="formLabelWidth">
                <el-date-picker
                  v-model="form.expireStopTime"
                  type="datetime"
                  :default-time="form.expireStopTime"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import * as EnumApi from '@/api/enums'
import * as OrderApi from '@/api/order'
const OSS = require('ali-oss');
export default {
  components: {
  },
  name: 'ArticleForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {},
      picUrl: '',
      flag: '',
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      that.form = Object.assign({}, that.control.form);
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)

      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              OrderApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.content = ''
              that.form.contentEn = ''
              that.form.fromType = 'article'
              OrderApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
