<template>
<el-drawer
    :title="'知识库：'+controlChapter.form.title"
    :enums="enums"
    :with-header="true"
    :visible.sync="controlChapter.show"
    direction="rtl"
    :append-to-body="true"
    :modal-append-to-body="false"
    size="90%">
  <div>
    <div style="display: flex;">
      <div style="flex: 2;margin-right: 20px;">
        <!-- 数据表格 -->
        <el-table
          stripe
          :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
          :data="tableData"
          height="100vh"
          ref="tableContainer"
          class="table-container">
          <!-- <el-table-column prop="id" label="id" type="selection" fixed></el-table-column> -->
          <el-table-column
            fixed
            prop="title"
            label="文档名称"
            levelKey="depth"
            treeKey="id"
            parentKey="parentId"
            childKey="children"
            min-width="120px">
            </el-table-column>

          <el-table-column
            prop="trunkNumber"
            label="分块数">
            <template slot-scope="scope">
              <el-button @click="handleTrunkPage(scope.row)" size="mini" type="warning" round>{{scope.row.trunkNumber}}</el-button></template>
          </el-table-column>

          <el-table-column fixed="right" min-width="80px">
            <template slot="header">
              <span style="float: left">操作</span>
            </template>
            <template slot-scope="scope">
              <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleSplit('split',scope.row)" size="mini" type="warning" round>解析</el-button>
              <!-- <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button> -->
              <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>移除</el-button>
            </template>
          </el-table-column>
        </el-table>
    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>
      </div>
    </div>

    <chapter-form v-if="control.show" :control="control"  :enums="enums" />

    <!-- grant role -->
    <el-dialog
      :title="'解析内容'"
      :visible.sync="roleVisible"
      :append-to-body="true"
      :modal-append-to-body="false"
       width="60%">
      <div>
        <div class="trunk" v-html="replaceLineBreaks(item.content)" v-for="(item,index) in trunks" :key="item.docId">
          <!-- {{item.content}} -->
        </div>
      </div>
    </el-dialog>

  </div>
</el-drawer>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as KnowledgeBaseApi from '@/api/knowledgebase'
import ChapterForm from './form'
export default {
  name: 'Chapter',
  components: {
    ChapterForm
  },
  props: {
    controlChapter: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
      stores: [],
      enums: [],
    	// 权限key
      targetPermit: 'chapter',
      tableHeight: 0,
      roleVisible: false,
      queryForm: {
        title: '',
        publishFlag: true,
        current: 1,
        size: 10
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      trunks: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handleEnum()
    this.handlePage()
    // this.handleTrunkPage()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    replaceLineBreaks(str) {
      // return str
      return str?str.replace(/\n/g, '<br>'):"";
    },
    handleSplit(action, item) {
      let that = this;
      that.$confirm('', '确定解析吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        console.log(item, that.controlChapter.form);
        KnowledgeBaseApi.splitDoc({"articleId":item.id, "knowledgebaseId": that.controlChapter.form.id}).then(res => {
          if(res.data.code === 0){
            this.$message({
              message: '解析成功',
              type: 'success'
            });
          }
        })
      }).catch((e) => {console.log('error', e)});
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        KnowledgeBaseApi.removeDoc({"articleId":item.id, "knowledgebaseId": that.controlChapter.form.id}).then(res => {
          if(res.data.code === 0){
            this.$message({
              message: '移除成功',
              type: 'success'
            });
            that.handlePage();
          }
        })
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ArticleApi.published({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handlePublish(item) {
      let that = this;
      let tip = !item.publishFlag?'发布':'取消发布'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ArticleApi.published({id: item.id, enabled: !item.publishFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      console.log('current', current)
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      that.queryForm.knowledgebaseId = that.controlChapter.form.id
      that.queryForm.fromType = 'knowledgebase'
      console.log('form', that.queryForm, that.controlChapter.form)
      // 获取表格数据
      ArticleApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleTrunkPage(item){
      KnowledgeBaseApi.trunkPage({articleKnowledgebaseId: item.articleKnowledgebaseId}).then(res => {
        let result = res.data.data;
        this.trunks = result.records
        this.roleVisible = true
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
.trunk{
  border: 4px solid #F3F5FA;
  padding: 5px;
  margin: 5px 0;

}
.trunk:hover{
  background: #F3F5FA;
}
</style>
