import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source"
import { Message, Loading } from 'element-ui'
import store from '../store'
const sse = function(workflowId, params, msgCallback) {
  return new Promise((resolve, reject) => {
    console.log('sse :: workflowId=', workflowId);
    let cb = msgCallback || function(msg){

    }
    let msg = ''
    let url = process.env.VUE_APP_BASE_API_URL+`auth-service/im_messages/stream_workflow/${workflowId}`
    params.platformCategory = 'WRITER_WEB'
    const controller = new AbortController();
    fetchEventSource(url,
        {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.lyToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
            onmessage: (event) => {
              // console.error('event:', event);
                let single = event.data.replace(/^"|"$/g, '').replace(/data:/g, '')
                if (single.startsWith('ERROR1')) {
                    console.error('Received ERROR:', single);
                    message(single.replace('ERROR1:', ''))
                    controller.abort();
                }else if (single.startsWith('ERROR2')) {
                    console.error('Received ERROR:', single);
                    // message(single.replace('ERROR2:', ''))
                    store.dispatch('setDialogShowCostomContactFlag', true)
                    controller.abort();
                }else if (single.startsWith('ERROR3')) {
                    console.error('Received ERROR:', single);
                    message(single.replace('ERROR3:', ''))
                    controller.abort();
                }else if (single.startsWith('processInstanceId')) {
                    console.warn('Received processInstanceId:', single);
                    localStorage.setItem("processInstanceId", single.split(':')[1])
                }else if (single.startsWith('ERROR4')) {
                  console.error('Received ERROR:', single);
                  message(single.replace('ERROR4:', ''))
                  controller.abort();
                }else if (single.startsWith('ERROR:LLM1')) {
                  message(single.replace('ERROR:LLM1:', ''))
                  // throw new Error(single)
                  controller.abort();
                }else if (single.startsWith('AGENT_END')) {
                    console.log('AGENT_END:', single);
                    // throw new Error('AGENT_END')
                    resolve(msg)
                    controller.abort();
                }else if (single.startsWith('AGENT_END_HUMAN')) {
                    console.log('AGENT_END_HUMAN:', single);
                    controller.abort();
                }else if (single.startsWith('queueName')) {
                    console.warn('queueName:', single);
                    localStorage.setItem("queueName", single.split(':')[1])
                }else {
                  // console.info("onmessage", event);
                  if(single != ''){
                    msg += single;
                    if(workflowId == '1806516321777786881'){// 标题
                      msg = msg?msg.replace(/\“/g, '“').replace(/\”/g, '”').replace(/\\"/g, '"').replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
                    }else{
                      msg = msg?msg.replace(/\“/g, '“').replace(/\”/g, '”').replace(/\\"/g, '"').replace(/\\n/g, '\n'):"";
                    }
                    msgCallback(msg)
                  }
                }
            },
            onerror: (error) => {
                console.info("onerror", error);
                controller.abort();
            },
            onopen: (response) => {
                console.info("onopen", response);
            },
            onclose: (response) => {
                console.info("onclose", response);
                controller.abort();
            },
            openWhenHidden: true,
            signal: controller.signal
        }
    );
  })
}
const message = function(msg){
  Message({
    message: msg,
    type: 'error',
    duration: 5 * 1000
  })
}
export default sse
