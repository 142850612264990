<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="true"
        :visible.sync="control.show"
        direction="rtl"
        :append-to-body="true"
        :modal="false"
        size="60%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">

         <el-row>
           <el-col :span="8">
             <el-form-item prop="weekDate" label="日期" :label-width="formLabelWidth">
               <el-date-picker
                 :disabled="control.disabled"
                 v-model="weekDate"
                 type="datetimerange"
                 range-separator=" - "
                 start-placeholder="开始日期"
                 end-placeholder="结束日期">
               </el-date-picker>
             </el-form-item>
           </el-col>
         </el-row>

          <el-row>
            <el-col :span="4">
              <el-form-item prop="stockNumber" label="库存" :label-width="formLabelWidth">
                <el-input style="width: 100%;" :disabled="control.disabled" v-model="form.stockNumber" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import  { formatDate } from '@/utils'
import * as EnumApi from '@/api/enums'
import * as SetmealStockApi from '@/api/setmealstock'
import moment from "moment"
export default {
  name: 'TaskForm',
  components: {

  },
  props: {
    enums: '',
    stores: '',
    parents: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      weekDate: [],
      form: {stockNumber: 0, startDate: '', stopDate: ''},
      rules: {
        stockNumber: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]
      },
    }
  },
  computed: {

  },
  mounted() {
    this.init();
  },
  methods: {
    init(){

      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      that.form = Object.assign({}, that.control.form);
      that.weekDate[0] = that.form.startDate
      that.weekDate[1] = that.form.stopDate
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          if(that.weekDate && that.weekDate.length == 2){
            that.form.startDate = formatDate(that.weekDate[0])
            that.form.stopDate = formatDate(that.weekDate[1])
          }
          that.form.setmealId = that.control.form.setmealId
          that.form.storeId = that.control.form.storeId
          switch(action){
            case that.$permits.UPDATE:
              SetmealStockApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              SetmealStockApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>
