<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-form-item label="基本信息" :label-width="formLabelWidth">
            </el-form-item>
            <el-col :span="12">
              <el-form-item label="账号" prop="username" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.username" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="authName" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.authName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别" prop="authGenderLabel" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.authGenderLabel" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="手机号" prop="authPhone" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.authPhone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信号" prop="authWeixin" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.authWeixin" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="auditState" label="审核状态" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.auditState"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.AuditStateEnum.filter(i=>{return i.code>=0;})"
                  :key="index"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="auditReason" label="审核原因" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.auditReason" autocomplete="off"></el-input>
          </el-form-item>

          <el-row>
            <el-col :span="8">
              <el-form-item label="申请条件" :label-width="formLabelWidth">
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div>下单{{form.orderNumber?form.orderNumber:0}}次</div>
                <div>站内种草{{form.inArticleNumber?form.inArticleNumber:0}}次</div>
                <div>站外评价{{form.outArticleNumber?form.outArticleNumber:0}}次</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="其他平台" :label-width="formLabelWidth">
              </el-form-item>
              <el-form-item label="抖音" :label-width="formLabelWidth">
                <el-image
                  :key="index"
                  v-for="(item,index) in (form.douyinAlbum?form.douyinAlbum.split(','):[])"
                  style ="width:100px;"
                  :src="item"
                  :preview-src-list="(form.douyinAlbum?form.douyinAlbum.split(','):[])"
                />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="小红书" :label-width="formLabelWidth">
                <el-image
                  :key="index"
                  v-for="(item,index) in (form.hongshuAlbum?form.hongshuAlbum.split(','):[])"
                  style ="width:100px;"
                  :src="item"
                  :preview-src-list="(form.hongshuAlbum?form.hongshuAlbum.split(','):[])"
                />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="大众点评" :label-width="formLabelWidth">
                <el-image
                  :key="index"
                  v-for="(item,index) in (form.dianpingAlbum?form.dianpingAlbum.split(','):[])"
                  style ="width:100px;"
                  :src="item"
                  :preview-src-list="(form.dianpingAlbum?form.dianpingAlbum.split(','):[])"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
            <el-button v-if="!control.disabled" @click="resetForm('form')" size="mini">重置</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as EnumApi from '@/api/enums'
import * as AccountApi from '@/api/account'
import * as Validator from '@/utils/validate'

export default {
  name: 'AccountForm',
  props: {
    enums: '',
    departments: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '6rem',
      form: {
        adminFlag: true
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: Validator.testPassword, trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      AccountApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        if(!valid){
        return false;
      }
      });
      switch(action){
        case that.$permits.UPDATE:
          AccountApi.update(that.form).then(res => {
            that.control.show = false;
            that.$parent.handlePage();
          })
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          const params = Object.assign({}, that.form)
          let md5 = require('md5')
          params.password = md5(that.form.password)
          AccountApi.create(that.form).then(res => {
            that.control.show = false;
            that.$parent.handlePage();
          })
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
  }
}
</script>

<style>
</style>
