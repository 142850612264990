<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="ID" :label-width="formLabelWidth">
              <el-input v-model="queryForm.username" maxlength="9" size="small" placeholder="请输入ID" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="昵称" :label-width="formLabelWidth">
              <el-input v-model="queryForm.name" maxlength="9" size="small" placeholder="请输入昵称" />
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage(1)">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <el-table-column
        fixed
        prop="username"
        label="ID"
        min-width="80px" />
      <el-table-column
        fixed
        prop="avatarId"
        label="头像">
        <template slot-scope="scope">
          <img style="width: 60px;" :src="scope.row.avatarId"/>
        </template>
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="昵称"
        min-width="80px" />

      <el-table-column
        prop="phone"
        label="手机号"
        :show-overflow-tooltip="true" />

      <el-table-column
        prop="enableFlag"
        label="状态"
        min-width="100px">
        <template slot-scope="scope">
          <el-button @click="handleStop(scope.row)" v-if="scope.row.enableFlag" size="mini" type="success" round>启用</el-button>
          <el-button @click="handleStart(scope.row)" v-else size="mini" type="danger" round>禁用</el-button>
        </template>
      </el-table-column>

      <el-table-column
        prop="lastLoginTime"
        label="最近登录"
        min-width="170px"/>

      <el-table-column
        prop="createTime"
        label="注册时间"
        min-width="165px"/>
<!--
      <el-table-column fixed="right" min-width="200px">
        <template slot="header">
          <span style="float: left">操作</span>
          <el-button
              style="float: right;"
              type="primary" size="mini"
              v-if="$permits.has(targetPermit,$permits.CREATE)"
              @click="handleAction($permits.CREATE)">
            + 新建
          </el-button>
        </template>
        <template slot-scope="scope">
          <el-button v-show="$permits.has(targetPermit,$permits.VIEW)" @click="handleAction($permits.VIEW,scope.row)" size="mini" type="success" round>详情</el-button>
          <el-button v-if="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button>
          <el-button v-if="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button>
        </template>
      </el-table-column> -->

    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>
    <AccountForm v-if="control.show" :control="control" :enums="enums" :departments="departments" />

    <!-- grant role -->
    <!-- <el-dialog
      :title="'分配角色-'+currentAccount.name||''"
      :visible.sync="roleVisible" width="60%">
      <el-form ref="form" label-width="100px">
        <el-checkbox-group v-model="roleIds">
          <el-checkbox v-for="(role,index) in roleList" :label="role.id" :key="index">
            {{role.remark}}
          </el-checkbox>
        </el-checkbox-group>
      </el-form>
      <br>
      <br>
      <el-row>
        <el-col span="8" offset="8" style="text-align: center;">
          <el-button @click="handleRoleSubmit()" size="mini" type="primary">确 定</el-button>
        </el-col>
      </el-row>
    </el-dialog> -->
  </div>
</template>
<script>
import * as DepartmentApi from '@/api/department'
import * as EnumApi from '@/api/enums'
import * as AccountApi from '@/api/account'
import * as RoleApi from '@/api/role'
import AccountForm from './form'
export default {
  name: 'Account',
  components: {
    AccountForm
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
    	// 权限key
      targetPermit: 'account',
      tableHeight: 0,
      roleVisible: false,
      currentAccount: {},
      roleList: [],
      roleIds: [],
      queryForm: {
        name: '',
        adminFlag: false,
        merchantFlag: true,
        current: '',
        size: ''
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      departments: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
    this.handleEnum()
    this.handleDepartment()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        AccountApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    async handleRole(item) {
      let that = this;
      that.roleVisible = true;
      that.currentAccount = item;
      let res = await RoleApi.page({current: 1, size: 500, enableFlag: true})
      that.roleList = res.data.data.records
      let roleRes = await RoleApi.listByAccountId({accountId: that.currentAccount.id})
      that.roleIds = roleRes.data.data.map(item=>{return item.id;})
    },
    handleRoleSubmit() {
      let that = this;
      const params = {
        accountId: that.currentAccount.id,
        roleIds: that.roleIds
      }
      RoleApi.grant(params).then(res => {
        that.roleVisible = false;
      });
    },
    handleStop(item) {
      let that = this;
      that.$confirm('', '确定停用吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        AccountApi.enabled({id: item.id, enabled: false}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleStart(item) {
      let that = this;
      that.$confirm('', '确定启用吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        AccountApi.enabled({id: item.id, enabled: true}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handleDepartment() {
      let that = this;
      // 获取表格数据
      DepartmentApi.page({enableFlag: true}).then(res => {
        let result = res.data.data;
        that.departments = result.records||[]
      })
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // 获取表格数据
      AccountApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records||[]
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
