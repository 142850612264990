var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"llm"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.msg)}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.llmstream}},[_vm._v("sse")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.log}},[_vm._v("log")]),(false)?_c('div',{staticClass:"table-container1"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),_vm._m(8),_c('VueDragResize',{attrs:{"isActive":true,"isResizable":false}},[_c('h3',[_vm._v("Hello World!")]),_c('p',[_vm._v(_vm._s(_vm.top)+" х "+_vm._s(_vm.left)+" ")]),_c('p',[_vm._v(_vm._s(_vm.width)+" х "+_vm._s(_vm.height))])]),_vm._m(9),_c('div',{staticStyle:{"position":"relative","width":"200px","height":"200px"}},[_c('frame-player',{ref:"theFramePlayer"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容11")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容12")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容13")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容14")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_vm._v("\n      码哩写作1\n      "),_c('div',{staticClass:"filter-blur"}),_vm._v("\n      码哩写作2\n    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animation-container"},[_c('img',{staticClass:"animation-frame",attrs:{"src":"","alt":"Animation Frame"}})])
}]

export { render, staticRenderFns }