<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item prop="name" label="角色名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="activeFlag" label="是否激活" :label-width="formLabelWidth">
                <el-switch
                  :disabled="control.disabled" 
                  v-model="form.activeFlag"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              <el-form-item prop="orderNumber" label="排序值" :label-width="formLabelWidth">
                <el-input type="number" :disabled="control.disabled" v-model="form.orderNumber" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as EnumApi from '@/api/enums'
import * as RoleApi from '@/api/role'

export default {
  name: 'RoleForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '80px',
      form: {},
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ],
        orderNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      RoleApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              RoleApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              RoleApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>