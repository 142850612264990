<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
              <el-form-item prop="name" label="网站名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="logo" label="Logo" :label-width="formLabelWidth">
                <div style="display: flex !important;align-items: center !important;">
                  <img v-if="logo" :src="form.logo" style="width: 100px; height: auto;" />
                  <i v-else class="el-icon-picture" style="font-size:100px;" />
                  
                  <el-button
                    style="margin-left: 10px;"
                    size="mini"
                    type="primary"
                    @click.stop="updatePicture('logo')">上传 (200x200)</el-button>
                  <input class="logo" :disabled="control.disabled" type="file" accept="image/*" @change="handleFile" style="display: none;">
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="24">
              <el-form-item prop="address" label="地址" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.address" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="12">
              <el-form-item prop="telphone" label="电话" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.telphone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="fax" label="传真" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.fax" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="12">
              <el-form-item prop="copyright" label="版权" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.copyright" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="icp" label="备案" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.icp" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="weixinQrcode" label="微信二维码" :label-width="formLabelWidth">
                <div style="display: flex !important;align-items: center !important;">
                  <img v-if="weixinQrcode" :src="form.weixinQrcode" style="width: 100px; height: auto;" />
                  <i v-else class="el-icon-picture" style="font-size:100px;" />
                  
                  <el-button
                    style="margin-left: 10px;"
                    size="mini"
                    type="primary"
                    @click.stop="updatePicture('weixinQrcode')">上传 (256x256)</el-button>
                  <input class="weixinQrcode" :disabled="control.disabled" type="file" accept="image/*" @change="handleFile" style="display: none;">
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="weiboQrcode" label="微博二维码" :label-width="formLabelWidth">
                <div style="display: flex !important;align-items: center !important;">
                  <img v-if="weiboQrcode" :src="form.weiboQrcode" style="width: 100px; height: auto;" />
                  <i v-else class="el-icon-picture" style="font-size:100px;" />
                  
                  <el-button
                    style="margin-left: 10px;"
                    size="mini"
                    type="primary"
                    @click.stop="updatePicture('weiboQrcode')">上传 (256x256)</el-button>
                  <input class="weiboQrcode" :disabled="control.disabled" type="file" accept="image/*" @change="handleFile" style="display: none;">
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item> -->
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
import * as EnumApi from '@/api/enums'
import * as HomepageApi from '@/api/homepage'
const OSS = require('ali-oss');
export default {
  name: 'HomepageForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '100px',
      form: {},
      weixinQrcode: '',
      weiboQrcode: '',
      logo: '',
      flag: '',
      rules: {
        name: [
          { required: true, message: '请输入网站名称', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      HomepageApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
        this.logo = that.form.logo
        this.weixinQrcode = that.form.weixinQrcode
        this.weiboQrcode = that.form.weiboQrcode
      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              HomepageApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.content = ''
              that.form.contentEn = ''
              HomepageApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    updatePicture(flag) {
      this.flag = flag;
      this.$el.querySelector('.'+flag).click()
    },
    async handleFile(e) {
      console.log('e',e)
      const $target = e.target || e.srcElement
      var file = $target.files[0]

      let fileType = file.type.toLowerCase()
      let ext
      if(fileType.indexOf('image') >= 0){
        ext = '.jpeg';
      }
      
      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      // OSS.urlib是sdk内部封装的发送请求的逻辑，开发者完全可以使用任何发请求的库向`sts-server`发送请求
      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.put(storeAs, file).then(result => {
        let picUrl = result.res.requestUrls[0];
        if(this.flag == 'logo'){
          this.form.logo = picUrl.split("?")[0];
          this.logo = this.form.logo;
        }else if(this.flag == 'weixinQrcode'){
          this.form.weixinQrcode = picUrl.split("?")[0];
          this.weixinQrcode = this.form.weixinQrcode;
        }else if(this.flag == 'weiboQrcode'){
          this.form.weiboQrcode = picUrl.split("?")[0];
          this.weiboQrcode = this.form.weiboQrcode;
        }
      }).catch(function(err) {})
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>