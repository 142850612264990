<template>
  <div class="layout" style="background: #242424;min-height:100vh;display: flex;flex-direction:column;height:100%;overflow-y:scroll;">
    <MainHeader />
    <div class="page-body" style="width:1000px;margin: 0 auto; flex:1">
      <h1 style="color: #ffffff;">最新资讯</h1>
      <div class="card-list">
        <el-card v-for="(item,index) in  news" :key="index" style="margin-bottom: 15px">
          <h4>{{item.title}}</h4>
          <p style="color: #ccc; font-size: 12px;">{{item.desc}} <a @click="showDialog" style="color: #446dec;font-weight: bold;">付费¥5元查看更多</a></p>
        </el-card>
      </div>
    </div>
    <MainFooter />

    <el-dialog
      title="付费阅读"
      :visible.sync="dialogVisible"
      width="40%">
      <div style="text-align: center;">
        <img src="@/assets/pay_qrcode.png" alt="" width="150" height="150">
        <p>价格：¥5元</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
export default {
  name: 'News',
  components: {
    MainHeader,
    MainFooter
  },
  data() {
    return {
      dialogVisible: false,
      news: [
          {
              "title": "AI技术如何重塑现代小说创作：自动生成故事情节",
              "desc": "随着人工智能技术的发展，小说创作正经历一场革命。AI能够分析大量文本数据，自动生成具有逻辑性和创意的故事情节，为作家提供灵感和支持。本文探讨了AI在小说创作中的应用..."
          },
          {
              "title": "人工智能辅助小说写作：从大纲到全文自动生成",
              "desc": "AI不仅能够生成故事情节，还可以根据大纲自动生成完整的小说文本。通过分析用户提供的故事元素和大纲，AI能够创作出符合用户预期的完整小说作品，极大地提高了写作效率..."
          },
          {
              "title": "利用AI提升小说创作效率：案例分析与实践",
              "desc": "本篇文章介绍了一些利用AI技术提升小说创作效率的实际案例。通过与几位知名作家的合作，展示了AI在灵感激发、情节发展和文本生成中的实际应用效果..."
          },
          {
              "title": "AI生成小说：机器能否超越人类作家的创意？",
              "desc": "AI生成小说正逐渐成为一种新趋势，但机器能否超越人类作家的创意仍然是一个争议话题。本文探讨了AI生成的小说在创意和情感表达方面的优势与局限..."
          },
          {
              "title": "从创意到出版：AI在小说写作流程中的角色",
              "desc": "AI不仅在小说创作的初期阶段发挥作用，还可以贯穿整个写作流程，包括编辑、润色和出版。本文详细介绍了AI在小说写作和出版过程中的各个环节中的应用..."
          },
          {
              "title": "AI与传统小说写作的融合：未来创作新模式",
              "desc": "传统小说写作与AI技术的融合正在形成一种全新的创作模式。作家们可以利用AI的强大数据分析能力，结合自己的创意，创作出更具吸引力和深度的小说作品..."
          },
          {
              "title": "探索AI驱动的互动小说：个性化阅读体验",
              "desc": "AI技术使得互动小说成为可能，通过实时分析读者的选择和偏好，生成个性化的故事情节。本文介绍了AI驱动的互动小说的基本原理及其在提高读者参与度方面的优势..."
          },
          {
              "title": "AI在小说创作中的创新应用：自动生成对话与人物描写",
              "desc": "AI不仅可以生成故事情节，还能自动生成对话和人物描写。通过分析大量的文学作品，AI能够模仿人类的写作风格，创作出自然流畅的对话和生动的角色..."
          },
          {
              "title": "AI与小说创作：机器学习如何改变文学创作的未来",
              "desc": "机器学习技术在小说创作中的应用越来越广泛。通过学习大量文学作品的数据，AI能够理解和模仿不同的写作风格，帮助作家们突破创作瓶颈..."
          },
          {
              "title": "AI赋能小说创作：从灵感激发到文本生成的全流程解析",
              "desc": "AI技术的不断进步使得从灵感激发到文本生成的整个小说创作流程变得更加高效。本文详细解析了AI如何在不同创作阶段提供帮助，提高作品的质量和创作速度..."
          }
      ],
      activeMenuItem: 1,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    showDialog() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
