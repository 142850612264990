<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="true"
        :visible.sync="control.show"
        direction="rtl"
        :append-to-body="true"
        :modal="false"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">

          <el-row>
            <el-col :span="12">
              <el-form-item prop="title" label="任务名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="amountType" label="奖励类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.amountType"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.AmountTypeEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="profitAmount" label="奖金比例" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.profitAmount" autocomplete="off"></el-input>%
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import * as EnumApi from '@/api/enums'
import * as TaskApi from '@/api/task'
import moment from "moment"
export default {
  name: 'TaskForm',
  components: {

  },
  props: {
    enums: '',
    stores: '',
    parents: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {storeId: '', content: '', picUrl: '', album: ''},
      rules: {
        title: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ]
      },
      gridData: [],
      stockNumberRepeat: []
    }
  },
  computed: {

  },
  mounted() {
    this.init();
  },
  methods: {
    init(){

      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      that.form = Object.assign({}, that.control.form);
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              TaskApi.updateToSetmeal(that.form).then(res => {
                that.control.show = false;
                that.$parent.$parent.handlePageSetmeal();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              TaskApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>
