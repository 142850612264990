import request from '@/utils/request'

// 枚举数据
export function list() {
  return request({
    url: '/auth-service/commons/enums/list',
    method: 'get'
  })
}

// 模板标签数据
export function templateTags() {
  return request({
    url: '/auth-service/commons/story/template/tags',
    method: 'get'
  })
}
