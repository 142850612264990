<template>
  <div class="menu">
    <template v-for="(item) in items">
      <div v-if="!item.hiddenFlag"
        :key="item.id" class="item"
        :class="{open: needOpenIds.includes(item.id)}">
        <div class="menu-header" @click.stop="handleOpen(item)">
          <i class="iconfont" :class="[`${item.icon}`]" />
          <template v-if="item.children && item.children.length==0">
            <router-link
                :key="item.id"
                :to="item.link ? item.link : ''"
                class="menuname"
                :class="{active: item.id === activeId}"
                @click.native="handleChange(item)">
              {{ item.name }}
            </router-link>
          </template>
          <template v-else>
            {{ item.name }}
          </template>
          <i v-show="item.children && item.children.length>0" class="el-icon-caret-right" />
        </div>
        <el-collapse-transition>
          <div v-show="needOpenIds.includes(item.id)">
            <div class="submenu">
              <template v-for="child in item.children">
                <router-link v-if="!child.hiddenFlag"
                    :key="child.id"
                    :to="child.link ? child.link : ''"
                    class="submenuitem"
                    :class="{active: child.id === activeId}"
                    @click.native="handleChange(child)">
                  {{ child.name }}
                </router-link>
              </template>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </template>
  </div>
</template>
<script>
import * as ResourceApi from '@/api/resource'
import {treeToList, findPnodeByNodeId, findNodeByNodeId} from '@/utils'
export default {
  name: 'LeftMenu',
  data() {
    return {
      items: [],
      needOpenIds: [],
      activeId: ''
    }
  },
  created() {
    this.handleInit()
  },
  methods: {
    handleOpen(item) {
      // console.log(this.needOpenIds, ">")
      if (this.needOpenIds.includes(item.id)) {
        this.needOpenIds.splice(this.needOpenIds.indexOf(item.id), 1)
      } else {
        this.needOpenIds.push(item.id)
      }
      // console.log(this.needOpenIds, ">>")
    },
    handleInit() {
      ResourceApi.treePermission4CurrentUser().then(res => {
        let result = res.data;
        let menus = result.data.menus;
        let actions = result.data.actions;
        localStorage.setItem('lyActions', JSON.stringify(actions))
        const home = menus[0].children[0] || menus[0]
        let currentId = localStorage.getItem('currentId')
        this.items = menus
        let needOpend = [];
        let activeId = ''

        if (!currentId) {
          currentId = home.id
          localStorage.setItem('currentId', currentId)
          needOpend = treeToList([].concat(menus[0]))
          this.needOpenIds = needOpend.map(item => { return item.id })
          this.activeId = currentId
        }else{
          this.activeId = currentId
          let parentNode = findPnodeByNodeId(menus,currentId)
          console.log(parentNode);
          if(parentNode){
            this.needOpenIds.push(parentNode.id)
          }

        }
        let activeNode = findNodeByNodeId(menus, this.activeId)
        this.$router.push(activeNode.link)
      });
    },
    handleChange(data) {
      this.activeId = data.id
      localStorage.setItem('currentId', data.id)
      // statlog
      this.$permits.log(data)
    }
  }
}
</script>
<style scoped lang="scss">
.menu {
  .item {
    padding: 0.5rem;
    border-bottom: .1rem solid #f1f1f1;
    .menuname{
      color: #001a40;
      &.active {
          color: #409eff;
          // background-color: #001a40;
        }
    }
    .menu-header {
      position: relative;
      font-size: 0.8rem;
      font-weight: bold;
      cursor: pointer;
      .menu-icon, .title, .el-icon-caret-right {
        vertical-align: middle;
        display: inline-block;
      }
      .el-icon-caret-right
 {
        float: right;
        line-height: inherit;
      }
    }
    .submenu {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      .submenuitem {
        flex: 0 0 50%;
        font-size: 0.8rem;
        font-weight: bold;
        color: #001a40;
        &.active {
          color: #409eff;
          font-size: 0.8rem;
          font-weight: bold;
        }
        &:nth-child(odd){
          text-align: left;
        }
        &:nth-child(even) {
          text-align: right;
        }
      }
    }
    &.open {
      .el-icon-caret-right {
        transform: rotate(90deg);
      }
    }
  }
}
.hiddenDropdown,
.hiddenDropname {
  display: none;
}
</style>
