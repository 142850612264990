<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="应用名称" prop="name" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
            <el-button v-if="!control.disabled" @click="resetForm('form')" size="mini">重置</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as EnumApi from '@/api/enums'
import * as ApplicationApi from '@/api/application'

export default {
  name: 'ApplicationForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '6rem',
      form: {},
      rules: {
        name: [
          { required: true, message: '请输入应用名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      ApplicationApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      let passed = false
      that.$refs['form'].validate((valid) => {
        passed = valid;
      });
      console.log("passed >> " + passed)
      if(!passed){
        return;
      }
      switch(action){
        case that.$permits.UPDATE:
          ApplicationApi.update(that.form).then(res => {
            that.control.show = false;
            that.$parent.handlePage();
          })
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          ApplicationApi.create(that.form).then(res => {
            that.control.show = false;
            that.$parent.handlePage();
          })
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
  }
}
</script>

<style>
</style>