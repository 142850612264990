<template>
<el-drawer
    title="手机截图任务审核"
    :enums="enums"
    :with-header="true"
    :visible.sync="controlTask.show"
    direction="rtl"
    :append-to-body="true"
    :modal-append-to-body="false"
    size="60%">
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="用户昵称" :label-width="formLabelWidth">
              <el-input v-model="queryForm.name" maxlength="9" size="small" placeholder="请输入名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item :label-width="formLabelWidth">
              <el-button type="primary" size="small" @click="handlePage()">搜索</el-button>
              <el-button size="small" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <!-- <el-table-column prop="id" label="id" type="selection" fixed></el-table-column> -->
      <el-table-column
        fixed
        prop="name"
        label="用户昵称"
        levelKey="depth"
        treeKey="id"
        parentKey="parentId"
        childKey="children"
        min-width="120px">
        </el-table-column>

      <el-table-column
        prop="picUrl"
        label="桌面截图">
        <template slot-scope="scope">
          <el-image
            style ="width:60px; height: 60px"
            :src="scope.row.desktopPicUrl"
            :preview-src-list="[scope.row.desktopPicUrl]"
          />
        </template>
      </el-table-column>

      <el-table-column
        prop="publishFlag"
        label="是否奖励金币"
        min-width="80px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.desktopFlag" :disabled="scope.row.desktopFlag" type="success" size="mini" round>已发放</el-button>
          <el-button v-else @click="$permits.has(targetPermit,$permits.CREATE)?handlePublish(scope.row,true):''" :disabled="scope.row.desktopFlag" type="danger" size="mini" round>发放金币</el-button>
          <el-button @click="handlePublish(scope.row,false)" type="warning" size="mini" round>重新提交</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            v-if="paginations.total>0"
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"/>
        </div>
      </el-col>
    </el-row>
    <task-form v-if="control.show" :control="control" :stores="stores" :enums="enums" :parents="parents" />
  </div>
</el-drawer>
</template>
<script>
import * as OrganizationApi from '@/api/organization'
import * as EnumApi from '@/api/enums'
import * as AccountApi from '@/api/account'
import TaskForm from './form'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Task',
  components: {
    TaskForm
  },
  props: {
    controlTask: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
      stores: [],
      enums: [],
    	// 权限key
      targetPermit: 'task',
      tableHeight: 0,
      queryForm: {
        title: '',
        fromType: 'desktop',
        current: 1,
        size: 10
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parents: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除套餐任务吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        AccountApi.removeToSetmeal(item.id).then(res => {
          // that.handlePageSetmeal();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        AccountApi.enabled({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handlePublish(item, flag) {
      let that = this;
      let tip = flag?'发放':'重新提交'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {

      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      console.log('current', current)
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // 获取表格数据
      AccountApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
