<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item prop="parentId" label="父级名称" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.parentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                  v-for="item in parentResources"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              
            </el-col>
            <el-col :span="8">
              
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="name" label="资源名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="category" label="类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.category"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ResourceCategoryEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="subcategory" label="子类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.subcategory"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.ResourceSubcategoryEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="serviceName" label="服务名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.serviceName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="method" label="请求方法" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.method"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.RequestMethodEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="uri" label="请求路径" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.uri" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item prop="link" label="前端路由" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.link" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <!-- <el-form-item label-width="5px"> -->
                <i style="font-size: 0.6rem;">若子类型为"目录"时，该值对应"权限对象,Eg. target;若子类型为"按钮"时，该值对应"权限标识,Eg. target:create"</i>
              <!-- </el-form-item> -->
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="icon" label="前端图标" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.icon" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="orderNumber" label="排序值" :label-width="formLabelWidth">
                <el-input type="number" :disabled="control.disabled" v-model.number="form.orderNumber" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="publicFlag" label="是否公开" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled" 
              v-model="form.publicFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
          <el-form-item prop="hiddenFlag" label="是否隐藏" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled" 
              v-model="form.hiddenFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
          
          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as ResourceApi from '@/api/resource'

export default {
  name: 'ResourceForm',
  props: {
    enums: '',
    parentResources: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '80px',
      form: {orderNumber: 0},
      rules: {
        name: [
          { required: true, message: '请输入资源名称', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        subcategory: [
          { required: true, message: '请选择子类型', trigger: 'blur' }
        ],
        orderNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      ResourceApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              ResourceApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              ResourceApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>