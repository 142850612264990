<template>
  <div class="layout" style="background: #242424;min-height:100vh;display: flex;flex-direction:column;height:100%;overflow-y:scroll;">
    <MainHeader />
    <div class="page-body" style="width:1000px;margin: 0 auto;flex:1">
      <h1 style="color: #ffffff;">合作方式</h1>
      <div class="contant-us" style="display:flex;align-items:center;flex-direction: column;">
        合作方式
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
export default {
  name: 'Contact',
  components: {
    MainHeader,
    MainFooter
  },
  data() {
    return {
      sliderBoxActive: 0,
      activeMenuItem: 1,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

</style>
