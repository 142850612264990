<template>
  <div class="layout" style="background: #ddd;min-height:100vh;display: flex;">
    <p style="font-size: 14px;color: #666;width: 100%;text-align: center;margin-top: 150px;">支付失败，请重试</p>
  </div>
</template>
<script>

export default {
  name: 'PayError',
  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

</style>
