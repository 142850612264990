// plugins/persistState.js
export default (
  store
) => {
  // 持久化 state 到 localStorage
  const persistedState = localStorage.getItem('vuex');
  // console.log('persistState', persistedState);
  if (persistedState) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(persistedState)));
  }
// console.log('persistState store', store);
  // 监听 state 的变化，同步到 localStorage
  store.subscribe((mutation, state ) => {
    localStorage.setItem('vuex', JSON.stringify(state));
  });
};
