<template>
  <div class="ly-container" style="display: flex; flex-direction: column;">
    <div style="position: absolute; z-index: 9001;left: 0;top: 0;width: 100%;">
      <MainHeader/>
    </div>
    <div class="fullpage-container">

      <div class="fullpage-wp" v-fullpage="opts" ref="example">
        <div class="page-1 page ">
          <div class="card">
            <MainHeader style="visibility: hidden;"/>
            <div class="wirter-slogin agent-fix-width">
              <!-- top -->
              <div class="wirter-slogin-top">
                <h1 class="wirter-slogin-top-title">码哩写作，小说创作金手指</h1>
                <div class="wirter-slogin-subtitle">
                  使用强大的生成式AI技术，融入爆款短篇、长篇的创作经验，帮助作者轻松创作出不同文风、不同题材的故事
                </div>
                <div class="wirter-slogin-fast-start">
                  <el-button style="border-radius: 6px !important;font-size: 1rem;padding: 0.5rem 2rem;" type="primary" size="small" @click="rightNowGo()">立即开始</el-button>
                </div>
              </div>
              <!-- top-end -->

              <!-- video -->
              <!-- <div class="" style="border-radius: 20px;"> -->
                <video-player ref="videoPlayer"
                       :options="playerOptions"
                       title="码哩写作"
                       @play="onPlayerPlay($event)"
                       @pause="onPlayerPause($event)"
                       @ended="onPlayerEnded($event)"
                       @loadeddata="onPlayerLoadeddata($event)"
                       @waiting="onPlayerWaiting($event)"
                       @playing="onPlayerPlaying($event)"
                       @timeupdate="onPlayerTimeupdate($event)"
                       @canplay="onPlayerCanplay($event)"
                       @canplaythrough="onPlayerCanplaythrough($event)"
                       @statechanged="playerStateChanged($event)"
                       @ready="playerReadied">
                </video-player>
              <!-- </div> -->

              <!-- <frame-player ref="theFramePlayer"/> -->
              <!-- video-end -->

            </div>
          </div>

          <!-- 背景 -->
          <div class="gradient-bg">
          	<svg viewBox="0 0 100vw 100vw" xmlns='http://www.w3.org/2000/svg' class="noiseBg">
          		<filter id='noiseFilterBg'>
          			<feTurbulence type='fractalNoise' baseFrequency='0.6' stitchTiles='stitch' />
          		</filter>
          		<rect width='100%' height='100%' preserveAspectRatio="xMidYMid meet" filter='url(#noiseFilterBg)' />
          	</svg>
          	<svg xmlns="http://www.w3.org/2000/svg" class="svgBlur">
          		<defs>
          			<filter id="goo">
          				<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          				<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
          					result="goo" />
          				<feBlend in="SourceGraphic" in2="goo" />
          			</filter>
          		</defs>
          	</svg>
          	<div class="gradients-container">
          		<div class="g1"></div>
          		<div class="g2"></div>
          		<div class="g3"></div>
          		<div class="g4"></div>
          		<div class="g5"></div>
          		<div class="interactive"></div>
          	</div>
          </div>
          <!-- 背景 end -->
        </div>
        <!-- page-1-end -->

        <!-- page-2 -->
        <div class="page-2 page writer-asigned">
          <div class="card">
            <MainHeader style="visibility: hidden;"/>
            <div class="agent-fix-width-nopadding">
              <h1  class="writer-title-bar">这些作者们在说</h1>
              <!-- avatar -->
              <marquee-text>
                <div class="marquee-item marquee-item-comment"
                   v-for="(item,index) in marqueeData1"
                   :key="index">
                    <div class="comment-box">
                      <div class="d-flex">
                        <div class="comment-box-avatar">
                          <el-image fit="cover" style="" :src="item.avatar"></el-image>
                        </div>
                        <div class="comment-box-text">
                          <div class="comment-box-text-title">
                            {{ item.nickname}}
                          </div>
                          <div class="comment-box-text-tag">
                              用户评价
                          </div>
                        </div>
                      </div>
                      <div class="comment-box-text-content">
                        {{ item.content }}
                      </div>
                    </div>
                 </div>
              </marquee-text>
              <marquee-text>
                <div class="marquee-item marquee-item-comment marquee-item-comment-mobile"
                   v-for="(item,index) in marqueeData2"
                   :key="index">
                    <div class="comment-box">
                      <div class="d-flex">
                        <div class="comment-box-avatar">
                          <el-image fit="cover" style="" :src="item.avatar"></el-image>
                        </div>
                        <div class="comment-box-text">
                          <div class="comment-box-text-title">
                            {{ item.nickname}}
                          </div>
                          <div class="comment-box-text-tag">
                              用户评价
                          </div>
                        </div>
                      </div>
                      <div class="comment-box-text-content">
                        {{ item.content }}
                      </div>
                    </div>
                 </div>
              </marquee-text>
            </div>
            <!-- 产品亮点 -->
            <div class="agent-fix-width">
              <h1 class="writer-title-bar">产品亮点</h1>
              <div class="writer-prod-insight">
                <div class="writer-prod-insight-item">
                  <div class="writer-prod-insight-item-num">+60%</div>
                  <div class="writer-prod-insight-item-title">效率提高</div>
                  <div class="writer-prod-insight-item-text">相同的作者，相同的文风，相同的类型元素，获得同一级别的作品，创作者使用码哩写作的效率</div>
                </div>
                <div class="writer-prod-insight-item">
                  <div class="writer-prod-insight-item-num">+80%</div>
                  <div class="writer-prod-insight-item-title">内容过稿率</div>
                  <div class="writer-prod-insight-item-text">内容过稿率超过作者自己普通投稿约20%，经过我们自己内容专家审核后的内容，过稿率提升80%</div>
                </div>
                <div class="writer-prod-insight-item">
                  <div class="writer-prod-insight-item-num">+500%</div>
                  <div class="writer-prod-insight-item-title">收益提升</div>
                  <div class="writer-prod-insight-item-text">提供优质签约渠道，优秀作品修稿，提升作者收益超500%</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 背景 -->
          <div class="gradient-bg">
          	<svg viewBox="0 0 100vw 100vw" xmlns='http://www.w3.org/2000/svg' class="noiseBg">
          		<filter id='noiseFilterBg'>
          			<feTurbulence type='fractalNoise' baseFrequency='0.6' stitchTiles='stitch' />
          		</filter>
          		<rect width='100%' height='100%' preserveAspectRatio="xMidYMid meet" filter='url(#noiseFilterBg)' />
          	</svg>
          	<svg xmlns="http://www.w3.org/2000/svg" class="svgBlur">
          		<defs>
          			<filter id="goo">
          				<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          				<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
          					result="goo" />
          				<feBlend in="SourceGraphic" in2="goo" />
          			</filter>
          		</defs>
          	</svg>
          	<div class="gradients-container">
          		<div class="g1"></div>
          		<div class="g2"></div>
          		<div class="g3"></div>
          		<div class="g4"></div>
          		<div class="g5"></div>
          		<div class="interactive"></div>
          	</div>
          </div>
          <!-- 背景 end -->
        </div>
        <!-- page-2-end -->

        <!-- page-3 -->
        <div class="page-3 page">
          <div class="card">
            <MainHeader style="visibility: hidden;"/>
            <div class="agent-fix-width writer-effect">
              <h1 class="writer-title-bar writer-effect-title">效果对比</h1>
              <div class="sub-title">
                同一段提示词，码哩写作和其它AI的区别
              </div>
              <div class="writer-effect-box">
                <div class="writer-effect-text ">
                  <div class="writer-effect-text-label" @click="typeCharMali">
                    Maliwriter
                  </div>
                  <div class="mali-text typewriter-mali mali-scrollbar" id="mali-text" v-html="maliTextContent">

                  </div>
                </div>
                <div class="writer-effect-text ">
                  <div class="writer-effect-text-label" @click="typeCharOther">
                    Other AI
                  </div>
                  <div class="other-text typewriter-other mali-scrollbar" id="other-text" v-html="otherTextContent">

                  </div>
                </div>
              </div>
              <div class="" style="margin-bottom: 1rem;">
                <el-button style="border-radius: 6px !important;font-size: 1rem;padding: 0.5rem 2rem;" type="primary" size="small" @click="rightNowGo()">立即开始</el-button>
              </div>
            </div>
          </div>

          <!-- 背景 -->
          <div class="gradient-bg">
          	<svg viewBox="0 0 100vw 100vw" xmlns='http://www.w3.org/2000/svg' class="noiseBg">
          		<filter id='noiseFilterBg'>
          			<feTurbulence type='fractalNoise' baseFrequency='0.6' stitchTiles='stitch' />
          		</filter>
          		<rect width='100%' height='100%' preserveAspectRatio="xMidYMid meet" filter='url(#noiseFilterBg)' />
          	</svg>
          	<svg xmlns="http://www.w3.org/2000/svg" class="svgBlur">
          		<defs>
          			<filter id="goo">
          				<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          				<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
          					result="goo" />
          				<feBlend in="SourceGraphic" in2="goo" />
          			</filter>
          		</defs>
          	</svg>
          	<div class="gradients-container">
          		<div class="g1"></div>
          		<div class="g2"></div>
          		<div class="g3"></div>
          		<div class="g4"></div>
          		<div class="g5"></div>
          		<div class="interactive"></div>
          	</div>
          </div>
          <!-- 背景 end -->
        </div>
        <!-- page-3-end -->

        <!-- page-4 -->
        <div class="page-4 page writer-problem">
          <div class="">
            <MainHeader style="visibility: hidden;"/>
            <!-- 合作伙伴 -->
            <div class="agent-fix-width-nopadding" v-if="false">
              <h1 class="writer-title-bar">合作伙伴</h1>
              <div class="co-workmate">
                <marquee-text :duration="30">
                  <div class="marquee-item marquee-item-cowork"
                     v-for="(item,index) in workmates"
                     :key="index">
                      <el-image class="co-workmate-item" fit="cover" :src="item"></el-image>
                   </div>
                </marquee-text>
              </div>
            </div>
            <!-- 常见问题 -->
            <div class="agent-fix-width">
                <h1 class="writer-title-bar">常见问题</h1>
                <el-collapse v-model="activeName" accordion>
                  <el-collapse-item title="码哩写作是什么？" name="1">
                    <div style="line-height: 2rem;">
                      码哩写作是一款专门为网文创作者而生的 Ai写作工具。我们认真听取网文从业者的声音，剖析杰出的标杆内，构建了大量的网文领域的默会知识，独特的数据和知识库，再结合最新的大语言模型，打造出来的全新一代AI创作辅助工具。
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="码哩写作适合谁用？" name="2">
                    <div style="line-height: 2rem;">
                      码哩写作适合所有阶段的创作者群体，包括写作爱好者，初入网文的小白写手，有所心得的兼职写手，以及熟能生巧的全职写手，甚至包括从来没有写过小说的读者，可以通过它来实现另一种形式的 “创造型阅读体验”。
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="创作者们为什么要使用码哩写作？" name="3">
                    <div style="line-height: 2rem;">
                      19世纪80年代，当汽车出现时，马车是反对的。
                      <br />
                      回顾历史，先进生产力的诞生从来都是伴随着巨大的争议。几乎无一例外。AI已经在各个领域不断渗透、影响我们的生活和工作，和 AI 共生，是我们都需要面对的问题。
                      <br />
                      如果你理解大语言模型是对过去经验和知识的压缩，那么创造力将是人类最后的那张遮羞布。
                      <br />
                      创作是最能体现创造力的部分之一，也是人类驾驭AI最大的法宝。
                      <br />
                      我们提供的码哩写作并非想取代作者，而是希望通过我们对创作者的理解以及对AI技术的认知，最大程度的帮助创作者提高效率，最大程度的帮助创作者把时间节省下来，投入到创造力更高的地方，让作者真正主导AI，创造出属于自己独一无二的内容，这条路应该会比较漫长，但是我们想试一试。
                      <br />
                      记住，AI 永远无法去取代你，能取代你的永远是比你还会运用AI的人。
                      <br />
                      马车的车夫这个职业，最终淡出历史舞台，取而代之的是会驾驶汽车的司机。
                      <br />
                      当你还在日更2000字的时候，你突然发现之前和你同一级别的作者日更20万字，不用怀疑，他应该已经熟练的利用了AI。
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="码哩写作是如何帮助作者写小说？" name="4">
                    <div style="line-height: 2rem;">
                      我们通过先进的语言模型和独有的数据库来帮助创作者梳理了整个创作流程，并且通过强大的AI能力，实现了通过多组智能体（Agent），解决整体超过60%的重复性低效率工作的自动化问题。比如当我出一个想法、一个人设后，可以利用码哩写作快速生成小说元素和大纲，这节省了创作者在当前这个部分投入的80%的时间。
                      <br />
                      据我们统计，同样一位作者，过去一周也许只能写一本2w字小说，现在，稍加理解，可以每天写2本以上的同级别的作品。效率值大幅度提升。
                      <br />
                      但在这里值得注意的是，同样的题材，同样的文风，一位对读者、对内容更理解、更具备创意的创作者，稍加干涉，利用码哩写作产品完成的作品质量确实远远超过一位创作新人。
                      <br />
                      我们为此也提供了基本的产品使用说明（不定期的工具使用培训说明等），会进一步告诉你，什么地方要由你来主导创造力，什么时候可以把琐碎的工作交给AI，帮助你驾驭AI创造出独特的内容。
                      <br />
                      如您想进一步了解相关说明，可以通过首页二维码和我们CreatiMix的成员联系。
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="码哩写作和市面上其他AI产品的区别是什么？" name="5">
                    <div style="line-height: 2rem;">
                      表面上对比，码哩写作生成出来的作品比其他Ai工具更能“读下去”，“AI味道”没那么重，可选择控制的地方更多，比如可以调整文风、类型等等。
                      <br />
                      实际本质是我们对于创作者和内容本身最大的尊重，就是我们和其他 AI 产品最大的区别。
                      <br />
                      基于此出发，我们过去做了大量的琐碎的工作。比如思考利用 码哩 写作“如何构建一个引人入胜的故事框架？”“如何能够设计出一个触动人心的角色”“好的对白和差的对白到底有什么本质的区别？”“想沉浸式的体验一下和李白聊天是什么感受？”“如果白居易有微信，他会如何发朋友圈”。
                      <br />
                      我们尊重团队内部的内容专家，也积极听取作者给我们反馈的重要建议，不断的优化我们自己的原创的数据库和知识库，将网文领域的精华通过全新的且科学的方式呈现给大家，而这一个过程，我们一直还在不断的自我超越，按照每周一个版本的迭代速度快速让创作者们使用到更强大的AI产品。
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <!-- 底部 -->
            <MainFooter />
          </div>
        </div>
        <!-- page-4-end -->

        <!-- next-page -->
        <div class="next-page-box" v-if="pageIndex == 0">
          <div class="agent-fix-width" @click="moveNext">
            <div>滑动浏览更多</div>
            <i class="el-icon-arrow-down arrow-down" style="font-weight: bold;"></i>
          </div>
        </div>
        <!-- next-page-end -->

        <Contact />
      </div>
    </div>
  </div>
</template>
<script>
// https://www.npmjs.com/package/fullpage-vue
// https://github.com/surmon-china/videojs-player/tree/v5.0.2
// https://evodiaaut.github.io/vue-marquee-text-component/
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import { videoPlayer } from 'vue-video-player'
import MarqueeText from 'vue-marquee-text-component'
import { mapState, mapMutations} from 'vuex'
import Contact from '@/components/Contact'
import VueDragResize from 'vue-drag-resize';
// import FramePlayer from 'vue-frame-player'
export default {
  name: 'Login',
  components: {
    MainHeader,
    MainFooter,
    Contact,
    VueDragResize,
  },
  data() {
    return {
      maliIndex: 0,
      otherIndex: 0,
      pageIndex: 0,
      maliTextContent: '',
      otherTextContent: '',
      marqueeData1: [
        {
          avatar: 'https://maliwriter.com/static/avatar-1.png',
          nickname: '用户138****3470',
          content: '方便，快捷，生成的文，文风不呆板，不生硬，没有奇怪的 ai文风，也没有语病。'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-2.png',
          nickname: '用户137****2056',
          content: '感觉特别好我就这两天才知道，比我之前接触过的 ai软件都要智能'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-3.png',
          nickname: '用户185****1980',
          content: '我来报喜啦!我长篇过稿啦，千字二十保底，谢谢老师，软件太绝了!'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-4.png',
          nickname: '用户180****5566',
          content: '可可可阿阿阿啊啊啊！我过了!我过了!我上知乎了!我爱码哩写作!我爱你!♥️'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-5.png',
          nickname: '用户186****1205',
          content: '妈耶，超好用。怎么没早点知道，再也不用为交稿发愁了✌️'
        }
      ],

      marqueeData2: [
        {
          avatar: 'https://maliwriter.com/static/avatar-3.png',
          nickname: '用户138****2576',
          content: '这个文风，好自然，可以直接用，都不太用修改了，马上就能投稿的节奏👍👍👍'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-5.png',
          nickname: '用户137****9806',
          content: '我怎么感觉它写的比我好?我漏掉的逻辑它都给我补上了，而且文风和我一样!好惊喜!🙀'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-4.png',
          nickname: '用户185****2255',
          content: '好好用啊，比别的ai聪明好多🐂'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-6.png',
          nickname: '用户180****1234',
          content: '不有这种好东西，下次早点掏给我，你妹穷，要比别人先用！'
        },
        {
          avatar: 'https://maliwriter.com/static/avatar-2.png',
          nickname: '用户186****8678',
          content: '我把公司编辑全开了，专心搞这个'
        }
      ],
      workmates: [
        'https://maliwriter.com/static/co-workmate-1.png',
        'https://maliwriter.com/static/co-workmate-2.png',
        'https://maliwriter.com/static/co-workmate-3.png',
        'https://maliwriter.com/static/co-workmate-4.png',
        'https://maliwriter.com/static/co-workmate-5.png',
        'https://maliwriter.com/static/co-workmate-6.png',
        'https://maliwriter.com/static/co-workmate-7.png',
        'https://maliwriter.com/static/co-workmate-8.png',
      ],
      activeName: '0',
      playerOptions: {
        // component options
        start: 0,
        playsinline: false,
        // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          // src: "https://image.uc.cn/s/uae/g/3o/broccoli/resource/202408/45060e5781c02920.mp4"
          src: "https://maliwriter.com/static/homepage.mp4"
        }],
        // poster: "/static/images/author.jpg",
      },
      opts: {
        start: 0,
        dir: 'v',
        loop: false,
        duration: 500,
        overflow: 'hidden',
        beforeChange: (currentSlideEl,currenIndex,nextIndex) => {
          console.log('beforeChange', currentSlideEl,currenIndex,nextIndex);
        },
        afterChange: (currentSlideEl,currenIndex) => {
          console.log('afterChange', currentSlideEl,currenIndex);
          if(currenIndex == 2){
            // 开始打字效果
            this.maliIndex = 0
            this.maliTextContent = ''
            this.typeCharMali()
            this.otherIndex = 0
            this.otherTextContent = ''
            this.typeCharOther()
          }
          // 显示下滑提示
          this.pageIndex = currenIndex;
        }
      }
    }
  },
  created() {

  },
  mounted() {

    // console.log('this is current player instance object', this.player)
    document.addEventListener('DOMContentLoaded', () => {
      const interBubble = document.querySelector('.interactive');
      let curX = 0;
      let curY = 0;
      let tgX = 0;
      let tgY = 0;

      const move = () => {
        curX += (tgX - curX) / 20;
        curY += (tgY - curY) / 20;
        interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(move);
      };

      window.addEventListener('mousemove', event => {
        tgX = event.clientX;
        tgY = event.clientY;
      });
      move();
    });
    // this.$nextTick(() => {
    //   var video = this.$refs.example;
    //   video.play();
    // });
  },
  computed: {
    ...mapState(['isAutnenticated']),
    // player() {
    //   return this.$refs.videoPlayer.player
    // }
  },
  methods: {
    ...mapMutations(['setShowLoginDialogFlag']),
    rightNowGo(){
      console.log('this.isAutnenticated', this.isAutnenticated);
      if(!!localStorage.lyToken){
        this.$router.push('/home')
      }else{
        this.setShowLoginDialogFlag(true)
      }
    },
    moveNext(){
      // console.log('22', this.$refs.example.$fullpage);
      this.$refs.example.$fullpage.moveNext(); //Move to the next page
    },
    scrollToBottom(id) {
      const msgbox = document.getElementById(id)
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    typeCharMali(){
      // console.log('22');
      let text = `昆北渔站在民政局门口，焦急地来回踱步。她的长发在微风中轻轻飘动，眼睛不停地扫视着周围的人群，希望能看到那个熟悉的身影。然而，时间一分一秒地流逝，她的未婚夫始终没有出现。
      <br>她再次掏出手机，拨打了那个已经烂熟于心的号码。"对不起，您拨打的电话暂时无人接听..."冰冷的电子音再次传来，昆北渔咬着嘴唇，强忍着内心的不安。
      <br>"也许他只是堵车了，或者手机没电了。"她自言自语地安慰着自己，但心里的不安却越来越强烈。
      <br>就在这时，手机突然震动起来。昆北渔急忙接起电话，却发现是师兄承天佑的声音。 "北渔，你们领证了吗？"承天佑的声音有些虚弱，似乎在强忍着痛苦。
      <br>"师兄，你怎么了？"昆北渔敏锐地察觉到了异常。 <br>"没事，就是发高烧，在打针。"承天佑轻描淡写地说，"我是想告诉你，伯伯和阿姨准备了一顿饭，想给你们庆祝。你们领完证就过来吧。"
      <br>昆北渔突然感到一阵鼻酸，眼泪不受控制地涌了出来。"师兄...他...他没来..."她哽咽着说。 <br>电话那头沉默了几秒。"要不要等等看？"承天佑轻声问道。
      <br>"不用了，我...我去他家看看。"昆北渔擦了擦眼泪，强迫自己冷静下来。 <br>挂断电话后，昆北渔立刻打车前往未婚夫的家。一路上，她的心跳得越来越快，各种可怕的猜测在脑海中闪过。
      <br>当她终于到达目的地时，眼前的景象让她惊呆了。未婚夫的家门口布满了黑色的痕迹，就像被火烧过一样。昆北渔颤抖着伸手触摸那些黑色痕迹，一种不祥的预感涌上心头。`
      if (this.maliIndex < text.length) {
        this.maliTextContent += text.charAt(this.maliIndex);
        this.maliIndex += 1;
        this.scrollToBottom('mali-text')
        setTimeout(this.typeCharMali, 80); // 调整打字速度
      }
    },
    typeCharOther(){
      let text = `在昆北渔的记忆中，父亲的形象总是模糊而温暖的，那位老中医的微笑如同春日暖阳，温暖了她孤独的童年。师兄承天佑，虽然性格抑郁，寡言少语，但他的存在就像是她生命中的一盏灯塔，指引着她前行。然而，随着时间的流逝，昆北渔的心中渐渐生出了一丝难以言说的情感，那种情感在见到Salina后变得更加复杂。
      <br>昆北渔的黑长直发在风中轻轻飘扬，她的眼神中透露出一丝坚定，尽管师兄一次次地拒绝她的告白，但她始终没有放弃。直到那天，承天启的警告如同冷水浇头，让她彻底清醒。她决定，是时候放手了。
      <br>在一次偶然的旅行中，昆北渔遇到了谢子期，那个擅长医术的少年。他的风采让她想起了父亲，那是一种久违的熟悉感。她与他订婚，却不知这是一场惊天阴谋的开端,
      <br>传说中的不死药，神裔的秘密，昆北渔的父亲之死，这一切都指向了一个古老的传说--昆仑。昆北渔，这个中药世家的孩子，实际上是一位昆仑的守护者，她的血脉中流淌着神族的血液。
      <br>承天佑的心中充满了矛盾，他对昆北渔的爱深如海，但他身上的寄生生物让他觉得自己不配拥有爱情。他的每一次拒绝，都是对自己情感的折磨。
      <br>谢子期，那个表面上对她痴情的男子，实际上却是一个狡猾的反派。他利用昆北渔的感情，试图揭开不死药的谜团，打开昆仑的秘密。
      <br>在这场阴谋中，各色人物纷纷登场。承天启的野心，Salina的嫉妒，李老鬼的秘密，朝风的冷漠，古老三的沉默、奉七少的冷酷，李青松的神秘，以及小飞的坚定，每个人都在为自己的信念和目的而战。`

      if (this.otherIndex < text.length) {
        this.otherTextContent += text.charAt(this.otherIndex);
        this.otherIndex += 1;
        this.scrollToBottom('other-text')
        setTimeout(this.typeCharOther, 80); // 调整打字速度
      }
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded(player) {
      // console.log('player pause!', player)
      player.play()
    },
    onPlayerLoadeddata(player) {
      // console.log('player pause!', player)
    },
    onPlayerWaiting(player) {
      // console.log('player pause!', player)
    },
    onPlayerPlaying(player) {
      // console.log('player pause!', player)
    },
    onPlayerTimeupdate(player) {
      // console.log('player pause!', player)
    },
    onPlayerCanplay(player) {
      console.log('player pause!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player pause!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied(player) {
      console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
      player.play()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/style.css';
video {
  border-radius: 10px !important;
  object-fit: cover;
}

.ly-container {
  width: 100vw;
  height: 100vh;
  background: #252525;
}
// fullpage
.fullpage-container {
  background-color: #252525;
  color: #ffffff;

  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
/* 全局滚动条轨道的padding */
.mali-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}

/* 滚动条的样式 */
.mali-scrollbar::-webkit-scrollbar {
  width: 8px;
  margin: 1rem 0;
  background-color: transparent;
}

.mali-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  // border-radius: 6px;
  // background-color: transparent;
}

// PC
@media screen and (min-width: 768px) {
/deep/ .vjs_video_3-dimensions {
    width: auto !important;
    height: 400px !important;
}
//video
.writer-title-bar {
  margin: 3rem 0;
  font-size: 1.5rem;
}
.wirter-slogin {
  margin: 0 auto;
  text-align: center;
  &-subtitle {
    color: rgba(255, 255, 255, .8);
  }
  &-fast-start {
    margin: 1.5rem 0;
  }
  /deep/ .vjs-tech {
    border-radius: 10px !important;
    width: auto;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  /deep/ .video-js {
    background-color: transparent;
    // width: 100%;
    margin: 0 auto;
  }
  /deep/ .vjs-big-play-button {
    display: none;
  }
  /deep/ .vjs-control-bar{
    display: none;
  }
}
.writer-asigned {
  text-align: center;
}
// next-page
.next-page-box {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 1rem;
  color: rgba(255, 255, 255, .8);
  width: 100%;
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
  z-index: 9007;
  .el-icon-arrow-down {
    margin-left: -1rem;
  }
}
// next-page-end

// marquee
.marquee-item {
  padding: .5rem 1rem;
  margin: 1rem .5rem;
  background: rgba(255, 255, 255, .9);
  display: inline-block;
  border-radius: 10px;
  &-comment {
    margin: 1rem .5rem;
    padding: 1rem;
  }
  &-cowork {
    margin: 1rem 1rem;
    padding: 0;
    background: transparent;
  }
}
.comment-box {
  display: flex;
  flex-direction: column;
  &-avatar {
    border-radius: 100%;
    margin-right: .5rem;
    .el-image {
      border-radius: 100%;
      // border: solid 1px #129FF5;
      width: 3rem;
      height: 3rem;
    }
  }
  &-text {
    text-align: left;
    &-title {
      margin-bottom: .5rem;
      color: rgba(0, 0, 0, .9);
    }
    &-tag {
      color: rgba(0, 0, 0, .6);
      font-size: .8rem;
    }
    &-content {
      color: rgba(0, 0, 0, .9);
      margin-top: 1rem;
      max-width: 20rem;
      text-wrap: wrap;
      white-space: wrap;
      text-align: left;
      font-size: .8rem;
    }
  }
}
.writer-prod-insight {
  display: grid;
  grid-template-columns:  repeat(3, 1fr);
  gap: 4rem;
  // margin-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  &-item {
    font-weight: 600;
    text-align: left;
    flex: 1;
    &-num {
      color: #C0D3FF;
      font-size: 60px;
      margin-bottom: 1rem;
    }
    &-title {
      color: #FFFFFF;
      font-size: 24px;
      // line-height: 40px;
      margin-bottom: 1rem;
    }
    &-text {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255,255,255,0.6);
      line-height: 32px;
      text-align: justify;
    }
  }
}
.writer-effect {
  display: flex;
  flex-direction: column;
  text-align: center;
  &-box {
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    gap: 4rem;
    margin-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    box-sizing: border-box;
  }
  .sub-title {
    margin-bottom: 1rem;
  }
  &-title {
    margin: 2rem 0;
  }
  &-text {
    // background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    margin: 10px;
    background: rgba(255,255,255,0.2);
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    line-height: 20px;
    text-align: justify;
    padding: 8px;
    flex: 1;
    &-label {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      text-align: center;
      padding: 1rem 0;
      background: rgba(255, 255, 255, .9);
      border-radius: 14px 14px 0 0;
    }
  }
  .mali-text, .other-text {
    text-align: justify;
    height: 50vh;
    font-size: 12px;
    background: rgba(255, 255, 255, .9);
    padding: 0 1.5rem 1rem 1.5rem;
    border-radius: 0 0 14px 14px;
    line-height: 24px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.agent-fix-width {
  padding: 1rem 0rem;
  max-width: 1024px;
  min-width: 1024px;
  // width: 100%;
  margin: 0 auto;
}
.agent-fix-width-nopadding {
  // max-width: 1024px;
  min-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.writer-problem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #29282B;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  /deep/ .el-collapse {
    border: none;
    text-align: left;

  }
  /deep/ .el-collapse-item__header {
    background-color: #29282B;
    color: #ffffff;
    border-bottom: none;
    font-size: 14px;
    font-weight: 400;
  }
  /deep/ .el-collapse-item__content {
    background-color: #29282B;
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    font-weight: normal;
    line-height: 36px;
  }
  /deep/ .el-collapse-item__wrap {
    border-bottom: 1px solid #424242;
  }
}
.co-workmate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .co-workmate-item {
    width: 80px;
    height: 80px;
  }
}

}
// mobile
@media screen and (max-width: 767px) {
/deep/ .vjs_video_3-dimensions {
    width: auto !important;
    height: 200px !important;
}
//video
.writer-title-bar {
  margin: 1rem 0;
  font-size: 5vw;
}
.wirter-slogin {
  margin: 0 auto;
  text-align: center;
  &-top {
    margin: 3rem 0;
    padding: 0 1rem;
    &-title {
      font-size: 5vw;
    }
  }
  &-subtitle {
    line-height: 2rem;
    color: rgba(255, 255, 255, .8);
    font-size: 3vw;
  }
  &-fast-start {
    margin: 1.5rem 0;
  }
  /deep/ .vjs-tech {
    border-radius: 10px !important;
    width: auto;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  /deep/ .video-js {
    background-color: transparent;
    margin: 0 auto;
  }
  /deep/ .vjs-big-play-button {
    display: none;
  }
  /deep/ .vjs-control-bar{
    display: none;
  }
}
.writer-asigned {
  text-align: center;
}
// next-page
.next-page-box {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 1rem;
  color: rgba(255, 255, 255, .8);
  width: 100%;
  text-align: center;
  z-index: 9007;
  padding: 1rem;
  margin: 0 auto;
  .el-icon-arrow-down {
    margin-left: -1rem;
  }

}
// next-page-end

// marquee
.marquee-item {
  padding: .5rem 1rem;
  margin: 1rem .5rem;
  background: rgba(255, 255, 255, .9);
  display: inline-block;
  border-radius: 10px;
  &-comment {
    margin: 1rem .5rem;
    padding: 1rem;
    &-mobile {
      display: none;
    }
  }
  &-cowork {
    margin: 1rem 1rem;
    padding: 0;
    background: transparent;
  }
}
.comment-box {
  display: flex;
  flex-direction: column;
  &-avatar {
    border-radius: 100%;
    margin-right: .5rem;
    .el-image {
      border-radius: 100%;
      // border: solid 1px #129FF5;
      width: 3rem;
      height: 3rem;
    }
  }
  &-text {
    text-align: left;
    &-title {
      margin-bottom: .5rem;
      color: rgba(0, 0, 0, .9);
    }
    &-tag {
      color: rgba(0, 0, 0, .6);
      font-size: .8rem;
    }
    &-content {
      color: rgba(0, 0, 0, .9);
      margin-top: 1rem;
      max-width: 20rem;
      text-wrap: wrap;
      white-space: wrap;
      text-align: left;
      font-size: .8rem;
    }
  }
}
.writer-prod-insight {
  display: grid;
  grid-template-columns:  repeat(3, 1fr);
  gap: 1rem;
  // margin-top: 1rem;
  padding: 0 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  &-item {
    font-weight: 600;
    text-align: left;
    flex: 1;
    &-num {
      color: #C0D3FF;
      font-size: 7vw;
      margin-bottom: 1rem;
    }
    &-title {
      color: #FFFFFF;
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 1rem;
    }
    &-text {
      font-size: 12px;
      color: rgba(255,255,255,0.6);
      line-height: 28px;
      text-align: justify;
    }
  }
}
.writer-effect {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 1rem;
  &-box {
    display: grid;
    grid-template-rows:  repeat(2, 1fr);
    row-gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
  }
  .sub-title {
    margin-bottom: 1rem;
  }
  &-text {
    border-radius: 20px 20px 20px 20px;
    background: rgba(255,255,255,0.2);
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    line-height: 20px;
    text-align: justify;
    padding: 8px;
    flex: 1;
    &-label {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      text-align: center;
      padding: 1rem 0;
      background: rgba(255, 255, 255, .9);
      border-radius: 14px 14px 0 0;
    }
  }
  .mali-text, .other-text {
    text-align: justify;
    height: 20vh;
    font-size: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(255, 255, 255, .9);
    padding: 0 1.2rem;
    border-radius: 0 0 14px 14px;
    line-height: 24px;
  }
}
.agent-fix-width {
  width: 100%;
  margin: 0 auto;
}
.agent-fix-width-nopadding {
  width: 100%;
  margin: 0 auto;
}
.writer-problem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #29282B;
  color: #ffffff;
  text-align: center;

  /deep/ .el-collapse {
    border: none;
    text-align: left;
    padding: 0 1rem;
  }
  /deep/ .el-collapse-item__header {
    background-color: #29282B;
    color: #ffffff;
    border-bottom: none;
    font-size: 14px;
    font-weight: 400;
  }
  /deep/ .el-collapse-item__content {
    background-color: #29282B;
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    font-weight: normal;
    line-height: 36px;
  }
  /deep/ .el-collapse-item__wrap {
    border-bottom: 1px solid #424242;
  }
}
.co-workmate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .co-workmate-item {
    width: 80px;
    height: 80px;
  }
}

}

</style>
