<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
      <el-form-item prop="name" label="角色名称" :label-width="formLabelWidth">
            <el-radio-group v-model="queryForm.id" size="mini" @change="handleChange">
              <el-radio :key="item.id" v-for="item in roles"  border :label="item.id" >{{item.remark}}</el-radio>
            </el-radio-group>
          </el-form-item>
    </el-form>

    <!-- 数据 -->
    <el-row class="table-container">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header">
            <span>菜单资源</span>
            <el-button
              style="float: right;"
              @click="handleGrant('MENU')"
              type="primary" size="mini">分配</el-button>
            <el-button
              style="float: right; margin-right: 0.5rem;"
              @click="handleClear('MENU')"
              type="danger" size="mini">清空</el-button>
          </div>
          <el-tree
              :data="resources.menus"
              default-expand-all
              node-key="id"
              ref="MENU"
              :disabled="true"
              highlight-current
              show-checkbox
              :props="props">
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header">
            <span>操作资源</span>
            <el-button
              style="float: right;"
              @click="handleGrant('ACTION')"
              type="primary" size="mini">分配</el-button>
            <el-button
              style="float: right; margin-right: 0.5rem;"
              @click="handleClear('ACTION')"
              type="danger" size="mini">清空</el-button>
          </div>
          <el-tree
              :data="resources.actions"
              default-expand-all
              node-key="id"
              ref="ACTION"
              :disabled="true"
              highlight-current
              show-checkbox
              :props="props">
          </el-tree>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as ResourceApi from '@/api/resource'
import * as RoleApi from '@/api/role'
import { buildTree, treeToList, deepClone } from '@/utils'
export default {
  name: 'GrantResource',
  components: {
    
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
      resources: { actions: [], menus: []},
      roles: [],
      props: {
          children: 'children',
          label: 'name'
      },
      targetPermit: 'application',
      tableHeight: 0,
      queryForm: {
        name: '',
        category: '',
        id: '',
        size: 100
      },
      control: {
        show: false,
        title: '',
        action: 'view',
        form: '',
        disabled: false
      }
    }
  },
  mounted() {
    this.handleInit()
  },
  methods: {
    // 重置
    handleInit() {
      let that = this;
      // role
      RoleApi.page(that.queryForm).then(res => {
        let result = res.data.data
        that.roles = result.records
        that.queryForm.id = that.roles[0].id || '0'
        that.handleChange()
      })
      //
      // that.handleEnum()
    },
    handleClear(action) {
      let that = this;
      that.$confirm('', '确定清空吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        let data = 'actions'
        if(action == 'MENU'){ data = 'menus' }
        console.log("handleClear -> that.resources >>> ", that.resources)
        let temp = JSON.parse(JSON.stringify(that.resources))
        let temp1 = treeToList(temp[data]);
        console.log("handleClear -> temp1 >>> ", temp1)
        let all = temp1.map(item=>item.id)
        let params = {roleId: that.queryForm.id, category: action, resourceIds: all}
        ResourceApi.clear(params).then(res => {
          that.handleChange()
        })
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // enums
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handleChange() {
      let that = this;
      // resource
      ResourceApi.page({size: 500, roleId: that.queryForm.id, category: 'MENU'}).then(res => {
        let records = res.data.data.records
        let recordClone = deepClone(records)
        that.resources.menus = buildTree(recordClone,0,0)
        let hasMenus = records.filter(item=>item.hasFlag && item.subcategory != 'DIRECTORY')
                              .map(item=>item.id)
        that.$refs.MENU.setCheckedKeys(hasMenus)
        // that.$refs.MENU.setCheckedNodes(hasMenus);
        // that.handleHasKeys()
      })
      ResourceApi.page({size: 500, roleId: that.queryForm.id, category: 'ACTION'}).then(res => {
        let records = res.data.data.records
        let recordClone = deepClone(records)
        that.resources.actions = buildTree(recordClone,0,0)
        // that.handleHasKeys()
        let hasActions = records.filter(item=>item.hasFlag && item.subcategory != 'DIRECTORY')
                                .map(item=>item.id)
        that.$refs.ACTION.setCheckedKeys(hasActions)
        // that.$refs.ACTION.setCheckedNodes(hasActions);
      })
    },
    handleGrant(action) {
      let that = this
      let checkedKeys = that.$refs[action].getCheckedKeys()
      let halfCheckedKeys = that.$refs[action].getHalfCheckedKeys()
      let allCheckedKeys = checkedKeys.concat(halfCheckedKeys)
      let params = {roleId: that.queryForm.id, category: action, resourceIds: allCheckedKeys}
      console.log("handleGrant -> action >>> ", action)
      console.log("handleGrant params >>> ", params)
      ResourceApi.grant(params).then(res => {
        that.handleChange()
      })
    }
  }
}
</script>

<style lang="css">
.el-card {
    overflow-y: scroll !important;
}
</style>