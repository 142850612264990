<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">

          <el-row>
            <el-col :span="8">
              <el-form-item prop="coin" label="用户信息" :label-width="formLabelWidth">
                <div>公司名称：{{form.store?form.store.organizationName:''}}</div>
                <div>店铺名称：{{form.store?form.store.storeName:''}}</div>
                <div>昵称：{{form.creator?form.creator.name:''}}</div>
                <div>真实姓名：{{form.trueName}}</div>
                <div>手机号：{{form.creator?form.creator.phone:''}}</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4">
              <el-form-item prop="balance" label="余额" :label-width="formLabelWidth">
                {{form.store?form.store.balance:0}}
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="amount" label="金额" :label-width="formLabelWidth">
                <el-input :disabled="true" v-model="form.amount" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item prop="withdrawUserType" label="类型" :label-width="formLabelWidth">
                  <el-select
                    :disabled="true"
                    v-model="form.withdrawUserType"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择">
                  <el-option
                    v-for="item in enums.WithdrawUserTypeEnum"
                    :key="item.name"
                    :label="item.desc"
                    :value="item.name"
                  />
                </el-select>
             </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="withdrawType" label="方式" :label-width="formLabelWidth">
                  <el-select
                    :disabled="true"
                    v-model="form.withdrawType"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择">
                  <el-option
                    v-for="item in enums.WithdrawTypeEnum"
                    :key="item.name"
                    :label="item.desc"
                    :value="item.name"
                  />
                </el-select>
             </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item prop="withdrawStatus" label="状态" :label-width="formLabelWidth">
                  <el-select
                    :disabled="false"
                    v-model="form.withdrawStatus"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择">
                  <el-option
                    v-for="item in enums.WithdrawStatusEnum"
                    :key="item.name"
                    :label="item.desc"
                    :value="item.name"
                  />
                </el-select>
             </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="contactus" label="联系方式" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.phone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import * as EnumApi from '@/api/enums'
import * as WithdrawApi from '@/api/withdraw'
export default {
  name: 'WithdrawForm',
  props: {
    enums: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '100px',
      form: {},
      flag: '',
      rules: {
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      that.form = Object.assign({}, that.control.form);
    },
    handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              WithdrawApi.update(that.form).then(res => {
                if(res.data.code == 0){
                  that.control.show = false;
                  that.$parent.handlePage();
                }
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              that.form.content = ''
              that.form.contentEn = ''
              WithdrawApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style scoped lang="scss">
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
</style>
