<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="parentId" label="父级名称" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled" 
                  v-model="form.parentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                  v-for="item in parents"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="name" label="工作/指标名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="24">
              <el-form-item label="提交设置" :label-width="formLabelWidth">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox checked v-for="(element, index) in enums.FileTargetTypeEnum.filter(i=>{return i.brief=='tute_job';})" :label="element.code" :key="index">
                    {{element.desc}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as TuteworkApi from '@/api/tutework'

export default {
  name: 'TuteworkForm',
  props: {
    enums: '',
    parents: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '120px',
      form: {orderNumber: 0},
      checkList: [],
      rules: {
        name: [
          { required: true, message: '请输入专项/指标名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      TuteworkApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.checkList = result.data.submitConfig.length > 0 ? result.data.submitConfig.split(',').map( Number ) : [];
        console.log(that.checkList)
        that.form = result.data;
      })
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(that.checkList && that.checkList.length > 0){
          that.form.submitConfig = that.checkList.join(',')
        }else{
          that.form.submitConfig = '-1'
        }
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              TuteworkApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              TuteworkApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>