<template>
<el-drawer
    :title="'书名：'+controlChapter.form.title"
    :enums="enums"
    :with-header="true"
    :visible.sync="controlChapter.show"
    direction="rtl"
    :append-to-body="true"
    :modal-append-to-body="false"
    size="90%">
  <div>
    <div style="display: flex;">
      <div style="flex: 2;margin-right: 20px;">
        <!-- 数据表格 -->
        <el-table
          stripe
          :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
          :data="tableData"
          height="100vh"
          ref="tableContainer"
          class="table-container">
          <!-- <el-table-column prop="id" label="id" type="selection" fixed></el-table-column> -->
          <el-table-column
            fixed
            prop="title"
            label="知识库名称"
            levelKey="depth"
            treeKey="id"
            parentKey="parentId"
            childKey="children"
            min-width="120px">
            </el-table-column>

            <el-table-column
              prop="createTime"
              label="创建时间"
              min-width="90px"/>
<!--
          <el-table-column
            prop="knowledgebaseNumber"
            label="章节数">
          </el-table-column>

          <el-table-column
            prop="wordNumber"
            label="字数">
            <template slot-scope="scope">
              {{scope.row.wordNumber}}
            </template>
          </el-table-column> -->

          <!--<el-table-column
            prop="amountType"
            label="奖励类型">
            <template slot-scope="scope">
              {{scope.row.amountTypeLabel}}
            </template>
          </el-table-column> -->
          <el-table-column fixed="right" min-width="80px">
            <template slot="header">
              <span style="float: left">操作</span>
            </template>
            <template slot-scope="scope">
              <el-button v-show="$permits.has(targetPermit,$permits.UPDATE)" @click="handleConnection('connection',scope.row)" size="mini" type="warning" round>加入</el-button>
              <!-- <el-button v-show="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <chapter-form v-if="control.show" :control="control" :stores="stores" :enums="enums" :parents="parents" />
  </div>
</el-drawer>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as KnowledgeBaseApi from '@/api/knowledgebase'
import * as ArticleApi from '@/api/article'
import ChapterForm from './form'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Chapter',
  components: {
    ChapterForm
  },
  props: {
    controlChapter: Object
  },
  data() {
    return {
      formLabelWidth: '80px',
      stores: [],
      enums: [],
    	// 权限key
      targetPermit: 'knowledgebase',
      tableHeight: 0,
      queryForm: {
        title: '',
        publishFlag: true,
        current: 1,
        size: 100
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parents: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 100, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handleEnum()
    this.handlePage()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    handleConnection(action,item) {
      let that = this;
        KnowledgeBaseApi.addDoc({"articleId":that.controlChapter.form.id, "knowledgebaseId": item.id}).then(res => {
          if(res.data.code === 0){
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          }
        })
        console.log("switch >>>" + action)
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {

      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        KnowledgeBaseApi.published({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handlePublish(item) {
      let that = this;
      let tip = !item.publishFlag?'发布':'取消发布'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        KnowledgeBaseApi.published({id: item.id, enabled: !item.publishFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handlePage(current) {
      console.log('current', current)
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      that.queryForm.articleId = that.controlChapter.form.id
      console.log('form', that.queryForm, that.controlChapter.form)
      // 获取表格数据
      KnowledgeBaseApi.page(that.queryForm).then(res => {
        let result = res.data.data;
        that.tableData = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
