<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">

          <el-row>
            <el-col :span="12">
              <el-form-item prop="title" label="任务名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="picUrl" label="示范案例" :label-width="formLabelWidth">
                  <ly-pic-uploader ref="lyuploader" :url="form.picUrl" picSize="750x不限"></ly-pic-uploader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="content" label="内容要求" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="10" :disabled="control.disabled" v-model="form.content" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="inputTip" label="输入提示" :label-width="formLabelWidth">
            <el-input :disabled="control.disabled" v-model="form.inputTip" autocomplete="off"></el-input>
          </el-form-item>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="amountType" label="奖励类型" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.amountType"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  v-for="item in enums.AmountTypeEnum"
                  :key="item.name"
                  :label="item.desc"
                  :value="item.name"
                />
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="profitAmount" label="奖金比例" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.profitAmount" autocomplete="off"></el-input>%
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="orderNumber" label="排序" :label-width="formLabelWidth">
            <el-input :disabled="control.disabled" v-model="form.orderNumber" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="4" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="publishFlag" label="是否发布" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.publishFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import LyPicUploader from '@/components/PicUploader'
import * as EnumApi from '@/api/enums'
import * as TaskApi from '@/api/task'
import moment from "moment"
export default {
  name: 'TaskForm',
  components: {
    LyPicUploader
  },
  props: {
    enums: '',
    stores: '',
    parents: '',
    control: Object
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {storeId: '', content: '', picUrl: '', album: ''},
      rules: {
        title: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ]
      },
      gridData: [],
      stockNumberRepeat: []
    }
  },
  computed: {

  },
  mounted() {
    this.init();
  },
  methods: {
    init(){

      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        this.schedule()
        return
      }
      that.form = Object.assign({}, that.control.form);
      this.schedule()
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      if(that.$refs.lyuploader.fileList[0]){
        that.form.picUrl = that.$refs.lyuploader.fileList[0].url
      }
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              TaskApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              TaskApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
    schedule(){
      let firstDayOfYear= moment().startOf('year').format("YYYY-MM-DD");
      let weeks = moment(firstDayOfYear, "YYYY-MM-DD").isoWeek();
      let week = moment().week();
      console.log('schedule', this.gridData, this.stockNumberRepeat, this.form);
      if(this.form.stockNumberSchedule){
        this.stockNumberRepeat=[]
        this.form.stockNumberSchedule.split(',')
        .forEach((item,index)=>{
          this.stockNumberRepeat.push(item)
        })
      }else{
        for(let i=0;i<weeks;i++){
          this.stockNumberRepeat.push(0)
        }
      }
      for(let i=1;i<=weeks;i++){
        let currentYear = moment().year();
        let currentDate = moment().year(currentYear).isoWeek(i);
        // console.log(1, currentDate);
        let weekStart = currentDate.clone().startOf('week').format('YYYY-MM-DD');
        let weekEnd = currentDate.clone().endOf('week').format('YYYY-MM-DD');
        let weekEndDate = currentDate.clone().endOf('week')

        let start = weekEndDate.weekday(1).format('YYYY/MM/DD')
        let end = weekEndDate.weekday(7).format('YYYY/MM/DD')

        let item = {
          date: start + ' ~ ' + end,
          weekNumber: i,
          stockNumberRepeat: this.stockNumberRepeat[i-1]
        }
        this.gridData.push(item)
      }
    },
    saveSchedule(){
      console.log(2, this.gridData, this.stockNumberRepeat);
      this.form.stockNumberSchedule = this.stockNumberRepeat.join(',')
      TaskApi.update({id: this.form.id, stockNumberSchedule: this.form.stockNumberSchedule}).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
      })
    },
    closeSchedule(){
      this.$refs['schedule'].doClose();
    },
    justifyPrice(flag){
      let param = {id: this.form.id}
      if(flag == 1){
        //douyin
        param.douyinPrice = this.form.douyinPrice
      }else if(flag == 2){
        //dianping
        param.dianpingPrice = this.form.dianpingPrice
      }
      TaskApi.update(param).then(res => {
        this.$message({
          message: '已纠正',
          type: 'success'
        });
      })
    }
  }
}
</script>

<style>
</style>
