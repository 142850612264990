<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item prop="parentId" label="父级名称" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.parentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                  v-for="item in parents"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="12">
              <el-form-item prop="name" label="部门名称" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>

          </el-row>

<!--          <el-row>
            <el-col :span="8">
              <el-form-item prop="avatarId" label="Logo" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.avatarId" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-form-item prop="enableFlag" label="是否启用" :label-width="formLabelWidth">
            <el-switch
              :disabled="control.disabled"
              v-model="form.enableFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as DepartmentApi from '@/api/department'

export default {
  name: 'DepartmentForm',
  props: {
    enums: '',
    parents: '',
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '80px',
      form: {orderNumber: 0},
      rules: {
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      DepartmentApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              DepartmentApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              DepartmentApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>
