import { render, staticRenderFns } from "./page.vue?vue&type=template&id=232985fe&scoped=true"
import script from "./page.vue?vue&type=script&lang=js"
export * from "./page.vue?vue&type=script&lang=js"
import style0 from "./page.vue?vue&type=style&index=0&id=232985fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232985fe",
  null
  
)

export default component.exports