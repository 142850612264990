<template>
  <el-container class="chat-container">
    <!-- <el-header class="chat-header">聊天室</el-header> -->
    <el-main class="chat-main" id="mainRef" >
      <div class="chat-messages">
        <div v-for="message in messages" :key="message.id" :class="['chat-message', message.isOwn ? 'own' : 'other']">
          <div class="message-header">
            <el-avatar :src="message.avatar" />
            <span>{{ message.nickname }}</span>
            <span style="margin-left: 10px; color: #323232;" v-if="!message.isOwn">{{'total tokens: '+(message.totalTokens|0)}}</span>
          </div>
          <div class="message-content" v-html="message.content"></div>
        </div>
      </div>
      <div style="height: 120px;"></div>
    </el-main>
    <el-footer class="chat-footer">
      <div class="input-container">
        <el-input type="textarea" :autosize="{minRows: 1, maxRows: 4}" v-model="newMessage" placeholder="Enter 发送" clearable></el-input>
        <el-select
            v-model="modelType"
            clearable
            filterable
            size="small"
            placeholder="模型">
          <el-option
            v-for="item in enums.ModelTypeEnum"
            :key="item.name"
            :label="item.desc"
            :value="item.name"
          />
        </el-select>
        <el-select
            v-model="ragFlag"
            clearable
            filterable
            size="small"
            placeholder="是否启用RAG">
          <el-option
            v-for="item in [{name: true, desc: '启用'}, {name: false, desc: '禁用'}]"
            :key="item.name"
            :label="item.desc"
            :value="item.name"
          />
        </el-select>
        <el-button type="primary" @click="sendMessage" @keydown.native="handleKeyPress" >发送</el-button>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source"
import * as EnumApi from '@/api/enums'
import marked from 'marked';
import * as AgentlyApi from '@/api/agently'
import * as ImmessageApi from '@/api/immessage'
export default {
  data() {
    return {
      enums: [],
      modelType: 'gpt_35_turbo',
      ragFlag: false,
      newMessage: '',
      messages: [
        { id: 1, content: '你好', isOwn: false, nickname: 'AI 001', avatar: 'https://kimi.moonshot.cn/kimi-chat/assets/avatar/kimi_avatar_keep_light.png' },

      ],
    };
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyPress);
    window.addEventListener('resize', this.handleResize);
    this.handleEnum()
  },
  beforeDestroy() {
      document.removeEventListener('keydown', this.handleKeyPress);
      window.removeEventListener('resize', this.handleResize);
    },
  methods: {
    handleKeyPress(event) {
      console.log('event ', event);
      // 检查是否同时按下了Shift和Enter键
      if (event.key === 'Enter' && event.key === 'Shift') {
        event.preventDefault(); // 阻止默认的换行行为
        // 获取光标前和光标后的文字
        let start = this.selectionStart;
        let end = this.selectionEnd;
        // 插入换行符
        this.newMessage = this.newMessage.substring(0, start) + "\n" + this.newMessage.substring(end);
        // 移动光标到换行符后
        this.selectionStart = this.selectionEnd = start + 1;
      }
      // 检查按下的键是否是回车键
      if (event.key === 'Enter') {
        event.preventDefault();
        // 执行你想要的操作，例如提交表单
        this.sendMessage();
      }
    },
    replaceLineBreaks(str) {
      // return str
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.messages.push({
          id: this.messages.length + 1,
          content: this.newMessage,
          isOwn: true,
          nickname: '我',
          avatar: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
        });
        this.$nextTick(() => {
          this.scrollToBottom();
        });

        // sse start

        // let url = process.env.VUE_APP_BASE_API_URL+"auth-service/im_messages/stream_workflow/1789960238070243329"
        let url = process.env.VUE_APP_BASE_API_URL+"auth-service/im_messages/stream"
        // let url = process.env.VUE_APP_BASE_API_URL+"auth-service/api/v1/stream"
        this.messages.push({
          id: this.messages.length + 1,
          content: '',
          isOwn: false,
          nickname: 'AI 001',
          totalTokens: 0,
          avatar: 'https://statics.moonshot.cn/kimi-chat/static/01.0245bc9d.png',
        });
        let msg = '';
        fetchEventSource(url,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.lyToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({content: this.newMessage, modelType: this.modelType, ragFlag: this.ragFlag, temperature: 0.7}),
                onmessage: (event) => {
                    // console.info("onmessage", event);
                    let append = event.data.replace(/^"|"$/g, '').replace(/data:/g, '')
                    msg += append;
                    msg = this.replaceLineBreaks(msg)
                    this.messages[this.messages.length-1]['content'] = marked.marked(msg)
                    // marked.marked(completion.result.output.content)
                },
                onerror: (error) => {
                    console.error("error", error);
                    this.messages[this.messages.length-1]['content'] = marked.marked(msg)
                    throw error;
                },
                onopen: (response) => {
                    console.info("onopen", response);
                },
                onclose: (response) => {
                    console.info("onclose", response);
                }
            }
        );
        // sse end
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    scrollToBottom() {
      const mainRef = document.getElementById("mainRef")
      mainRef.scrollTop = mainRef.scrollHeight;
      this.newMessage = null;
    },
    handleResize() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
  },
};
</script>

<style scoped>
.chat-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #409eff;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.chat-main {
  flex: 1;
  padding: 10px;
  margin-bottom: 70px;
  background-color: #f5f7fa;
  overflow: auto;
}

.chat-messages {
  height: 100%;
}

.chat-message {
  margin: 20px 0;
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.message-header .el-avatar {
  margin-right: 5px;
}

.message-content {
  /* max-width: 90%; */
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
}

.own .message-content {
  background-color: #001A40;
  color: #fff;
  margin-right: auto;
}

.other .message-content {
  background-color: #dcdfe6;
  margin-right: auto;
}

.chat-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.input-container {
  display: flex;
  align-items: center;
}
/deep/ .input-container .el-textarea {
  flex: 1;
  margin-right: 2px;
  display: flex;
}
/deep/ .input-container .el-textarea .el-textarea__inner {
  min-height: 40px !important;
}
/deep/ .input-container .el-select {
  /* min-height: 70px !important; */
}
</style>
