<template>
  <div class="app-table-container">
    <!-- 查询条件 -->
    <el-form :model="queryForm">
      <el-row style="margin: .5rem 0px;">
        <el-col :span="8">
          <el-form-item label="工作名称" :label-width="formLabelWidth">
            <el-input v-model="queryForm.name" maxlength="9" size="small" placeholder="请输入工作名称" clearable=""/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" size="small" @click="handlePage">搜索</el-button>
            <el-button size="small" @click="reset()">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">
      <el-table-column fixed type="index" label="#"></el-table-column>
      <el-table-tree-column
        fixed
        prop="name" 
        label="工作/指标名称" 
        levelKey="depth"
        treeKey="id"
        parentKey="parentId"
        childKey="children"
        min-width="200px"
        >
        </el-table-tree-column>
      <el-table-column 
        prop="auditStatusLabel" 
        label="审核状态" 
        />

      
      <el-table-column 
        prop="submitCompleteFlag" 
        label="是否完成" 
        min-width="80px">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="scope.row.submitCompleteFlag" round>是</el-button>
          <el-button type="danger" size="mini" v-else round>否</el-button>
        </template>
      </el-table-column>

      <el-table-column 
        prop="enableFlag" 
        label="是否开启" 
        min-width="80px">
        <template slot-scope="scope">
          <el-button @click="$permits.has(targetPermit,$permits.CREATE)?handleEnable(scope.row):''"  :type="scope.row.enableFlag?'success':'danger'" size="mini" round>
            {{scope.row.enableFlag?'启用':'停用'}}
          </el-button>
        </template>
      </el-table-column>
      
      <el-table-column fixed="right" min-width="210px">
        <template slot="header">
          <span>操作</span>
          <el-button 
              style="float: right;"
              type="primary" size="mini"
              v-show="$permits.has(targetPermit,$permits.CREATE)" 
              @click="handleAction($permits.CREATE)">
            + 新建工作/指标
          </el-button>
        </template>
        <template slot-scope="scope">
          <!-- <el-button v-show="$permits.has(targetPermit,$permits.VIEW)" @click="handleAction($permits.VIEW,scope.row)" size="mini" type="success" round>详情</el-button> -->
          <el-button v-if="$permits.has(targetPermit,$permits.UPDATE)" @click="handleAction($permits.UPDATE,scope.row)" size="mini" type="warning" round>编辑</el-button>
          <el-button v-if="$permits.has(targetPermit,$permits.REMOVE)" @click="handleRemove(scope.row)" size="mini" type="danger" round>删除</el-button>
          <el-button v-if="$permits.has('file',$permits.UPLOAD) && scope.row.enableFlag" @click="handleUpload($permits.UPLOAD,scope.row)" size="mini" type="info" round>上传</el-button>
        </template> 
      </el-table-column>

    </el-table>

    <tutework-form v-if="control.show" :control="control" :enums="enums"  :parents="parents" />
    <tutework-upload v-if="controlUpload.show" :control="controlUpload" :enums="enums"  :parents="parents" />
  </div>
</template>
<script>

import * as EnumApi from '@/api/enums'
import * as TuteworkApi from '@/api/tutework'
import TuteworkForm from './form'
import TuteworkUpload from './upload'
import { buildTree,treeToList } from '@/utils'
export default {
  name: 'Tutework',
  components: {
    TuteworkForm,TuteworkUpload
  },
  data() {
    return {
      formLabelWidth: '80px',
      enums: [],
    	targetPermit: 'tutework',
      tableHeight: 0,
      queryForm: {
        name: '',
        category: '',
        current: '',
        size: 10000
      },
      control: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      controlUpload: {
        show: false,
        title: '',
        action: this.$permits.VIEW,
        form: '',
        disabled: false
      },
      tableData: [],
      parents: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 10, // 每页行数
        sizes: [10, 20, 30, 40], // 每页行数 调整
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handleEnum()
    this.handlePage()
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.queryForm).forEach(key => (this.queryForm[key] = ''))
      this.handlePage()
    },
    remote(row,callback){
      // async or sync are both supported
      console.log("remote row:" + row);
    },
    handleAction(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.control.show = true;
      that.control.disabled = false;
      that.control.action = action;

      switch(action){
        case that.$permits.VIEW:
          that.control.title = "详情";
          that.control.disabled = true;
          that.control.form = item;
          console.log("switch >>> " + that.$permits.VIEW)
          break;
        case that.$permits.UPDATE:
          that.control.title = "编辑";
          that.control.form = item;
          console.log("switch >>> " + that.$permits.UPDATE)
          break;
        case that.$permits.CREATE:
          that.control.title = "新建";
          console.log("switch >>> " + that.$permits.CREATE)
          break;
      }
    },
    handleUpload(action,item) {
      let that = this;
      console.log("action >> " + action)
      that.controlUpload.show = true;
      that.controlUpload.title = "上传";
      that.controlUpload.disabled = true;
      that.controlUpload.action = action;
      that.controlUpload.form = item;

    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        TuteworkApi.remove(item.id).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnum() {
      let that = this;
      // 获取表格数据
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    handleEnable(item) {
      let that = this;
      let tip = !item.enableFlag?'启用':'停用'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        TuteworkApi.enabled({id: item.id, enabled: !item.enableFlag}).then(res => {
          that.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handlePage() {
      let that = this;
      // 获取表格数据
      let params = Object.assign({}, that.queryForm)
      params.parentId = 0;
      TuteworkApi.page(params).then(res => {
        let result = res.data.data;
        that.tableData = buildTree(result.records, 0, 0)
        that.parents = treeToList(that.tableData)
        that.parents.unshift({id: 0, name: "无", depth: 0})
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style>

</style>
