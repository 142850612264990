<template>
  <!-- 新建/编辑 -->
    <el-drawer
        :title="control.title"
        :enums="enums"
        :with-header="false"
        :visible.sync="control.show"
        direction="rtl"
        size="70%">
        <!-- <el-divider>创建</el-divider> -->
        <el-form status-icon :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="8">
              <el-form-item prop="parentId" label="父级名称" :label-width="formLabelWidth">
                <el-select
                  :disabled="control.disabled"
                  v-model="form.parentId"
                  clearable
                  filterable
                  size="small"
                  placeholder="请选择">
                <el-option
                  :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                  v-for="item in parentOurservices"
                  :key="item.id"
                  :value="item.id"
                  :label="item.title">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">

            </el-col>
            <el-col :span="8">

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="title" label="标题" :label-width="formLabelWidth">
                <el-input :disabled="control.disabled" v-model="form.title" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item prop="coin" label="服务币" :label-width="formLabelWidth">
                <el-input type="number" :disabled="control.disabled" v-model.number="form.coin" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label-width="5px">
                服务币<i style="font-size: 0.6rem;color: red;">若为一级标题，服务币填 0</i>
              </el-form-item>
            </el-col>
          </el-row>

         <el-row>
            <el-col :span="8">
              <el-form-item prop="orderNumber" label="排序值" :label-width="formLabelWidth">
                <el-input type="number" :disabled="control.disabled" v-model.number="form.orderNumber" autocomplete="off"></el-input>
                <i style="font-size: 0.6rem;color: red;">自然排序，值越小越靠前。</i>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
            <el-input :rows="5" type="textarea" :disabled="control.disabled" v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button v-if="!control.disabled" type="primary" @click="handleSubmit(control.action)" size="mini">提交</el-button>
          </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>

import * as OurserviceApi from '@/api/ourservice'

export default {
  name: 'OurserviceForm',
  props: {
    enums: '',
    parentOurservices: [],
    control: Object
  },
  data() {
    var checkNumber = (rule, value, callback) => {
        if (!value && value!=0) {
          return callback(new Error('排序值不能为空'));
        }
        callback();
      };
    return {
      formLabelWidth: '80px',
      form: {orderNumber: 0},
      rules: {
        name: [
          { required: true, message: '请输入资源名称', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        subcategory: [
          { required: true, message: '请选择子类型', trigger: 'blur' }
        ],
        orderNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      let that = this;
      console.log("init action >> " + that.control.action)
      if(that.$permits.CREATE === that.control.action){
        return
      }
      OurserviceApi.view(that.control.form.id).then(res => {
        let result = res.data;
        that.form = result.data;
      })
    },
    async handleSubmit(action) {
      let that = this;
      console.log("action >> " + action)
      that.$refs['form'].validate((valid) => {
        console.log("validate >> starting...")
        if(valid){
          switch(action){
            case that.$permits.UPDATE:
              OurserviceApi.update(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.UPDATE)
              break;
            case that.$permits.CREATE:
              OurserviceApi.create(that.form).then(res => {
                that.control.show = false;
                that.$parent.handlePage();
              })
              console.log("switch >>> " + that.$permits.CREATE)
              break;
          }
        }
        console.log("validate >> stop, valid: " + valid)
      });
    },
  }
}
</script>

<style>
</style>
